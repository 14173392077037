import React, { useState } from 'react'
import cn from 'classnames'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const inputStyle = "rounded-md px-2 py-2  bg-gray-50 text-[14px] w-full"
const containerInput = 'rounded-md flex flex-row bg-gray-50  border-2 items-center'

const Input = ({ multiline = false,
    select = false,
    value,
    setValue,
    type = 'text',
    label,
    selectItems = [],
    rows = 10,
    cols = 30,
    placeholder,
    disabled = false,
    styles,
    id,
    required = false,
}) => {
    const handleInputChange = (field) => {
        return (e) => {
            setValue((prev) => ({
                ...prev,
                [field]: e.target.value
            }))
        }
    }

    const [type_, setType_] = useState(type)

    if (multiline) {
        return (
            <div className='flex flex-col gap-2 w-full'>
                <div>
                    <label htmlFor={id} className="font-semibold">
                        {label + (required ? '(*)' : '')}
                    </label>
                </div>
                <div className={cn(containerInput)}>
                    <textarea
                        className={cn(inputStyle)}
                        style={{ ...styles }}
                        name={id}
                        id={id}
                        cols={cols}
                        rows={rows}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={handleInputChange(id)}
                    >
                    </textarea>
                </div>
            </div>
        )
    } else if (select) {
        return (
            <div className='flex flex-col gap-2 w-full'>
                <div>
                    <label htmlFor={id} className="font-semibold">
                        {label + (required ? '(*)' : '')}
                    </label>
                </div>

                <div className={cn(containerInput)}>
                    <select
                        className={cn(inputStyle)}
                        style={{ ...styles }}
                        name={id}
                        id={id}
                        value={value}
                        onChange={handleInputChange(id)}
                        disabled={disabled}
                    >
                        {selectItems.map((el, index) => (
                            <option
                                value={el.value}
                                key={index}
                            >{el.label}</option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }

    return (
        <div className='flex flex-col gap-2 w-full'>
            <div>
                <label htmlFor={id} className="font-semibold w-6/6 flex-wrap flex">
                    {label + (required ? '(*)' : '')}
                </label>
            </div>
            <div className={cn(containerInput)}>
                <input
                    className={cn(inputStyle)}
                    style={{ ...styles }}
                    type={type_}
                    name={id}
                    value={value}
                    id={id}
                    onChange={handleInputChange(id)}
                    placeholder={placeholder}
                    disabled={disabled}
                />

                {(type === 'password') && (
                    type_ === 'password' ? (
                        <button className='px-2' onClick={() => setType_('text')}>
                            <FaEye />
                        </button>
                    ) : (
                        <button className='px-2' onClick={() => setType_('password')}>
                            <FaEyeSlash />
                        </button>
                    )
                )}
            </div>
        </div >
    )
}

export default Input
import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getUsers, createUser, setProfile, getUserById, updateUser } from '../../../api/auth'
import { HiEye, HiPencil } from "react-icons/hi"
import MessagePanel from '../../../component/Card/MessagePanel'
import { emailRegex } from '../../../component/utils/index'
import SearchText from '../../../component/SearchText'
import { refresh } from "../../../helpers/helper"

function Users() {
  const moment = require('moment')
  const [itShowEdit, setItShowEdit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [user, setUser] = useState({
    displayName: '',
    email: '',
    phone: '',
    personType: ''
  })

  const [filter, setFilter] = useState({
    searchText: '',
    status: 1,
    itemsPerPage: 10
  })

  const [users, setUsers] = useState([])
  const [usersFiltered, setUsersFiltered] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState()
  const [messageStatus, setMessageStatus] = useState(0)
  const [currentItems, setCurrentItems] = useState([])
  const [itemsPerPageArray, setItemsPerPageArray] = useState([])

  const getUsersList = useCallback(async () => {
    setIsLoading(true)
    try {
      await getUsers().then((response) => {
        const result = response.data
        setUsers(result)
        setUsersFiltered(result)
        setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
        let arrayItemsPerPage = []
        for (let i = 1; i <= result.length; i++) {
          if (i === 0) {
            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
          } else {
            arrayItemsPerPage.push({ value: i, label: i.toString() })
          }
        }
        setItemsPerPageArray(arrayItemsPerPage)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }, [filter.itemsPerPage])

  const getUserInfos = useCallback(async (id) => {
    try {
      await getUserById(id)
        .then(({ data }) => {
          const result = data

          console.log(result)
          setUser((prev) => ({
            ...prev,
            displayName: result.displayName,
            email: result.email,
            phone: result.phone,
            personType: result.personType,
            id: result.id
          }))
        })
    } catch (error) {
      const errorMessage = error.response.data.detail
      console.log(errorMessage)
    }
  }, [])

  const editShowItem = (item) => {
    setShowModal(true)
    getUserInfos(item.id)
  }

  const resetUser = () => {
    setUser((prev) => ({
      ...prev,
      displayName: '',
      email: '',
      phone: '',
      personType: ''
    }))
  }

  const setCreateUsers = async () => {
    setShowMessage(false)
    if (
      user.displayName !== '' && user.email !== '' && user.phone !== '' && user.profil !== ''
    ) {
      if (user.id !== undefined) {
        try {
          if (user.email.match(emailRegex) && user.email !== 'nom@mail.dom') {
            setIsLoading(true)
            await updateUser(
              user.personType,
              user.email,
              user.displayName,
              user.phone,
              user.id
            )
              .then(async (res) => {
                const result = res.data
                await setProfile(result.id, user.profil).then((res) => {
                  setIsLoading(false)
                  setItShowEdit(false)
                  getUsersList()
                  setShowMessage(true)
                  setMessageStatus(1)
                  setMessage("L'utilisateur a été modifier avec succès")
                })
              })
          }
          else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
              '🤦🏾‍♂️ Veuillez renseigner un email valide "Ex : nom@mail.dom".'
            )
            setIsLoading(false)
          }
        } catch (error) {
          const errorMessage = error.response.data.detail
          setShowMessage(true)
          setMessageStatus(0)
          setMessage(errorMessage)
        }
      } else {
        try {
          if (user.email.match(emailRegex) && user.email !== 'nom@mail.dom') {
            setIsLoading(true)
            await createUser(
              user.personType,
              user.email,
              user.email,
              user.displayName,
              user.phone
            )
              .then(async () => {
                setIsLoading(false)
                resetUser()
                getUsersList()
                setShowMessage(true)
                setMessageStatus(1)
                setMessage("L'utilisateur a été créer avec succès")
              }).catch((error) => console.log(error))
          }
          else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
              '🤦🏾‍♂️ Veuillez renseigner un email valide "Ex : nom@mail.dom".'
            )
            setIsLoading(false)
          }
        } catch (error) {
          const errorMessage = error.response.data.detail
          setShowMessage(true)
          setMessageStatus(0)
          setMessage(errorMessage)
        }
      }
    } else {
      setShowMessage(true)
      setMessageStatus(0)
      setMessage(
        'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
      )
    }
  }

  const headers = [
    "Id",
    "Email",
    "Statut",
    // 'Profile',
    "Date de création",
    "Action"
  ]

  const statusItems = [
    {
      label: "Tout",
      value: 0,
      default: true
    },
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Non-active",
      value: 2,
    }
  ]

  const toggleModal = () => {
    setShowModal(!showModal)
    setItShowEdit(false)
    setShowMessage(false)
    setIsLoading(false)
    resetUser()
  }

  const personTypes = [
    {
      label: "Administrateur",
      value: "ADM"
    },
  ]

  useEffect(() => {
    if (users.length === 0) {
      getUsersList()
    }
  }, [users, getUsersList])

  return (
    <div className="flex flex-col w-full h-full px-10 py-10">
      <div className='flex flex-row'>
        <div className='w-full'>
          <div className='flex flex-row justify-between mb-4'>
            <div className='flex-col flex gap-2 w-6/12'>
              <div>
                <SearchText initialArray={users} setArrayFiltered={setUsersFiltered} setLoader={setIsLoading} />
              </div>
              <div className='flex-row flex gap-3'>
                <div className='w-6/12'>
                  <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                </div>
                <div className='w-6/12'>
                  <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                </div>

              </div>
            </div>
            <div className='flex flex-row gap-2  justify-center items-end'>
              <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
              <button onClick={() => toggleModal()} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter un utilisateur</button>
            </div>
          </div>
          <ModelOne headers={headers} arrayItems={usersFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
            {usersFiltered.length > 0 ?
              currentItems.map((item, index) => (
                <tr key={index} className="border-b-2 border-gray-300">
                  <td className='p-3'>{item.id}</td>
                  <td className='p-3'>{item.email}</td>
                  <td className='p-3'>{!item.locked ? 'Active' : "Non-active"}</td>
                  {/* <td className='p-3'>{setCategoryName(item.personType)}</td> */}
                  <td className='p-3'>{moment(item.createdAt).format('ll')}</td>
                  <td className='flex flex-row items-center justify-center p-3 gap-2'>
                    <button className='text-[16px] px-5 py-2 border-2 rounded-md' onClick={() => editShowItem(item)}>
                      <HiEye />
                    </button>
                  </td>
                </tr>
              )) : (
                <tr className="">
                  <td
                    colSpan="10"
                    className="p-5 text-center text-gray-700 text-md"
                  >
                    {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                  </td>
                </tr>
              )}
          </ModelOne>
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className='w-full h-full flex-col flex items-center justify-center'>
          <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
            <div className='flex flex-col gap-3'>
              {showMessage && (
                <MessagePanel message={message} messageStatus={messageStatus} />
              )}

              <h3 className='text-2xl mb-2 font-semibold uppercase' >{user.id !== undefined ? 'Information utilisateur' : 'Ajouter un utilisateur'}</h3>
              <div>
                <Input disabled={!itShowEdit && user.id !== undefined} required={true} label={'Nom complet'} placeholder="Nom complet" id={'displayName'} value={user.displayName} setValue={setUser} />
              </div>
              <div>
                <Input disabled={!itShowEdit && user.id !== undefined} required={true} label={'Adresse email'} placeholder="Adresse email" id={'email'} value={user.email} setValue={setUser} />
              </div>
              <div>
                <Input disabled={!itShowEdit && user.id !== undefined} required={true} label={'Numéro de Téléphone'} placeholder="Numéro de Téléphone" id={'phone'} value={user.phone} setValue={setUser} />
              </div>

              <div>
                <Input disabled={!itShowEdit && user.id !== undefined} required={true} label={'Sélectionner un type'} id={'profil'} setValue={setUser} select={true} selectItems={personTypes} value={user.personType} />
              </div>

              <div className='flex flex-row gap-4'>
                {(user.id !== undefined) && (itShowEdit ?
                  <button onClick={setCreateUsers} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier l\'utilisateur'} </button>
                  :
                  <button onClick={() => setItShowEdit(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>
                    <HiPencil />
                  </button>)
                }

                {user.id === undefined &&
                  <button onClick={setCreateUsers} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter un utilisateur'} </button>}
                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
              </div>

            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default Users

// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const getTransactions = () => {
    const url = '/transactions'

    return axiosInstance.get(url)
}

export const getComptes = () => {
    const url = '/accounts'

    return axiosInstance.get(url)
}

export const getCompte = (id) => {
    const url = '/accounts/' + id

    return axiosInstance.get(url)
}

export const getEntries = (id) => {
    const url = `/entries?accountId=${id}`

    return axiosInstance.get(url)
}

export const createCompte = (data) => {
    const url = '/accounts'
    let data_ = {}

    data.customerId === '' ? data_ = {
        name: data.name,
        profile: data.profile,
        currency: data.currency,
    } : data_ = data

    return axiosInstance.post(url, data_)
}

export const updateCompte = (data, id) => {
    const url = '/accounts/' + id
    let data_ = {}

    data.customerId === '' ? data_ = {
        name: data.name,
        profile: data.profile,
        currency: data.currency,
    } : data_ = data

    return axiosInstance.put(url, data_)
}


import React, { useEffect, useState, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, HiPencil } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import Modal from '@mui/material/Modal';
import { getPartitions, createPartition, updatePartition } from '../../../api/partition'
import MessagePanel from '../../../component/Card/MessagePanel'

function PLateforme() {
    const [partition, setPartition] = useState({
        name: "",
        address: "",
        state: "",
        city: "",
        country: "COD",
        active: true,
        // deskNumber: ""
    })

    const headers = [
        "Id",
        "Nom",
        "Adresse",
        "Province / Ville",
        "Ville / Commune",
        "Statut",
        "Action"
    ]

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [partitions, setPartitions] = useState([])
    const [partitionsFiltered, setPartitionsFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [itEditing, setItEditing] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    // const { countryCode } = require('../../../helpers/countryCode')

    const editShowItem = (item) => {

        setPartition((prev) => ({
            ...prev,
            name: item.name,
            address: item.address,
            state: item.state,
            city: item.city,
            country: item.country,
            active: item.active,
            id: item.id
        }))

        setShowModal(true)
    }

    const resetPartition = () => {
        setPartition((prev) => ({
            ...prev,
            name: "",
            address: "",
            state: "",
            city: "",
            country: "COD",
            active: true,
            id: undefined
            // deskNumber: ""
        }))
    }

    const toggleModal = () => {
        setShowModal(!showModal)
        setShowMessage(false)
        setItEditing(false)
        resetPartition()
    }

    const statusItems = [
        {
            label: "Tout",
            value: 0,
            default: true
        },
        {
            label: "Active",
            value: 1,
        },
        {
            label: "Non-active",
            value: 2,
        }
    ]

    const getPartitionsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getPartitions()
                .then(({ data }) => {
                    setPartitions(data)
                    setPartitionsFiltered(data)
                    setCurrentItems(data.reverse().slice(0, filter.itemsPerPage))
                    let arrayItemsPerPage = []
                    for (let i = 1; i <= data.length; i++) {
                        if (i === 0) {
                            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                        } else {
                            arrayItemsPerPage.push({ value: i, label: i.toString() })
                        }
                    }
                    setItemsPerPageArray(arrayItemsPerPage)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("Try : => ", error)
                    setIsLoading(false)
                })
        } catch (error) {
            console.log("Catch : =>", error)
            setIsLoading(false)
        }
    }, [filter])


    const setCreatePartition = async () => {
        if (partition.name !== '' && partition.address !== '') {
            try {
                setIsLoading(true)
                if (partition.id !== undefined) {
                    await updatePartition(partition, partition.id)
                        .then(() => {
                            setShowMessage(true)
                            setMessageStatus(1)
                            setMessage("La partition a été modifier avec succès")
                            setItEditing(false)
                            getPartitionsList()
                            setIsLoading(false)
                        })
                } else {
                    await createPartition(partition)
                        .then(() => {
                            setIsLoading(false)
                            resetPartition()
                            getPartitionsList()
                            setShowMessage(true)
                            setMessageStatus(1)
                            setMessage("La partition a été créer avec succès")
                        })
                }
            } catch (error) {
                console.log("Catch : => ", error)
                setIsLoading(false)
            }

        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }

    useEffect(() => {
        if (partitions.length === 0 && isLoading === true) {
            getPartitionsList()
        }
    }, [getPartitionsList, partitions, isLoading])


    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={partitionsFiltered} setArrayFiltered={setPartitionsFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={toggleModal} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter une partition</button>
                        </div>
                    </div>

                    <ModelOne headers={headers} arrayItems={partitionsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {partitionsFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr className="border-b-2 border-gray-300" key={index}>
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.name}</td>
                                    <td className='p-3'>{item.address}</td>
                                    <td className='p-3'>{item.state}</td>
                                    <td className='p-3'>{item.city}</td>
                                    <td className='p-3'>{item.active ? "Active" : "Non-active"}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button onClick={() => editShowItem(item)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
                        <div className='flex flex-col gap-3'>

                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{partition.id !== undefined ? 'Information de la partition' : 'Ajouter une partition'}</h3>

                            <div className='flex flex-col gap-4'>
                                <Input
                                    disabled={!itEditing && partition.id !== undefined}
                                    required={true}
                                    label={'Nom de la partition'}
                                    placeholder="Nom de la partition"
                                    id={'name'}
                                    value={partition.name}
                                    setValue={setPartition}
                                />

                                <Input
                                    disabled={!itEditing && partition.id !== undefined}
                                    required={true}
                                    label={'Adresses'}
                                    placeholder={'Adresses'}
                                    id={'address'}
                                    value={partition.address}
                                    setValue={setPartition}
                                />

                                <Input
                                    disabled={!itEditing && partition.id !== undefined}
                                    label={'Province / Ville'}
                                    placeholder="Province / Ville"
                                    id={'state'}
                                    value={partition.state}
                                    setValue={setPartition}
                                />

                                <Input
                                    disabled={!itEditing && partition.id !== undefined}
                                    label={'Ville / Commune'}
                                    placeholder="Ville / Commune"
                                    id={'city'}
                                    value={partition.city}
                                    setValue={setPartition}
                                />

                                {/* <Input
                                    disabled={!itEditing && partition.id !== undefined}
                                    label={'Desk-number'}
                                    placeholder={'Desk-number'}
                                    value={partition.deskNumber}
                                    setValue={setPartition}
                                    id={'deskNumber'}
                                /> */}
                            </div>

                            <div className='flex flex-row gap-4'>
                                {(partition.id !== undefined) && (itEditing ?
                                    <button onClick={() => setCreatePartition()} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier la plateforme'} </button>
                                    :
                                    <button onClick={() => setItEditing(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>
                                        <HiPencil />
                                    </button>)
                                }

                                {partition.id === undefined &&
                                    <button onClick={() => setCreatePartition()} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter une plateforme'} </button>}
                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default PLateforme

export const countryCode = [{
    "label": "Afghanistan",
    "dial_code": "+93",
    "emoji": "🇦🇫",
    "value": "AF"
},
{
    "label": "Aland Islands",
    "dial_code": "+358",
    "emoji": "🇦🇽",
    "value": "AX"
},
{
    "label": "Albania",
    "dial_code": "+355",
    "emoji": "🇦🇱",
    "value": "AL"
},
{
    "label": "Algeria",
    "dial_code": "+213",
    "emoji": "🇩🇿",
    "value": "DZ"
},
{
    "label": "AmericanSamoa",
    "dial_code": "+1684",
    "emoji": "🇦🇸",
    "value": "AS"
},
{
    "label": "Andorra",
    "dial_code": "+376",
    "emoji": "🇦🇩",
    "value": "AD"
},
{
    "label": "Angola",
    "dial_code": "+244",
    "emoji": "🇦🇴",
    "value": "AO"
},
{
    "label": "Anguilla",
    "dial_code": "+1264",
    "emoji": "🇦🇮",
    "value": "AI"
},
{
    "label": "Antarctica",
    "dial_code": "+672",
    "emoji": "🇦🇶",
    "value": "AQ"
},
{
    "label": "Antigua and Barbuda",
    "dial_code": "+1268",
    "emoji": "🇦🇬",
    "value": "AG"
},
{
    "label": "Argentina",
    "dial_code": "+54",
    "emoji": "🇦🇷",
    "value": "AR"
},
{
    "label": "Armenia",
    "dial_code": "+374",
    "emoji": "🇦🇲",
    "value": "AM"
},
{
    "label": "Aruba",
    "dial_code": "+297",
    "emoji": "🇦🇼",
    "value": "AW"
},
{
    "label": "Australia",
    "dial_code": "+61",
    "emoji": "🇦🇺",
    "value": "AU"
},
{
    "label": "Austria",
    "dial_code": "+43",
    "emoji": "🇦🇹",
    "value": "AT"
},
{
    "label": "Azerbaijan",
    "dial_code": "+994",
    "emoji": "🇦🇿",
    "value": "AZ"
},
{
    "label": "Bahamas",
    "dial_code": "+1242",
    "emoji": "🇧🇸",
    "value": "BS"
},
{
    "label": "Bahrain",
    "dial_code": "+973",
    "emoji": "🇧🇭",
    "value": "BH"
},
{
    "label": "Bangladesh",
    "dial_code": "+880",
    "emoji": "🇧🇩",
    "value": "BD"
},
{
    "label": "Barbados",
    "dial_code": "+1246",
    "emoji": "🇧🇧",
    "value": "BB"
},
{
    "label": "Belarus",
    "dial_code": "+375",
    "emoji": "🇧🇾",
    "value": "BY"
},
{
    "label": "Belgium",
    "dial_code": "+32",
    "emoji": "🇧🇪",
    "value": "BE"
},
{
    "label": "Belize",
    "dial_code": "+501",
    "emoji": "🇧🇿",
    "value": "BZ"
},
{
    "label": "Benin",
    "dial_code": "+229",
    "emoji": "🇧🇯",
    "value": "BJ"
},
{
    "label": "Bermuda",
    "dial_code": "+1441",
    "emoji": "🇧🇲",
    "value": "BM"
},
{
    "label": "Bhutan",
    "dial_code": "+975",
    "emoji": "🇧🇹",
    "value": "BT"
},
{
    "label": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "emoji": "🇧🇴",
    "value": "BO"
},
{
    "label": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "emoji": "🇧🇦",
    "value": "BA"
},
{
    "label": "Botswana",
    "dial_code": "+267",
    "emoji": "🇧🇼",
    "value": "BW"
},
{
    "label": "Brazil",
    "dial_code": "+55",
    "emoji": "🇧🇷",
    "value": "BR"
},
{
    "label": "British Indian Ocean Territory",
    "dial_code": "+246",
    "emoji": "🇮🇴",
    "value": "IO"
},
{
    "label": "Brunei Darussalam",
    "dial_code": "+673",
    "emoji": "🇧🇳",
    "value": "BN"
},
{
    "label": "Bulgaria",
    "dial_code": "+359",
    "emoji": "🇧🇬",
    "value": "BG"
},
{
    "label": "Burkina Faso",
    "dial_code": "+226",
    "emoji": "🇧🇫",
    "value": "BF"
},
{
    "label": "Burundi",
    "dial_code": "+257",
    "emoji": "🇧🇮",
    "value": "BI"
},
{
    "label": "Cambodia",
    "dial_code": "+855",
    "emoji": "🇰🇭",
    "value": "KH"
},
{
    "label": "Cameroon",
    "dial_code": "+237",
    "emoji": "🇨🇲",
    "value": "CM"
},
{
    "label": "Canada",
    "dial_code": "+1",
    "emoji": "🇨🇦",
    "value": "CA"
},
{
    "label": "Cape Verde",
    "dial_code": "+238",
    "emoji": "🇨🇻",
    "value": "CV"
},
{
    "label": "Cayman Islands",
    "dial_code": "+ 345",
    "emoji": "🇰🇾",
    "value": "KY"
},
{
    "label": "Central African Republic",
    "dial_code": "+236",
    "emoji": "🇨🇫",
    "value": "CF"
},
{
    "label": "Chad",
    "dial_code": "+235",
    "emoji": "🇹🇩",
    "value": "TD"
},
{
    "label": "Chile",
    "dial_code": "+56",
    "emoji": "🇨🇱",
    "value": "CL"
},
{
    "label": "China",
    "dial_code": "+86",
    "emoji": "🇨🇳",
    "value": "CN"
},
{
    "label": "Christmas Island",
    "dial_code": "+61",
    "emoji": "🇨🇽",
    "value": "CX"
},
{
    "label": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "emoji": "🇨🇨",
    "value": "CC"
},
{
    "label": "Colombia",
    "dial_code": "+57",
    "emoji": "🇨🇴",
    "value": "CO"
},
{
    "label": "Comoros",
    "dial_code": "+269",
    "emoji": "🇰🇲",
    "value": "KM"
},
{
    "label": "Congo",
    "dial_code": "+242",
    "emoji": "🇨🇬",
    "value": "CG"
},
{
    "label": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "emoji": "🇨🇩",
    "value": "CD"
},
{
    "label": "Cook Islands",
    "dial_code": "+682",
    "emoji": "🇨🇰",
    "value": "CK"
},
{
    "label": "Costa Rica",
    "dial_code": "+506",
    "emoji": "🇨🇷",
    "value": "CR"
},
{
    "label": "Cote d'Ivoire",
    "dial_code": "+225",
    "emoji": "🇨🇮",
    "value": "CI"
},
{
    "label": "Croatia",
    "dial_code": "+385",
    "emoji": "🇭🇷",
    "value": "HR"
},
{
    "label": "Cuba",
    "dial_code": "+53",
    "emoji": "🇨🇺",
    "value": "CU"
},
{
    "label": "Cyprus",
    "dial_code": "+357",
    "emoji": "🇨🇾",
    "value": "CY"
},
{
    "label": "Czech Republic",
    "dial_code": "+420",
    "emoji": "🇨🇿",
    "value": "CZ"
},
{
    "label": "Denmark",
    "dial_code": "+45",
    "emoji": "🇩🇰",
    "value": "DK"
},
{
    "label": "Djibouti",
    "dial_code": "+253",
    "emoji": "🇩🇯",
    "value": "DJ"
},
{
    "label": "Dominica",
    "dial_code": "+1767",
    "emoji": "🇩🇲",
    "value": "DM"
},
{
    "label": "Dominican Republic",
    "dial_code": "+1849",
    "emoji": "🇩🇴",
    "value": "DO"
},
{
    "label": "Ecuador",
    "dial_code": "+593",
    "emoji": "🇪🇨",
    "value": "EC"
},
{
    "label": "Egypt",
    "dial_code": "+20",
    "emoji": "🇪🇬",
    "value": "EG"
},
{
    "label": "El Salvador",
    "dial_code": "+503",
    "emoji": "🇸🇻",
    "value": "SV"
},
{
    "label": "Equatorial Guinea",
    "dial_code": "+240",
    "emoji": "🇬🇶",
    "value": "GQ"
},
{
    "label": "Eritrea",
    "dial_code": "+291",
    "emoji": "🇪🇷",
    "value": "ER"
},
{
    "label": "Estonia",
    "dial_code": "+372",
    "emoji": "🇪🇪",
    "value": "EE"
},
{
    "label": "Ethiopia",
    "dial_code": "+251",
    "emoji": "🇪🇹",
    "value": "ET"
},
{
    "label": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "emoji": "🇫🇰",
    "value": "FK"
},
{
    "label": "Faroe Islands",
    "dial_code": "+298",
    "emoji": "🇫🇴",
    "value": "FO"
},
{
    "label": "Fiji",
    "dial_code": "+679",
    "emoji": "🇫🇯",
    "value": "FJ"
},
{
    "label": "Finland",
    "dial_code": "+358",
    "emoji": "🇫🇮",
    "value": "FI"
},
{
    "label": "France",
    "dial_code": "+33",
    "emoji": "🇫🇷",
    "value": "FR"
},
{
    "label": "French Guiana",
    "dial_code": "+594",
    "emoji": "🇬🇫",
    "value": "GF"
},
{
    "label": "French Polynesia",
    "dial_code": "+689",
    "emoji": "🇵🇫",
    "value": "PF"
},
{
    "label": "Gabon",
    "dial_code": "+241",
    "emoji": "🇬🇦",
    "value": "GA"
},
{
    "label": "Gambia",
    "dial_code": "+220",
    "emoji": "🇬🇲",
    "value": "GM"
},
{
    "label": "Georgia",
    "dial_code": "+995",
    "emoji": "🇬🇪",
    "value": "GE"
},
{
    "label": "Germany",
    "dial_code": "+49",
    "emoji": "🇩🇪",
    "value": "DE"
},
{
    "label": "Ghana",
    "dial_code": "+233",
    "emoji": "🇬🇭",
    "value": "GH"
},
{
    "label": "Gibraltar",
    "dial_code": "+350",
    "emoji": "🇬🇮",
    "value": "GI"
},
{
    "label": "Greece",
    "dial_code": "+30",
    "emoji": "🇬🇷",
    "value": "GR"
},
{
    "label": "Greenland",
    "dial_code": "+299",
    "emoji": "🇬🇱",
    "value": "GL"
},
{
    "label": "Grenada",
    "dial_code": "+1473",
    "emoji": "🇬🇩",
    "value": "GD"
},
{
    "label": "Guadeloupe",
    "dial_code": "+590",
    "emoji": "🇬🇵",
    "value": "GP"
},
{
    "label": "Guam",
    "dial_code": "+1671",
    "emoji": "🇬🇺",
    "value": "GU"
},
{
    "label": "Guatemala",
    "dial_code": "+502",
    "emoji": "🇬🇹",
    "value": "GT"
},
{
    "label": "Guernsey",
    "dial_code": "+44",
    "emoji": "🇬🇬",
    "value": "GG"
},
{
    "label": "Guinea",
    "dial_code": "+224",
    "emoji": "🇬🇳",
    "value": "GN"
},
{
    "label": "Guinea-Bissau",
    "dial_code": "+245",
    "emoji": "🇬🇼",
    "value": "GW"
},
{
    "label": "Guyana",
    "dial_code": "+595",
    "emoji": "🇬🇾",
    "value": "GY"
},
{
    "label": "Haiti",
    "dial_code": "+509",
    "emoji": "🇭🇹",
    "value": "HT"
},
{
    "label": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "emoji": "🇻🇦",
    "value": "VA"
},
{
    "label": "Honduras",
    "dial_code": "+504",
    "emoji": "🇭🇳",
    "value": "HN"
},
{
    "label": "Hong Kong",
    "dial_code": "+852",
    "emoji": "🇭🇰",
    "value": "HK"
},
{
    "label": "Hungary",
    "dial_code": "+36",
    "emoji": "🇭🇺",
    "value": "HU"
},
{
    "label": "Iceland",
    "dial_code": "+354",
    "emoji": "🇮🇸",
    "value": "IS"
},
{
    "label": "India",
    "dial_code": "+91",
    "emoji": "🇮🇳",
    "value": "IN"
},
{
    "label": "Indonesia",
    "dial_code": "+62",
    "emoji": "🇮🇩",
    "value": "ID"
},
{
    "label": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "emoji": "🇮🇷",
    "value": "IR"
},
{
    "label": "Iraq",
    "dial_code": "+964",
    "emoji": "🇮🇷",
    "value": "IQ"
},
{
    "label": "Ireland",
    "dial_code": "+353",
    "emoji": "🇮🇪",
    "value": "IE"
},
{
    "label": "Isle of Man",
    "dial_code": "+44",
    "emoji": "🇮🇲",
    "value": "IM"
},
{
    "label": "Israel",
    "dial_code": "+972",
    "emoji": "🇮🇱",
    "value": "IL"
},
{
    "label": "Italy",
    "dial_code": "+39",
    "emoji": "🇮🇹",
    "value": "IT"
},
{
    "label": "Jamaica",
    "dial_code": "+1876",
    "emoji": "🇯🇲",
    "value": "JM"
},
{
    "label": "Japan",
    "dial_code": "+81",
    "emoji": "🇯🇵",
    "value": "JP"
},
{
    "label": "Jersey",
    "dial_code": "+44",
    "emoji": "🇯🇪",
    "value": "JE"
},
{
    "label": "Jordan",
    "dial_code": "+962",
    "emoji": "🇯🇴",
    "value": "JO"
},
{
    "label": "Kazakhstan",
    "dial_code": "+77",
    "emoji": "🇰🇿",
    "value": "KZ"
},
{
    "label": "Kenya",
    "dial_code": "+254",
    "emoji": "🇰🇪",
    "value": "KE"
},
{
    "label": "Kiribati",
    "dial_code": "+686",
    "emoji": "🇰🇮",
    "value": "KI"
},
{
    "label": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "emoji": "🇰🇵",
    "value": "KP"
},
{
    "label": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "emoji": "🇰🇷",
    "value": "KR"
},
{
    "label": "Kuwait",
    "dial_code": "+965",
    "emoji": "🇰🇼",
    "value": "KW"
},
{
    "label": "Kyrgyzstan",
    "dial_code": "+996",
    "emoji": "🇰🇬",
    "value": "KG"
},
{
    "label": "Laos",
    "dial_code": "+856",
    "emoji": "🇱🇦",
    "value": "LA"
},
{
    "label": "Latvia",
    "dial_code": "+371",
    "emoji": "🇱🇻",
    "value": "LV"
},
{
    "label": "Lebanon",
    "dial_code": "+961",
    "emoji": "🇱🇧",
    "value": "LB"
},
{
    "label": "Lesotho",
    "dial_code": "+266",
    "emoji": "🇱🇸",
    "value": "LS"
},
{
    "label": "Liberia",
    "dial_code": "+231",
    "emoji": "🇱🇷",
    "value": "LR"
},
{
    "label": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "emoji": "🇱🇾",
    "value": "LY"
},
{
    "label": "Liechtenstein",
    "dial_code": "+423",
    "emoji": "🇱🇮",
    "value": "LI"
},
{
    "label": "Lithuania",
    "dial_code": "+370",
    "emoji": "🇱🇹",
    "value": "LT"
},
{
    "label": "Luxembourg",
    "dial_code": "+352",
    "emoji": "🇱🇺",
    "value": "LU"
},
{
    "label": "Macao",
    "dial_code": "+853",
    "emoji": "🇲🇴",
    "value": "MO"
},
{
    "label": "Macedonia",
    "dial_code": "+389",
    "emoji": "🇲🇰",
    "value": "MK"
},
{
    "label": "Madagascar",
    "dial_code": "+261",
    "emoji": "🇲🇬",
    "value": "MG"
},
{
    "label": "Malawi",
    "dial_code": "+265",
    "emoji": "🇲🇼",
    "value": "MW"
},
{
    "label": "Malaysia",
    "dial_code": "+60",
    "emoji": "🇲🇾",
    "value": "MY"
},
{
    "label": "Maldives",
    "dial_code": "+960",
    "emoji": "🇲🇻",
    "value": "MV"
},
{
    "label": "Mali",
    "dial_code": "+223",
    "emoji": "🇲🇱",
    "value": "ML"
},
{
    "label": "Malta",
    "dial_code": "+356",
    "emoji": "🇲🇹",
    "value": "MT"
},
{
    "label": "Marshall Islands",
    "dial_code": "+692",
    "emoji": "🇲🇭",
    "value": "MH"
},
{
    "label": "Martinique",
    "dial_code": "+596",
    "emoji": "🇲🇶",
    "value": "MQ"
},
{
    "label": "Mauritania",
    "dial_code": "+222",
    "emoji": "🇲🇷",
    "value": "MR"
},
{
    "label": "Mauritius",
    "dial_code": "+230",
    "emoji": "🇲🇺",
    "value": "MU"
},
{
    "label": "Mayotte",
    "dial_code": "+262",
    "emoji": "🇾🇹",
    "value": "YT"
},
{
    "label": "Mexico",
    "dial_code": "+52",
    "emoji": "🇲🇽",
    "value": "MX"
},
{
    "label": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "emoji": "🇫🇲",
    "value": "FM"
},
{
    "label": "Moldova",
    "dial_code": "+373",
    "emoji": "🇲🇩",
    "value": "MD"
},
{
    "label": "Monaco",
    "dial_code": "+377",
    "emoji": "🇲🇨",
    "value": "MC"
},
{
    "label": "Mongolia",
    "dial_code": "+976",
    "emoji": "🇲🇳",
    "value": "MN"
},
{
    "label": "Montenegro",
    "dial_code": "+382",
    "emoji": "🇲🇪",
    "value": "ME"
},
{
    "label": "Montserrat",
    "dial_code": "+1664",
    "emoji": "🇲🇸",
    "value": "MS"
},
{
    "label": "Morocco",
    "dial_code": "+212",
    "emoji": "🇲🇦",
    "value": "MA"
},
{
    "label": "Mozambique",
    "dial_code": "+258",
    "emoji": "🇲🇿",
    "value": "MZ"
},
{
    "label": "Myanmar",
    "dial_code": "+95",
    "emoji": "🇲🇲",
    "value": "MM"
},
{
    "label": "Namibia",
    "emoji": "🇳🇦",
    "dial_code": "+264",
    "value": "NA"
},
{
    "label": "Nauru",
    "dial_code": "+674",
    "emoji": "🇳🇷",
    "value": "NR"
},
{
    "label": "Nepal",
    "dial_code": "+977",
    "emoji": "🇳🇵",
    "value": "NP"
},
{
    "label": "Netherlands",
    "dial_code": "+31",
    "emoji": "🇳🇱",
    "value": "NL"
},
{
    "label": "Netherlands Antilles",
    "dial_code": "+599",
    "emoji": "🇧🇶",
    "value": "AN"
},
{
    "label": "New Caledonia",
    "dial_code": "+687",
    "emoji": "🇳🇨",
    "value": "NC"
},
{
    "label": "New Zealand",
    "dial_code": "+64",
    "emoji": "🇳🇿",
    "value": "NZ"
},
{
    "label": "Nicaragua",
    "dial_code": "+505",
    "emoji": "🇳🇮",
    "value": "NI"
},
{
    "label": "Niger",
    "dial_code": "+227",
    "emoji": "🇳🇪",
    "value": "NE"
},
{
    "label": "Nigeria",
    "dial_code": "+234",
    "emoji": "🇳🇬",
    "value": "NG"
},
{
    "label": "Niue",
    "dial_code": "+683",
    "emoji": "🇳🇺",
    "value": "NU"
},
{
    "label": "Norfolk Island",
    "dial_code": "+672",
    "emoji": "🇳🇫",
    "value": "NF"
},
{
    "label": "Northern Mariana Islands",
    "dial_code": "+1670",
    "emoji": "🇲🇵",
    "value": "MP"
},
{
    "label": "Norway",
    "dial_code": "+47",
    "emoji": "🇳🇴",
    "value": "NO"
},
{
    "label": "Oman",
    "dial_code": "+968",
    "emoji": "🇴🇲",
    "value": "OM"
},
{
    "label": "Pakistan",
    "dial_code": "+92",
    "emoji": "🇵🇰",
    "value": "PK"
},
{
    "label": "Palau",
    "dial_code": "+680",
    "emoji": "🇵🇼",
    "value": "PW"
},
{
    "label": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "emoji": "🇵🇸",
    "value": "PS"
},
{
    "label": "Panama",
    "dial_code": "+507",
    "emoji": "🇵🇦",
    "value": "PA"
},
{
    "label": "Papua New Guinea",
    "dial_code": "+675",
    "emoji": "🇵🇬",
    "value": "PG"
},
{
    "label": "Paraguay",
    "dial_code": "+595",
    "emoji": "🇵🇾",
    "value": "PY"
},
{
    "label": "Peru",
    "dial_code": "+51",
    "emoji": "🇵🇪",
    "value": "PE"
},
{
    "label": "Philippines",
    "dial_code": "+63",
    "emoji": "🇵🇭",
    "value": "PH"
},
{
    "label": "Pitcairn",
    "dial_code": "+872",
    "emoji": "🇵🇳",
    "value": "PN"
},
{
    "label": "Poland",
    "dial_code": "+48",
    "emoji": "🇵🇱",
    "value": "PL"
},
{
    "label": "Portugal",
    "dial_code": "+351",
    "emoji": "🇵🇹",
    "value": "PT"
},
{
    "label": "Puerto Rico",
    "dial_code": "+1939",
    "emoji": "🇵🇷",
    "value": "PR"
},
{
    "label": "Qatar",
    "dial_code": "+974",
    "emoji": "🇶🇦",
    "value": "QA"
},
{
    "label": "Romania",
    "dial_code": "+40",
    "emoji": "🇷🇴",
    "value": "RO"
},
{
    "label": "Russia",
    "dial_code": "+7",
    "emoji": "🇷🇺",
    "value": "RU"
},
{
    "label": "Rwanda",
    "dial_code": "+250",
    "emoji": "🇷🇼",
    "value": "RW"
},
{
    "label": "Reunion",
    "dial_code": "+262",
    "emoji": "🇷🇪",
    "value": "RE"
},
{
    "label": "Saint Barthelemy",
    "dial_code": "+590",
    "emoji": "🇧🇱",
    "value": "BL"
},
{
    "label": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "emoji": "🇸🇭",
    "value": "SH"
},
{
    "label": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "emoji": "🇰🇳",
    "value": "KN"
},
{
    "label": "Saint Lucia",
    "dial_code": "+1758",
    "emoji": "🇱🇨",
    "value": "LC"
},
{
    "label": "Saint Martin",
    "dial_code": "+590",
    "emoji": "🇲🇫",
    "value": "MF"
},
{
    "label": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "emoji": "🇵🇲",
    "value": "PM"
},
{
    "label": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "emoji": "🇻🇨",
    "value": "VC"
},
{
    "label": "Samoa",
    "dial_code": "+685",
    "emoji": "🇼🇸",
    "value": "WS"
},
{
    "label": "San Marino",
    "dial_code": "+378",
    "emoji": "🇸🇲",
    "value": "SM"
},
{
    "label": "Sao Tome and Principe",
    "dial_code": "+239",
    "emoji": "🇸🇹",
    "value": "ST"
},
{
    "label": "Saudi Arabia",
    "dial_code": "+966",
    "emoji": "🇸🇦",
    "value": "SA"
},
{
    "label": "Senegal",
    "dial_code": "+221",
    "emoji": "🇸🇳",
    "value": "SN"
},
{
    "label": "Serbia",
    "dial_code": "+381",
    "emoji": "🇷🇸",
    "value": "RS"
},
{
    "label": "Seychelles",
    "dial_code": "+248",
    "emoji": "🇸🇨",
    "value": "SC"
},
{
    "label": "Sierra Leone",
    "dial_code": "+232",
    "emoji": "🇸🇱",
    "value": "SL"
},
{
    "label": "Singapore",
    "dial_code": "+65",
    "emoji": "🇸🇬",
    "value": "SG"
},
{
    "label": "Slovakia",
    "dial_code": "+421",
    "emoji": "🇸🇰",
    "value": "SK"
},
{
    "label": "Slovenia",
    "dial_code": "+386",
    "emoji": "🇸🇮",
    "value": "SI"
},
{
    "label": "Solomon Islands",
    "dial_code": "+677",
    "emoji": "🇸🇧",
    "value": "SB"
},
{
    "label": "Somalia",
    "dial_code": "+252",
    "emoji": "🇸🇴",
    "value": "SO"
},
{
    "label": "South Africa",
    "dial_code": "+27",
    "emoji": "🇿🇦",
    "value": "ZA"
},
{
    "label": "South Sudan",
    "dial_code": "+211",
    "emoji": "🇸🇸",
    "value": "SS"
},
{
    "label": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "emoji": "🇬🇸",
    "value": "GS"
},
{
    "label": "Spain",
    "dial_code": "+34",
    "emoji": "🇪🇸",
    "value": "ES"
},
{
    "label": "Sri Lanka",
    "dial_code": "+94",
    "emoji": "🇱🇰",
    "value": "LK"
},
{
    "label": "Sudan",
    "dial_code": "+249",
    "emoji": "🇸🇩",
    "value": "SD"
},
{
    "label": "Suriname",
    "dial_code": "+597",
    "emoji": "🇸🇷",
    "value": "SR"
},
{
    "label": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "emoji": "🇸🇯",
    "value": "SJ"
},
{
    "label": "Swaziland",
    "dial_code": "+268",
    "emoji": "🇸🇿",
    "value": "SZ"
},
{
    "label": "Sweden",
    "dial_code": "+46",
    "emoji": "🇸🇪",
    "value": "SE"
},
{
    "label": "Switzerland",
    "dial_code": "+41",
    "emoji": "🇨🇭",
    "value": "CH"
},
{
    "label": "Syrian Arab Republic",
    "dial_code": "+963",
    "emoji": "🇸🇾",
    "value": "SY"
},
{
    "label": "Taiwan",
    "dial_code": "+886",
    "emoji": "🇹🇼",
    "value": "TW"
},
{
    "label": "Tajikistan",
    "dial_code": "+992",
    "emoji": "🇹🇯",
    "value": "TJ"
},
{
    "label": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "emoji": "🇹🇿",
    "value": "TZ"
},
{
    "label": "Thailand",
    "dial_code": "+66",
    "emoji": "🇹🇭",
    "value": "TH"
},
{
    "label": "Timor-Leste",
    "dial_code": "+670",
    "emoji": "🇹🇱",
    "value": "TL"
},
{
    "label": "Togo",
    "dial_code": "+228",
    "emoji": "🇹🇬",
    "value": "TG"
},
{
    "label": "Tokelau",
    "dial_code": "+690",
    "emoji": "🇹🇰",
    "value": "TK"
},
{
    "label": "Tonga",
    "dial_code": "+676",
    "emoji": "🇹🇴",
    "value": "TO"
},
{
    "label": "Trinidad and Tobago",
    "dial_code": "+1868",
    "emoji": "🇹🇹",
    "value": "TT"
},
{
    "label": "Tunisia",
    "dial_code": "+216",
    "emoji": "🇹🇳",
    "value": "TN"
},
{
    "label": "Turkey",
    "dial_code": "+90",
    "emoji": "🇹🇷",
    "value": "TR"
},
{
    "label": "Turkmenistan",
    "dial_code": "+993",
    "emoji": "🇹🇲",
    "value": "TM"
},
{
    "label": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "emoji": "🇹🇨",
    "value": "TC"
},
{
    "label": "Tuvalu",
    "dial_code": "+688",
    "emoji": "🇹🇻",
    "value": "TV"
},
{
    "label": "Uganda",
    "dial_code": "+256",
    "emoji": "🇺🇬",
    "value": "UG"
},
{
    "label": "Ukraine",
    "dial_code": "+380",
    "emoji": "🇺🇦",
    "value": "UA"
},
{
    "label": "United Arab Emirates",
    "dial_code": "+971",
    "emoji": "🇦🇪",
    "value": "AE"
},
{
    "label": "United Kingdom",
    "dial_code": "+44",
    "emoji": "🇬🇧",
    "value": "GB"
},
{
    "label": "United States",
    "dial_code": "+1",
    "emoji": "🇺🇸",
    "value": "US"
},
{
    "label": "Uruguay",
    "dial_code": "+598",
    "emoji": "🇺🇾",
    "value": "UY"
},
{
    "label": "Uzbekistan",
    "dial_code": "+998",
    "emoji": "🇺🇿",
    "value": "UZ"
},
{
    "label": "Vanuatu",
    "dial_code": "+678",
    "emoji": "🇻🇺",
    "value": "VU"
},
{
    "label": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "emoji": "🇻🇪",
    "value": "VE"
},
{
    "label": "Vietnam",
    "dial_code": "+84",
    "emoji": "🇻🇳",
    "value": "VN"
},
{
    "label": "Virgin Islands, British",
    "dial_code": "+1284",
    "emoji": "🇻🇬",
    "value": "VG"
},
{
    "label": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "emoji": "🇻🇮",
    "value": "VI"
},
{
    "label": "Wallis and Futuna",
    "dial_code": "+681",
    "emoji": "🇼🇫",
    "value": "WF"
},
{
    "label": "Yemen",
    "dial_code": "+967",
    "emoji": "🇾🇪",
    "value": "YE"
},
{
    "label": "Zambia",
    "dial_code": "+260",
    "emoji": "🇿🇲",
    "value": "ZM"
},
{
    "label": "Zimbabwe",
    "dial_code": "+263",
    "emoji": "🇿🇼",
    "value": "ZW"
}
]
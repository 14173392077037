// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createSchema = async (
    // platformId,
    code,
    instructions
) => {
    const url = '/schemas'
    return await axiosInstance.post(
        url,
        {
            // platformId,
            code,
            instructions
        },
    )
}

export const updateSchema = async (
    code,
    instructions,
    id
) => {
    const url = '/schemas/' + id
    return await axiosInstance.put(
        url,
        {
            code,
            instructions
        },
    )
}

export const getSchemas = () => {
    const url = '/schemas'

    return axiosInstance.get(url)
}

export const getSchema = (id) => {
    const url = '/schemas/' + id

    return axiosInstance.get(url)
}

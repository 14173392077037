import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createProduct = async (
    code,
    label,
    type,
    featureItems,
) => {
    const url = '/account_profiles'
    return await axiosInstance.post(
        url,
        {
            code,
            label,
            type,
            featureItems,
        },
    )
}

export const updateProduct = async (
    code,
    label,
    type,
    featureItems,
    id
) => {
    const url = 'https://api.kurrency.demo.e-makuta.com/api/account_profiles/' + id
    return await axios.put(
        url,
        {
            code,
            label,
            type,
            featureItems,
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }
    )
}

export const getProducts = () => {
    const url = '/account_profiles'
    return axiosInstance.get(url)
}

export const getProductById = (id) => {
    const url = 'https://api.kurrency.demo.e-makuta.com/api/account_profiles/' + id
    return axios.get(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    })
}


import React, { useEffect, useState } from 'react'
import Paginate from '../Paginate'
import { HiArrowDown, HiArrowUp } from "react-icons/hi"

function ModelOne({ children, headers, styles = '', arrayItems, setArrayItems, itemsPerPage }) {
  const [currentPage, setCurrentPage] = useState(1)
  const [defaultItemsPerPage, setDefaultItemsPerPage] = useState(itemsPerPage)
  const [defaultArray, setDefaultArray] = useState(arrayItems)

  const paginate = (pageNumber) => {
    const lastItemIndex = pageNumber * itemsPerPage
    const firstItemIndex = lastItemIndex - itemsPerPage
    const currentItems = arrayItems.slice(firstItemIndex, lastItemIndex)

    setCurrentPage(pageNumber)
    setArrayItems(currentItems)
    setDefaultItemsPerPage(itemsPerPage)
    setDefaultArray(arrayItems)
  }

  const previousPage = () => {
    if (currentPage !== 1) {
      paginate(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(arrayItems.length / itemsPerPage)) {
      paginate(currentPage + 1)
    }
  }

  const reverseArray = () => {
    let newArr = [...defaultArray].reverse();
    setArrayItems(newArr)
    setDefaultArray(newArr)
  }

  useEffect(() => {
    if (itemsPerPage !== defaultItemsPerPage) {
      paginate(1)
    }
  })

  useEffect(() => {
    if (defaultArray.toString() !== arrayItems.toString()) {
      paginate(1)
    }
  })
  return (
    <div className="overflow-auto">
      <table className="w-full border-2">
        <thead className="border-b-2 border-gray-400 bg-[#e6e4e4]">
          <tr>
            {headers.map((el, i) => (
              <th
                className={`${el === 'Action' ? 'text-center' : 'text-left'
                  } p-3 text-sm font-semibold tracking-wide  text-gray-500`}
                key={i}
              >
                <div className='flex flex-row items-center gap-2'>
                  {el}
                  {(el !== 'Action' && i === 0) && (
                    <span className='flex flex-col text-[7.5px] gap-1'>
                      <HiArrowUp onClick={() => reverseArray()} className='cursor-pointer' />
                      <HiArrowDown onClick={() => reverseArray()} className='cursor-pointer' />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
          {arrayItems.length > itemsPerPage && (
            <tr className='py-2'>
              <td className='pb-3 pl-5' colSpan={headers.length}>
                <Paginate
                  itemsPerPage={itemsPerPage}
                  totalItems={arrayItems.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ModelOne

import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, HiDocumentReport, HiPencil } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { getComptes, createCompte, updateCompte } from '../../../api/account'
import MessagePanel from '../../../component/Card/MessagePanel'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getProducts } from '../../../api/products'
import { goTo, refresh, currencies } from '../../../helpers/helper'
import { getCustomers } from '../../../api/customer'
import { setChildVisible } from '../../../helpers/roleManager'
import { getPartitions } from '../../../api/partition'

function Comptes() {
    const userState = useSelector((state) => state.auth.user)
    const user = userState !== null && ((typeof userState) === 'object' ? userState : JSON.parse(userState))

    const [itShowEdit, setItShowEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_, setIsLoading_] = useState(false)
    const [isLoading__, setIsLoading__] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [products, setProducts] = useState([])
    const [defaultStatus, setDefaultStatus] = useState('A')
    const [customers, setCustomers] = useState([])
    const [customersFiltered, setCustomersFiltered] = useState([])
    const [compteLabel, setCompteLabel] = useState('')
    const [defaultCurrency, setDefaultCurrency] = useState('AA')
    const [labelSearch, setLabelSearch] = useState('')
    const [partitions, setPartitions] = useState([])
    const [partitionsFiltered, setPartitionsFiltered] = useState([])

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [compte, setCompte] = useState({
        name: '',
        profile: '',
        currency: 'USD',
        customerId: '',
        branchId: user?.partition?.id
    })

    // console.log(user)

    const [comptes, setComptes] = useState([])
    const [comptesFiltered, setComptesFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)

    const headers = [
        "Id",
        "Numéro de compte",
        "Nom",
        'Balance',
        'Devise',
        "Statut",
        setChildVisible(user?.roles, ['ROLE_ACCOUNT_DETAILS', 'ROLE_ENTRY_LIST']) === true && "Action"
    ]

    const statusItems = [
        {
            label: "Tout",
            value: 'A',
        },
        {
            label: "Ouvert",
            value: 'false',
        },
        {
            label: "Fermé",
            value: 'true',
        }
    ]

    const resetCompte = useCallback(() => {
        setCompte((prev) => ({
            ...prev,
            name: '',
            profile: products[0].value,
            currency: 'USD',
            customerId: '',
            branchId: user?.partition?.id,
            id: undefined
        }))
    }, [products, user])

    const toggleModal = useCallback(() => {
        resetCompte()
        setShowModal(!showModal)
        setItShowEdit(false)
        setShowMessage(false)
    }, [showModal, resetCompte])

    const currency = [
        { label: 'US Dollars', value: 'USD', default: true },
        { label: 'FC Congolais', value: 'CDF' },
    ]

    const filterByStatus = useCallback((status) => {
        if (status === 'A') {
            setComptesFiltered(comptes)
        } else {
            let startArray = comptes
            let lastArray = startArray.filter((el) => el.closed.toString() === status.toString())
            setComptesFiltered(lastArray)
            setDefaultStatus(status)
        }
    }, [comptes])

    const setItemSearch = async (item) => {
        if (item !== undefined) {
            setCompte((prev) => ({
                ...prev,
                branchId: `${item.id}`
            }))

            setLabelSearch(`${item.id} | ${item.name}`)
            setIsLoading__(false)
        }
    }

    const getProductsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getProducts().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((el, i) => {
                    if (i === 0) {
                        newArr.push({ value: el.id, label: `${el.label} ${el.currency} (${el.code})`, default: true })
                    } else {
                        newArr.push({ value: el.id, label: `${el.label} ${el.currency} (${el.code})` })
                    }
                })
                setProducts(newArr)
                setCompte((prev) => ({
                    ...prev,
                    profile: newArr[0].value
                }))
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [])

    const getPartitionsList = useCallback(async () => {
        try {
            await getPartitions()
                .then(({ data }) => {
                    setPartitions(data)
                    setPartitionsFiltered(data)
                })
                .catch((error) => {
                    console.log("Try : => ", error)
                })
        } catch (error) {
            console.log("Catch : =>", error)
        }
    }, [])

    const editShowItem = (item) => {
        // console.log(item)
        setShowModal(true)
        setCompte((prev) => ({
            ...prev,
            name: item.name,
            currency: item.currency,
            customerId: item.holderId,
            id: item.id,
            holderId: item.holderId
        }))
    }

    useEffect(() => {
        if (products.length === 0 && isLoad === false) {
            getProductsList()
        }
    }, [products, getProductsList, isLoad])

    const getComptesList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getComptes()
                .then(({ data }) => {
                    const result = data
                    setComptes(result)

                    setComptesFiltered(result)
                    setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                    let arrayItemsPerPage = []
                    for (let i = 1; i <= result.length; i++) {
                        if (i === 1) {
                            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                        } else {

                            arrayItemsPerPage.push({ value: i, label: i.toString() })
                        }
                    }

                    setItemsPerPageArray(arrayItemsPerPage)
                    setIsLoading(false)
                    setIsLoad(true)
                })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error.response.data)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage])

    const setCreateCompte = useCallback(async () => {
        if (compte.name !== '' && compte.profile !== '' && compte.branchId !== undefined && compte.branchId !== '' && compte.branchId !== null) {
            if (compte.id !== undefined) {
                setIsLoading(true)
                try {
                    await updateCompte(compte, compte.id)
                        .then(() => {
                            getComptesList()
                            setIsLoading(false)
                            resetCompte()
                            toggleModal()
                        }).catch((error) => {
                            console.log(error)
                            setIsLoading(false)
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            } else {
                setIsLoading(true)
                try {
                    await createCompte(compte)
                        .then(() => {
                            getComptesList()
                            setIsLoading(false)
                            resetCompte()
                            toggleModal()
                        }).catch((error) => {
                            console.log(error)
                            setIsLoading(false)
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            }
        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }, [getComptesList, compte, toggleModal, resetCompte])

    const setCustomerId = (item) => {
        if (item !== undefined) {
            setCompte((prev) => ({
                ...prev,
                customerId: item.id
            }))

            setCompteLabel(`Nom : ${item.firstName} ${item.lastName} | ID : ${item.id}`)
            setIsLoading_(false)
        }
    }

    const getCustomersList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getCustomers().then((res) => {
                const result = res.data
                setCustomers(result)
                setCustomersFiltered(result)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [])

    const filterByDevise = useCallback((devise) => {
        if (devise === 'AA') {
            setComptesFiltered(comptes)
        } else {
            let startArray = comptes
            let lastArray = startArray.filter((el) => el.currency === devise)
            setComptesFiltered(lastArray)
            setDefaultCurrency(devise)
        }
    }, [comptes])


    useEffect(() => {
        if (filter.currency !== defaultCurrency) {
            filterByDevise(filter.currency)
        }
    }, [filter, defaultCurrency, filterByDevise])

    useEffect(() => {
        if (comptes.length === 0 && isLoad === false) {
            getComptesList()
        }
    }, [comptes, getComptesList, isLoad])


    useEffect(() => {
        if (filter.status !== defaultStatus) {
            filterByStatus(filter.status)
        }
    }, [filter, defaultStatus, filterByStatus])

    useEffect(() => {
        if (customers.length === 0 && isLoad === false) {
            getCustomersList()
        }
    }, [customers, getCustomersList, isLoad])

    useEffect(() => {
        if (partitions.length === 0) {
            getPartitionsList()
        }
    }, [getPartitionsList, partitions])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={comptes} setArrayFiltered={setComptesFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-4/12'>
                                    <Input label={'Filtrer par devise'} id={'currency'} value={filter.currency} setValue={setFilter} selectItems={currencies} select={true} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => toggleModal()} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un Compte</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={comptesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {comptesFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.accountNumber}</td>
                                    <td className='p-3'>{item.name}</td>
                                    <td className='p-3'>{item.balance}</td>
                                    <td className='p-3'>{item.currency}</td>
                                    <td className='p-3'>{item.closed ? "Clôturer" : "Ouvert"}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button onClick={() => editShowItem(item)} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_DETAILS']) === true ? 'px-5 py-2 border-2 rounded-md text-[16px]' : 'hidden'}`}>
                                            <HiEye />
                                        </button>

                                        <button onClick={() => goTo(`entries-manager/${item.id}`)} className={`${setChildVisible(user?.roles, ['ROLE_ENTRY_DETAILS']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>
                                            <HiDocumentReport />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{compte.id !== undefined ? 'Information Compte' : 'Ajouter un Compte'}</h3>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} label={'Nom du Compte'} placeholder="Nom du Compte" id={'name'} value={compte.name} setValue={setCompte} />
                            </div>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} select={true} selectItems={products} label={'Produit'} id={'profile'} value={compte.profile} setValue={setCompte} />
                            </div>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} select={true} selectItems={currency} label={'Devise'} id={'currency'} value={compte.currency} setValue={setCompte} />
                            </div>

                            <div>
                                <SearchText
                                    arrayFiltered={setCustomersFiltered}
                                    initialArray={customers}
                                    setArrayFiltered={setCustomersFiltered}
                                    label={'Client'}
                                    required={true}
                                    setLoader={setIsLoading_}
                                    defaultValue={compteLabel}
                                    value={compte.holderId}
                                    setDefaultValue={setCompteLabel}
                                    disabled={compte.id !== undefined && !itShowEdit}
                                />
                            </div>



                            {(isLoading_ !== false) &&
                                <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                                    {customersFiltered.length > 0 ?
                                        customersFiltered.map((el, id) => (
                                            <button onClick={() => setCustomerId(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                                                {`Nom : ${el.firstName} ${el.lastName} | ID : ${el.id}`}
                                            </button>
                                        ))
                                        :
                                        <div className='w-full flex justify-center items-center'>
                                            Aucun compte trouver
                                        </div>}
                                </div>
                            }

                            <div className='flex flex-col my-4 gap-4'>
                                <div className='w-full'>
                                    <div className='w-full'>
                                        <SearchText
                                            arrayFiltered={partitionsFiltered}
                                            initialArray={partitions}
                                            setArrayFiltered={setPartitionsFiltered}
                                            label={'Partition'}
                                            required={compte.branchId === undefined || compte.branchId === null || compte.branchId === ''}
                                            setLoader={setIsLoading__}
                                            defaultValue={labelSearch}
                                            setDefaultValue={setLabelSearch}
                                            value={compte.branchId}
                                            disabled={compte.id !== undefined && !itShowEdit}
                                        />
                                    </div>
                                </div>

                                {(isLoading__ !== false) &&
                                    <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                                        {partitionsFiltered.length > 0 ?
                                            partitionsFiltered.map((el, id) => (
                                                <button onClick={() => setItemSearch(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                                                    {`${el.id} | ${el.name}`}
                                                </button>
                                            ))
                                            :
                                            <div className='w-full flex justify-center items-center'>
                                                Aucune partition trouver
                                            </div>}
                                    </div>
                                }
                            </div>

                            <div className='flex flex-row gap-4 mt-2'>
                                {(compte.id !== undefined) && (
                                    itShowEdit ?
                                        <button onClick={setCreateCompte} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`} disabled={isLoading}>
                                            {isLoading ? 'Modification en cours...' : 'Modifier le compte'}
                                        </button>
                                        :
                                        <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`}>
                                            <HiPencil />
                                        </button>
                                )}

                                {compte.id === undefined &&
                                    <button onClick={setCreateCompte} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_CREATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`} disabled={isLoading}>
                                        {isLoading ? 'Ajout en cours...' : 'Ajouter un compte'}
                                    </button>
                                }
                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default Comptes

import React from 'react'

function ToolTip({ element, children }) {
    return (
        <div className="dropdown">
            <button>{element}</button>
            <div className="dropdown-content">
                {children}
            </div>
        </div>
    )
}

export default ToolTip
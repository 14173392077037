import React from 'react'
import { Navigate, Outlet } from "react-router-dom"

function MiddleWare() {
    const isAuthenticated = localStorage.getItem('token')

    return (
        isAuthenticated !== null && isAuthenticated !== undefined ? (
            <Outlet />
        ) : (
            <Navigate to='/' />
        )
    )
}

export default MiddleWare
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import axios from 'axios'

export const Login = (token, user, refreshToken) => {
  return async (dispatch) => {
    if (token !== null && user !== null) {
      localStorage.setItem('user', JSON.stringify(user))

      dispatch({
        type: 'LOGIN',
        payload: { token: token, user: user, refreshToken: refreshToken },
      })
    }
  }
}

export const initAuth = () => {
  const token = localStorage.getItem('token')
  const user = localStorage.getItem('user')

  if (
    token !== undefined ||
    (token !== null && user !== undefined) ||
    user !== null
  ) {
    return async (dispatch) => {
      dispatch({
        type: 'LOGIN',
        payload: { token: token, user: user },
      })
    }
  } else {
    window.location.replace('/')
  }
}

export const Logout = () => {
  return async (dispatch) => {
    localStorage.clear()

    dispatch({
      type: 'LOGOUT',
      payload: { token: null, user: null },
    })
  }
}

import React from 'react'

function CardAuthForm({ children }) {
  return (
    <div className="bg-white md:min-h-[480px] md:min-w-[30%] md:max-w-[40%] max-w-[85%] rounded-md flex md:flex-column flex-col drop-shadow-md shadow-md md:px-3 px-3 py-10 justify-center items-center border-2 my-10">
      <div className="flex flex-col justify-center md:px-5 px-2 md:py-5 md:w-full">
        {children}
      </div>
    </div>
  )
}

export default CardAuthForm

// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createAccountType = async (
    code,
    label,
    // platformId,
    applyMaintenanceFee,
    supportInterest,
    interestRate,
    maintenanceFee,
    inactiveMaintenanceFee,
) => {
    const url = '/account_types'
    return await axiosInstance.post(
        url,
        {
            code,
            label,
            // platformId,
            applyMaintenanceFee,
            supportInterest,
            interestRate,
            maintenanceFee,
            inactiveMaintenanceFee,
        },
    )
}

export const updateAccountType = async (
    code,
    label,
    // platformId,
    applyMaintenanceFee,
    supportInterest,
    interestRate,
    maintenanceFee,
    inactiveMaintenanceFee,
    id
) => {
    const url = '/account_types/' + id
    return await axiosInstance.put(
        url,
        {
            code,
            label,
            // platformId,
            applyMaintenanceFee,
            supportInterest,
            interestRate,
            maintenanceFee,
            inactiveMaintenanceFee,
        },
    )
}

export const getAccountTypes = () => {
    const url = '/account_types'

    return axiosInstance.get(url)
}

export const getAccountTypeById = (id) => {
    const url = '/account_types/' + id

    return axiosInstance.get(url)
}


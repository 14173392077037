import React, { useState, useEffect, useCallback } from 'react'
import Input from "../Input"

function DateFilter({ setArrayToFilter, arrayToFilter = [], elementKey }) {
    const [dateFilter, setDateFilter] = useState({
        start: '',
        end: ''
    })
    const [isSet, setIsSet] = useState(false)
    const moment = require('moment')

    const filterByDate = useCallback(() => {
        let arrayFiltered = []

        arrayToFilter.forEach(element => {
            for (const key in element) {
                if (key === elementKey) {

                    if ((dateFilter.start !== undefined && dateFilter.start !== null && dateFilter.start !== '') ||
                        (dateFilter.end !== undefined && dateFilter.end !== null && dateFilter.end !== '')) {
                        if (moment(element[key]).format("y-MM-DD") >= dateFilter.start && moment(element[key]).format("y-MM-DD") <= dateFilter.end) {
                            arrayFiltered.push(element)
                        }
                    }

                    if (dateFilter.start !== undefined && dateFilter.start !== null && dateFilter.start !== '') {
                        if (moment(element[key]).format("y-MM-DD") === dateFilter.start) {
                            arrayFiltered.push(element)
                        }
                    }

                    if (dateFilter.end !== undefined && dateFilter.end !== null && dateFilter.end !== '') {
                        if (moment(element[key]).format("y-MM-DD") === dateFilter.end) {
                            arrayFiltered.push(element)
                        }
                    }
                }
            }
        })

        setArrayToFilter(arrayFiltered)
        setIsSet(false)
    }, [elementKey, setArrayToFilter, arrayToFilter, dateFilter, moment])

    useEffect(() => {
        if (isSet === false && (dateFilter.start !== '' || dateFilter.end !== '')) {
            filterByDate()
        }
    }, [dateFilter.start, dateFilter.end, filterByDate, isSet])

    return (
        <div>
            <div className='flex flex-row gap-4'>
                <div className='w-6/12'>
                    <Input label={'Du'} type='date' value={dateFilter.start} id={'start'} setValue={setDateFilter} />
                </div>

                <div className='w-6/12'>
                    <Input label={'Au'} type='date' value={dateFilter.end} id={'end'} setValue={setDateFilter} />
                </div>
            </div>
        </div >
    )
}

export default DateFilter
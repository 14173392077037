import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { setChildVisible } from '../../helpers/roleManager'

function MenuItem({
  text,
  logout = false,
  path_,
  icon,
  isOpen,
  orPath = [],
  role = [],
  roles = [],
  setSelectedItem
}) {
  const location = useLocation()

  const setPathItem = (path) => {
    const pathItem = path === '' ? '/dashboard' : '/dashboard/' + path
    return pathItem
  }

  const isMatched = () => {
    let result = false
    if (orPath.length > 0) {
      orPath.forEach((el) => {
        if (
          location.pathname === setPathItem(path_) ||
          location.pathname === setPathItem(path_) + '/' ||
          location.pathname === setPathItem(el) ||
          location.pathname.includes(setPathItem(el))
        ) {
          result = true
        }
      })
    } else {
      if (location.pathname === setPathItem(path_) || location.pathname === setPathItem(path_) + '/') {
        result = true
      }
    }

    return result
  }

  if (logout === true) {
    return (
      <div className="w-full h-full">
        <button
          onClick={setSelectedItem}
          type="button"
          className="flex flex-row items-center w-full px-2 py-2 my-4 bg-[#000064] rounded-md text-white"
        >
          <span
            className={`${!isOpen &&
              'text-2xl text-center flex flex-col items-center justify-center ml-[0px]'
              }`}
          >
            {icon}
          </span>
          <span
            className={`ml-2 font-normal text-[14px] ${!isOpen && 'opacity-0 translate-x-28 overflow-hidden'}`}
          >
            {text}
          </span>
        </button>
      </div>
    )
  }
  return (
    <div className="w-full h-full">
      <Link
        to={path_}
        className={`${setChildVisible(roles, role) ? 'flex flex-row justify-between items-center w-full px-2 py-2 my-3' : 'hidden'}`}
        style={
          isMatched() ? {
            backgroundColor: '#0064ff',
            borderRadius: '7.5px',
            color: '#fefefe',
          }
            : {}
        }
      >
        <div className='flex flex-row items-center'>
          <span
            className={`text-[20px] ${!isOpen &&
              ' text-[24px] text-center flex flex-col'
              }`}
          >
            {icon}
          </span>
          <span
            className={`ml-2 text-[14px] font-semibold ${!isOpen && 'opacity-0 translate-x-28 overflow-hidden'
              } `}
          >
            {text}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default MenuItem

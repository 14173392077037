import React, { useEffect, useState } from 'react'
import { HiBell, HiMenuAlt2 } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function HeaderMenu({ isOpen, setIsOpen }) {
  const userState = useSelector((state) => state.auth.user)
  const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (user !== null && user !== undefined) {
      setUsername(user.username === undefined ? user.id : user.username)
    }
  }, [user])

  return (
    <header
      className="
      flex flex-row
      py-5
      drop-shadow-md
      max-h-[80px]
      sticky
      top-0
      w-full
      z-10
      bg-white
      border-b-2
      border-y-gray-200
      border-solid
    "
    >
      <nav className="flex flex-row items-center justify-between w-full md:px-5">
        <div className="flex flex-row items-center">
          <button
            className="border-2 flex flex-col items-center justify-center p-2 mr-4 bg-gray-100 rounded-md drop-shadow-sm"
            onClick={() => setIsOpen(!isOpen)}
          >
            <h3 className="text-3xl text-[#555]">
              <HiMenuAlt2 />
            </h3>
          </button>

          <div>
            <Link to={'user-account'} className="font-sans text-xl font-semibold">
              {username + ' '}
              👋🏻
            </Link>
            <p className="font-sans text-sm font-thin">
              Content de vous revoir!
            </p>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          {/* <div className="flex flex-row items-center mr-2 w-80">
            <input
              type="search"
              name=""
              id=""
              className="
              flex-1
              px-5
              py-2
              bg-gray-100
              w-full
              rounded-md
              h-10
              text-[14px]
              border-2
            "
              placeholder="Recherche"
            />
          </div> */}
          <div className="flex flex-row items-center justify-end h-10 px-3 py-2 ml-2 bg-gray-100 rounded-md border-2">
            <span className="text-xl text-[#555]">
              <HiBell />
            </span>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default HeaderMenu

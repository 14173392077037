import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../component/Layout/SideBar'
import HeaderMenu from '../../component/Layout/HeaderMenu'
import Footer from '../../component/Layout/Footer'
import { useDispatch } from 'react-redux'
import { initAuth } from '../../store/actions/authActions'
// import { redirect } from 'react-router-dom'

function Dashboard() {
  var w = window.innerWidth;
  const [isOpen, setIsOpen] = useState(w <= 1440 ? false : true)
  const token = localStorage.getItem('token')
  const dispatch = useDispatch()

  const goBackHome = () => {
    if (token === undefined || token === null) {
      window.location.replace('/')
    } else {
      dispatch(initAuth())
    }
  }

  useEffect(() => {
    goBackHome()
  })

  return (
    <div className="flex flex-row w-full">
      <div className="min-w-1/12">
        <SideBar isOpen={isOpen} />
      </div>
      <main className="flex flex-col w-full">
        <HeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        <Outlet />
        <Footer />
      </main>
    </div>
  )
}

export default Dashboard

import React from 'react'
import Modal from '@mui/material/Modal';
import imageExtensions from 'image-extensions'

function ModalKyc({ open, onClose, url, withShow = false }) {
    const ext = (typeof url === 'string') && url.substring(url.length - 5)

    const setViewer = () => {
        if ((imageExtensions.filter((el) => ext.includes(el))).length > 0) {
            return (
                <img src={url} className='max-h-[500px] max-w-[850px]' alt='Kyc-document' />
            )
        } else if (ext.includes('pdf')) {
            return (
                <div>
                    <iframe src={url} width={850} height={500} title='kyc-pdf'></iframe>
                </div>
            );
        } else {
            return (
                <h3 className='text-lg text-center'>Aucun aperçu disponible</h3>
            )
        }
    }

    if (withShow === true) {

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        {ext && setViewer()}

                        <div className='flex flex-row gap-4 mt-4 items-center justify-center'>
                            {/* <button className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>Télécharger le fichier</button> */}
                            <button onClick={() => onClose(false)} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                        </div>
                    </div>
                </div>

            </Modal>
        )

    } else {

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
                        <h3 className='text-lg text-center'>Aucun aperçu disponible</h3>

                        <div className='flex flex-row gap-4 mt-4'>
                            {/* <button className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>Télécharger le fichier</button> */}
                            <button onClick={() => onClose(false)} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                        </div>
                    </div>
                </div>

            </Modal>
        )

    }
}

export default ModalKyc
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { getCustomers } from '../../../api/customer'
import { goTo, refresh } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'

function Clients() {
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const [filter, setFilter] = useState({
        searchText: '',
        status: 'A',
        itemsPerPage: 5
    })

    const [customers, setCustomers] = useState([])
    const [customersFiltered, setCustomersFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [defaultStatus, setDefaultStatus] = useState('A')

    // console.log(user)

    const headers = [
        "Id",
        "Prénom",
        "Nom",
        'Nationalité',
        'Genre',
        'Statut',
        setChildVisible(user?.roles, ['ROLE_CUSTOMER_DETAILS']) === true && "Action"
    ]

    const statusItems = [
        {
            label: "Tout",
            value: 'A',
            default: true
        },
        {
            label: "Validé",
            value: 'V',
        },
        {
            label: "En attente",
            value: 'P',
        }
    ]

    const filterByStatus = useCallback((status) => {
        if (status === 'A') {
            setCustomersFiltered(customers)
        } else {
            let startArray = customers
            let lastArray = startArray.filter((el) => el.status === status)
            setCustomersFiltered(lastArray)
            setDefaultStatus(status)
        }
    }, [customers])

    const getCustomersList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getCustomers().then(({ data }) => {
                const result = data

                setCustomers(result)
                setCustomersFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            // console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage])

    useEffect(() => {
        if (customers.length === 0 && isLoad === false) {
            getCustomersList()
        }
    }, [customers, getCustomersList, isLoad])

    useEffect(() => {
        if (filter.status !== defaultStatus) {
            filterByStatus(filter.status)
        }
    }, [filter, defaultStatus, filterByStatus])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={customers} setArrayFiltered={setCustomersFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => goTo('add-client-manager')} className={`${setChildVisible(user?.roles, ['ROLE_CUSTOMER_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un client</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={customersFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {customersFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.firstName}</td>
                                    <td className='p-3'>{item.lastName}</td>
                                    <td className='p-3'>{item.country}</td>
                                    <td className='p-3'>{item.gender === 'M' ? "Homme" : "Femme"}</td>
                                    <td className='p-3'>{item.status === 'V' ? "Validé" : "En attente"}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button onClick={() => goTo(`add-client-manager/${item.id}`)} className={`${setChildVisible(user?.roles, ['ROLE_CUSTOMER_DETAILS']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>
        </div >
    )
}

export default Clients

import { Link } from 'react-router-dom'
import Card from '../../../component/Card/CardAuthForm'
import notFound from '../../../assets/images/404.png'

export default function ErrorPage() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="w-[80%]">
        <Card bg="#fefefe">
          <div className="flex flex-col items-center justify-center h-full p-10 rounded-md">
            <img src={notFound} alt="not found sef" />
            <div>
              <h3 className="m-3 text-6xl text-center font-semi-bold">Oups</h3>
              <p className="text-center">
                🤦🏾‍♂️ Il y a un problème avec votre requête veillez verifier que
                vous êtes connecter ou si l'url est correct !!
                <br />
                Merci 🙏🏿 !
              </p>
            </div>
            <Link to={'/'}>
              <button className="py-2 px-10 mt-4 text-white rounded-md bg-[#ED326B]">
                Accueil
              </button>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  )
}

import React from 'react'
import icon from '../../assets/images/icon.png'
import logo from '../../assets/images/b-logo.png'
import MenuItem from './MenuItem'
import {
  HiUsers,
  HiLogout,
  HiTemplate,
  HiOutlineDocumentText,
  HiIdentification,
  HiHome,
  HiCreditCard,
  HiOutlinePuzzle,
  HiOutlineCube,
  HiCollection,
  HiArrowsExpand,
  HiOutlineCog,
  HiLibrary,
  HiOutlineOfficeBuilding
} from 'react-icons/hi'
import { FaUserCog } from "react-icons/fa"
// import { BiSolidUserAccount } from "react-icons/bi"
import { Logout } from '../../store/actions/authActions'
import { useDispatch } from 'react-redux'
import { FaCashRegister, FaUserTie, FaExchangeAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { setParentVisible } from '../../helpers/roleManager'

function SideBar({ isOpen }) {
  const menuItems_GA = [
    { icon: <HiLibrary />, text: 'Plateformes', path: 'plateformes', orPath: ['add-plateformes'], role: ['ROLE_ADMIN',] },
    { icon: <HiOutlineOfficeBuilding />, text: 'Partitions', path: 'partitions', role: ['ROLE_PLATFORM_ADMIN',] },
    { icon: <HiUsers />, text: 'Utilisateurs', path: 'users-manager', role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] },
    { icon: <FaUserTie />, text: 'Employés', path: 'employes-manager', role: ['ROLE_PLATFORM_ADMIN',] },
    { icon: <FaUserCog />, text: 'Profils', path: 'profiles-manager', role: ['ROLE_PLATFORM_ADMIN',] },
    { icon: <FaExchangeAlt />, text: 'Affectations', path: 'affectation-manager', role: ['ROLE_PLATFORM_ADMIN'] },
  ]

  const menuItems_BP = [
    {
      icon: <HiOutlineCube />, text: 'Services', path: 'services-manager', role: ['ROLE_FEATURE_LIST', 'ROLE_FEATURE_DETAILS']
    },
    {
      icon: <HiCollection />, text: 'Types des comptes', path: 'account-type-manager', orPath: ['add-account-type-manager'], role: ['ROLE_ACCOUNT_TYPE_LIST', 'ROLE_ACCOUNT_TYPE_DETAILS']
    },
    {
      icon: <HiTemplate />, text: 'Produits', path: 'product-manager', orPath: ['add-product-manager'], role: ['ROLE_ACCOUNT_PROFILE_LIST', 'ROLE_ACCOUNT_PROFILE_DETAILS']
    },
    // { icon: <HiOutlineDocumentText />, text: 'Contrat & Tarification', path: 'business-manager', orPath: ['business-manager-add'] },
  ]

  const menuItems_C = [
    {
      icon: <HiIdentification />, text: 'Clients', path: 'client-manager', orPath: ['add-client-manager'], role: ['ROLE_CUSTOMER_LIST', 'ROLE_CUSTOMER_DETAILS']
    },
    {
      icon: <HiOutlineDocumentText />, text: 'Contrats', path: 'contract-manager', role: ['ROLE_CONTRACT_LIST', 'ROLE_CONTRACT_DETAILS']
    },
  ]

  const menuItems_O = [
    {
      icon: <FaCashRegister />, text: 'Dépôt / Retrait', path: 'cash-manager', role: ['ROLE_CASHIN_CASHOUT_CREATE', 'ROLE_CASHIN_CASHOUT_DETAILS']
    },
    {
      icon: <HiOutlineCog />, text: 'Configurations', path: 'operation-manager', role: ['ROLE_OPERATION_LIST', 'ROLE_OPERATION_DETAILS']
    },
  ]

  const menuItems_CT = [
    {
      icon: <HiCreditCard />, text: 'Comptes', path: 'account-manager', orPath: ['entries-manager'], role: ['ROLE_ACCOUNT_LIST', 'ROLE_ACCOUNT_DETAILS']
    },
    {
      icon: <HiArrowsExpand />, text: 'Transactions', path: 'transactions-manager', role: ['ROLE_TX_LIST', 'ROLE_TX_DETAILS']
    },
    {
      icon: <HiOutlinePuzzle />, text: 'Schema', path: 'schema-manager', orPath: ['schema-manager-add'], role: ['ROLE_SCHEMA_LIST', 'ROLE_SCHEMA_DETAILS']
    },
  ]

  const dispatch = useDispatch()
  const userState = useSelector((state) => state.auth.user)
  const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

  // console.log(user?.roles)

  const logoutUser = () => {
    dispatch(Logout()).then(() => {
      window.location.replace('/')
    })
  }

  console.log(user)
  return (
    <main
      className={`min-h-screen bg-white border-2 border-r-gray-300 ${isOpen ? 'w-72' : 'w-[70px]'
        } shrink-0 duration-500 sticky top-0 left-0 z-20`}
    >
      <nav className="flex flex-col flex-1 min-h-screen py-5">
        <div className="flex flex-col items-center">
          <p className={`${isOpen ? ' px-5' : 'px-2'}`}>
            {isOpen ? (<img src={logo} alt='logo-banky' className='h-[130px]' />) : (<img src={icon} alt='icon-banky' className='h-[55px]' />)}
          </p>
        </div>

        <div className={`py-5 ${isOpen ? ' px-7 ' : 'px-3'}`}>
          <h3
            className={`font-medium text-neutral-500 text-[14px] ${isOpen ? '' : 'hidden'}`}
          >
            Application
          </h3>

          <div className="py-0">
            <MenuItem
              text={'Tableau de bord'}
              key={0}
              path_={''}
              icon={<HiHome />}
              isOpen={isOpen}
              role={['admin']}
              roles={['admin']}
            />
          </div>

          <h3
            className={`
              font-medium text-neutral-500 text-[14px] 
              ${isOpen ? '' : 'hidden'} 
              ${user?.roles !== undefined && (setParentVisible(menuItems_GA, user?.roles) === true ? '' : 'hidden')}`
            }
          >
            Gestion des Accès
          </h3>
          <div className="py-0">
            {menuItems_GA.map((item, index) => (
              <MenuItem
                text={item.text}
                key={index}
                path_={item.path}
                icon={item.icon}
                isOpen={isOpen}
                subMenu={item.subMenu}
                orPath={item.orPath}
                role={item.role}
                roles={user?.roles}
              />
            ))}
          </div>

          <h3
            className={`font-medium text-neutral-500 text-[14px] ${isOpen ? '' : 'hidden'} ${user?.roles !== undefined && (setParentVisible(menuItems_BP, user?.roles) === true ? '' : 'hidden')} `}
          >
            Business & Produits
          </h3>
          <div className="py-0">
            {menuItems_BP.map((item, index) => (
              <MenuItem
                text={item.text}
                key={index}
                path_={item.path}
                orPath={item.orPath}
                icon={item.icon}
                isOpen={isOpen}
                subMenu={item.subMenu}
                role={item.role}
                roles={user?.roles}
              />
            ))}
          </div>

          <h3
            className={`font-medium text-neutral-500 text-[14px] ${isOpen ? '' : 'hidden'} ${user?.roles !== undefined && (setParentVisible(menuItems_C, user?.roles) === true ? '' : 'hidden')} `}
          >
            Clientèle
          </h3>
          <div className="py-0">
            {menuItems_C.map((item, index) => (
              <MenuItem
                text={item.text}
                key={index}
                path_={item.path}
                icon={item.icon}
                isOpen={isOpen}
                subMenu={item.subMenu}
                orPath={item.orPath}
                role={item.role}
                roles={user?.roles}
              />
            ))}
          </div>

          <h3
            className={`font-medium text-neutral-500 text-[14px] ${isOpen ? '' : 'hidden'} ${user?.roles !== undefined && (setParentVisible(menuItems_O, user?.roles) === true ? '' : 'hidden')} `}
          >
            Opérations
          </h3>
          <div className="py-0">
            {menuItems_O.map((item, index) => (
              <MenuItem
                text={item.text}
                key={index}
                path_={item.path}
                icon={item.icon}
                isOpen={isOpen}
                subMenu={item.subMenu}
                orPath={item.orPath}
                role={item.role}
                roles={user?.roles}
              />
            ))}
          </div>

          <h3
            className={`font-medium text-neutral-500 text-[14px] ${isOpen ? '' : 'hidden'} ${user?.roles !== undefined && (setParentVisible(menuItems_CT, user?.roles) === true ? '' : 'hidden')} `}
          >
            Comptes et Écriture
          </h3>
          <div className="py-0">
            {menuItems_CT.map((item, index) => (
              <MenuItem
                text={item.text}
                key={index}
                path_={item.path}
                icon={item.icon}
                isOpen={isOpen}
                subMenu={item.subMenu}
                orPath={item.orPath}
                role={item.role}
                roles={user?.roles}
              />
            ))}
          </div>
        </div>
        <hr />
        <div
          className={`flex flex-col py-5 ${isOpen ? ' px-7 ' : 'px-2'}`}
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <div className="hidden">
            <h3 className="font-sans text-xl font-semibold">Hello Bonny 👋🏻</h3>
            <p className="font-sans text-sm font-thin">
              Content de vous revoir!
            </p>
          </div>
          <MenuItem
            text="Déconnexion"
            logout={true}
            icon={<HiLogout />}
            isOpen={isOpen}
            path="/"
            setSelectedItem={logoutUser}
          />
        </div>
      </nav>
    </main>
  )
}

export default SideBar

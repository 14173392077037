import axiosInstance from "../helpers/axiosInstance"

export const getPartitions = () => {
    const url = '/partitions'

    return axiosInstance.get(url)
}

export const createPartition = (data) => {
    const url = '/partitions'

    return axiosInstance.post(url, data)
}

export const updatePartition = (data, id) => {
    const url = '/partitions/' + id

    return axiosInstance.put(url, data)
}
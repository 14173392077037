import React from 'react'

function Card({ children, bg, styles, min = 'min-h-[280px]' }) {
  return (
    <div
      className={`w-full shadow-md p5 drop-shadow-md ${min} rounded-md p-5`}
      style={{ backgroundColor: bg }}
    >
      {children}
    </div>
  )
}

export default Card

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers, applyMiddleware, compose } from 'redux'
import auth from './reducers/AuthReducer'
import thunk from 'redux-thunk'

const RootReducers = combineReducers({
  auth,
})

const store = configureStore(
  { reducer: RootReducers },
  compose(applyMiddleware(thunk))
)

export default store

import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getEmployees, createEmployee, updateEmployee } from '../../../api/employes'
import { getBusinessRoles } from '../../../api/businessRoles'
import { HiEye, HiPencil } from "react-icons/hi"
import MessagePanel from '../../../component/Card/MessagePanel'
import SearchText from '../../../component/SearchText'
import { refresh } from "../../../helpers/helper"
import { getPartitions } from '../../../api/partition'

function Users() {
  const moment = require('moment')
  const [itShowEdit, setItShowEdit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoading_, setIsLoading_] = useState(false)
  const [isLoading__, setIsLoading__] = useState(false)
  const [labelSearch, setLabelSearch] = useState('')
  const [labelSearch_, setLabelSearch_] = useState('')

  const [employe, setEmploye] = useState({
    role: "",
    email: "",
    fullname: "",
    plainPassword: ""
  })

  const [filter, setFilter] = useState({
    searchText: '',
    status: 1,
    itemsPerPage: 10
  })

  const [employes, setEmployes] = useState([])
  const [employesFiltered, setEmployesFiltered] = useState([])
  const [affectations, setAffectations] = useState([])
  const [affectationsFiltered, setAffectationsFiltered] = useState([])
  const [partitions, setPartitions] = useState([])
  const [partitionsFiltered, setPartitionsFiltered] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState()
  const [messageStatus, setMessageStatus] = useState(0)
  const [currentItems, setCurrentItems] = useState([])
  const [itemsPerPageArray, setItemsPerPageArray] = useState([])
  const [showPartition, setShowPartition] = useState(false)

  const getEnployeList = useCallback(async () => {
    setIsLoading(true)
    try {
      await getEmployees().then(({ data }) => {
        const result = data
        setEmployes(result)
        setEmployes(result)
        setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
        let arrayItemsPerPage = []
        for (let i = 1; i <= result.length; i++) {
          if (i === 0) {
            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
          } else {
            arrayItemsPerPage.push({ value: i, label: i.toString() })
          }
        }
        setItemsPerPageArray(arrayItemsPerPage)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }, [filter.itemsPerPage])

  const editShowItem = (item) => {
    setShowModal(true)

    setEmploye((prev) => ({
      ...prev,
      email: item.email,
      fullname: item.fullname,
      role: item.role,
      id: item.id,
      partition: item.partition === undefined ? '' : item.partition
    }))

    let newArr = affectations.filter((el) => item.role.includes(el.id))
    setLabelSearch(`${newArr[0]?.code} | ${newArr[0]?.label}`)

    if (item.partition !== undefined) {
      let newArr_ = partitions.filter((el) => item.partition?.includes(el.id))
      setLabelSearch_(`${newArr_[0]?.id} | ${newArr_[0]?.name}`)
    }
  }

  const setAffectationName = (item) => {
    let newArr = affectations.filter((el) => item.role.includes(el.id))
    return (`${newArr[0]?.label}`)
  }

  const resetEmploye = () => {
    setEmploye((prev) => ({
      ...prev,
      role: "",
      email: "",
      fullname: "",
      plainPassword: "",
      id: undefined,
      partition: ''
    }))

    setShowPartition(false)
    setLabelSearch('')
    setLabelSearch_('')
  }

  const setCreateEmploye = async () => {
    setShowMessage(false)
    if (
      employe.role !== '' && employe.email !== '' && employe.fullname !== ''
    ) {
      setIsLoading(true)
      if (employe.id !== undefined) {
        try {
          let data = {}
          employe.partition !== '' ?
            data = {
              role: employe.role,
              partition: employe.partition,
              email: employe.email,
              fullname: employe.fullname,
              active: true
            }
            : data = {
              role: employe.role,
              email: employe.email,
              fullname: employe.fullname,
              active: true
            }

          // console.log(data)

          await updateEmployee(data, employe.id)
            .then(() => {
              setShowMessage(true)
              setMessageStatus(1)
              setMessage("L'employé a été modifier avec succès")
              getEnployeList()
              setIsLoading(false)
              setItShowEdit(false)
            })
            .catch((error) => {
              console.log("Try : =>", error)
              setIsLoading(false)
            })

        } catch (error) {
          console.log("Catch : =>", error)
          setIsLoading(false)
        }
      } else {
        try {
          let data = {}
          employe.partition === '' ?
            data = {
              role: employe.role,
              email: employe.email,
              fullname: employe.fullname,
              plainPassword: employe.plainPassword
            } : data = employe

          await createEmployee(data)
            .then(() => {
              getEnployeList()
              toggleModal()
              setIsLoading(false)
            })
            .catch((error) => {
              console.log("Try : =>", error)
              setIsLoading(false)
            })
        } catch (error) {
          console.log("Catch : =>", error)
          setIsLoading(false)
        }
      }
    } else {
      setShowMessage(true)
      setMessageStatus(0)
      setMessage(
        'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
      )
      setIsLoading(false)
    }
  }

  const headers = [
    "Id",
    "User ID",
    "Email",
    "Affectation",
    "Statut",
    "Date de création",
    "Action"
  ]

  const statusItems = [
    {
      label: "Tout",
      value: 0,
      default: true
    },
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Non-active",
      value: 2,
    }
  ]

  const toggleModal = () => {
    setShowModal(!showModal)
    setItShowEdit(false)
    setShowMessage(false)
    setIsLoading(false)
    resetEmploye()
  }

  const getAffectationList = useCallback(async () => {
    setIsLoading(true)
    try {
      await getBusinessRoles().then((response) => {
        const result = response.data
        setAffectations(result)
        setAffectationsFiltered(result)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }, [])

  const getPartitionsList = useCallback(async () => {
    try {
      await getPartitions()
        .then(({ data }) => {
          setPartitions(data)
          setPartitionsFiltered(data)
        })
        .catch((error) => {
          console.log("Try : => ", error)
        })
    } catch (error) {
      console.log("Catch : =>", error)
    }
  }, [])

  const setItemSearch = async (item) => {
    if (item !== undefined) {
      setEmploye((prev) => ({
        ...prev,
        role: `/api/business_roles/${item.id}`
      }))

      item.partitioned === true ? setShowPartition(true) : setShowPartition(false)

      setLabelSearch(`${item.code} | ${item.label}`)
      setIsLoading_(false)
    }
  }

  const setItemSearch_ = async (item) => {
    if (item !== undefined) {
      setEmploye((prev) => ({
        ...prev,
        partition: `/api/partitions/${item.id}`
      }))

      setLabelSearch_(`${item.id} | ${item.name}`)
      setIsLoading__(false)
    }
  }

  useEffect(() => {
    if (employes.length === 0 && isLoading === true) {
      getEnployeList()
    }
  }, [employes, getEnployeList, isLoading])

  useEffect(() => {
    if (affectations.length === 0) {
      getAffectationList()
    }
  }, [affectations, getAffectationList])

  useEffect(() => {
    if (partitions.length === 0) {
      getPartitionsList()
    }
  }, [getPartitionsList, partitions])

  return (
    <div className="flex flex-col w-full h-full px-10 py-10">
      <div className='flex flex-row'>
        <div className='w-full'>
          <div className='flex flex-row justify-between mb-4'>
            <div className='flex-col flex gap-2 w-6/12'>
              <div>
                <SearchText initialArray={employes} setArrayFiltered={setEmployesFiltered} setLoader={setIsLoading} />
              </div>
              <div className='flex-row flex gap-3'>
                <div className='w-6/12'>
                  <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                </div>
                <div className='w-6/12'>
                  <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                </div>

              </div>
            </div>
            <div className='flex flex-row gap-2  justify-center items-end'>
              <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
              <button onClick={() => toggleModal()} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter un employé</button>
            </div>
          </div>
          <ModelOne headers={headers} arrayItems={employesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
            {employesFiltered.length > 0 ?
              currentItems.map((item, index) => (
                <tr key={index} className="border-b-2 border-gray-300">
                  <td className='p-3'>{item.id}</td>
                  <td className='p-3'>{item.userId}</td>
                  <td className='p-3'>{item.email}</td>
                  <td className='p-3'>{setAffectationName(item)}</td>
                  <td className='p-3'>{!item.locked ? 'Active' : "Non-active"}</td>
                  {/* <td className='p-3'>{setCategoryName(item.personType)}</td> */}
                  <td className='p-3'>{moment(item.createdAt).format('ll')}</td>
                  <td className='flex flex-row items-center justify-center p-3 gap-2'>
                    <button className='text-[16px] px-5 py-2 border-2 rounded-md' onClick={() => editShowItem(item)}>
                      <HiEye />
                    </button>
                  </td>
                </tr>
              )) : (
                <tr className="">
                  <td
                    colSpan="10"
                    className="p-5 text-center text-gray-700 text-md"
                  >
                    {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                  </td>
                </tr>
              )}
          </ModelOne>
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className='w-full h-full flex-col flex items-center justify-center'>
          <div className={`bg-white shadow-sm ma min-w-[500px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
            <div className='flex flex-col gap-3'>
              {showMessage && (
                <MessagePanel message={message} messageStatus={messageStatus} />
              )}

              <h3 className='text-2xl mb-2 font-semibold uppercase' >{employe.id !== undefined ? 'Information employé' : 'Ajouter un employé'}</h3>

              <div className='flex flex-col my-4 gap-4'>
                <div className='w-full'>
                  <div className='w-full'>
                    <SearchText
                      arrayFiltered={affectationsFiltered}
                      initialArray={affectations}
                      setArrayFiltered={setAffectationsFiltered}
                      label={'Affectation'}
                      required={true}
                      setLoader={setIsLoading_}
                      defaultValue={labelSearch}
                      setDefaultValue={setLabelSearch}
                      disabled={employe.id !== undefined && !itShowEdit}
                    />
                  </div>
                </div>
                {(isLoading_ !== false) &&
                  <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                    {affectationsFiltered.length > 0 ?
                      affectationsFiltered.map((el, id) => (
                        <button onClick={() => setItemSearch(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                          {`${el.code} | ${el.label}`}
                        </button>
                      ))
                      :
                      <div className='w-full flex justify-center items-center'>
                        Aucun employé trouver
                      </div>}
                  </div>
                }
              </div>

              <div className='flex flex-row gap-4'>
                <div>
                  <Input disabled={!itShowEdit && employe.id !== undefined} required={true} label={'Adresse email'} placeholder="Adresse email" id={'email'} value={employe.email} setValue={setEmploye} />
                </div>

                <div>
                  <Input disabled={!itShowEdit && employe.id !== undefined} required={true} label={'Nom complet'} placeholder="Nom complet" id={'fullname'} value={employe.fullname} setValue={setEmploye} />
                </div>
              </div>


              <div className='flex flex-col my-4 gap-4'>
                <div className='w-full'>
                  <div className='w-full'>
                    <SearchText
                      arrayFiltered={partitionsFiltered}
                      initialArray={partitions}
                      setArrayFiltered={setPartitionsFiltered}
                      label={'Partition'}
                      required={showPartition}
                      setLoader={setIsLoading__}
                      defaultValue={labelSearch_}
                      setDefaultValue={setLabelSearch}
                      disabled={employe.id !== undefined && !itShowEdit}
                    />
                  </div>
                </div>
                {(isLoading__ !== false) &&
                  <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                    {partitionsFiltered.length > 0 ?
                      partitionsFiltered.map((el, id) => (
                        <button onClick={() => setItemSearch_(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                          {`${el.id} | ${el.name}`}
                        </button>
                      ))
                      :
                      <div className='w-full flex justify-center items-center'>
                        Aucune partition trouver
                      </div>}
                  </div>
                }
              </div>

              <div className='flex flex-row gap-4'>
                {(employe.id !== undefined) && (itShowEdit ?
                  <button onClick={setCreateEmploye} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier l\'employé'} </button>
                  :
                  <button onClick={() => setItShowEdit(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>
                    <HiPencil />
                  </button>)
                }

                {employe.id === undefined &&
                  <button onClick={setCreateEmploye} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter un employé'} </button>}
                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
              </div>

            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default Users

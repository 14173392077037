// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createProfil = async (
    name,
    permissions,
    personType,
    // platformId
) => {
    const url = '/profiles'
    return await axiosInstance.post(
        url,
        {
            name,
            permissions,
            personType,
            // platformId
        },
    )
}

export const updateProfil = async (
    name,
    permissions,
    personType,
    // platformId,
    id
) => {
    const url = '/profiles/' + id
    return await axiosInstance.put(
        url,
        {
            name,
            permissions,
            personType,
            // platformId
        },
    )
}


export const getProfils = () => {
    const url = '/profiles'

    return axiosInstance.get(url)
}

export const getPermissions = () => {
    const url = '/permissions'

    return axiosInstance.get(url)
}

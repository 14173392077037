import React from 'react'
import cn from 'classnames'
import { handleFile } from "../../helpers/helper"

const inputStyle = "w-full px-2 py-2 rounded-md bg-gray-50 text-[14px] border-2"

function FileInput({ setValue }) {
    return (
        <div>
            <input
                className={cn(inputStyle)}
                type="file"
                onChange={(e) => handleFile(e, setValue)}
            />
        </div>
    )
}

export default FileInput
import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../../component/Table/ModelOne"
import Input from '../../../../component/Input'
import SearchText from '../../../../component/SearchText'
import { getEntries, getCompte } from '../../../../api/account'
import { getCustomerKycDocuments } from '../../../../api/customer'
import { useParams } from 'react-router-dom'
import { refresh } from '../../../../helpers/helper'
import DateFilter from '../../../../component/DateFilter'
import avatar from '../../../../assets/images/avatar-r.png'

function Entries() {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const moment = require('moment')

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10,
        currency: 'AA'
    })

    const [avatarClient, setAvatarClient] = useState('')

    const [entries, setEntries] = useState([])
    const [entriesFiltered, setEntriesFiltered] = useState([])
    const [account, setAccount] = useState(null)
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])

    const headers = [
        "Date",
        "No.transaction",
        "Opération",
        "Devise",
        "Montant du credit",
        "Montant du debit",
        "Solde"
    ]

    const getEntriesList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getCompte(id).then(async ({ data }) => {
                console.log(data)
                setAccount(data)
                if (data?.holderId !== undefined) {
                    await getCustomerKycDocuments(data?.holderId)
                        .then(({ data }) => {
                            let arr = data.filter((el) => el?.type === 'PSSPH')
                            setAvatarClient(arr[0]?.contentUrl)
                        })
                }
            })

            await getEntries(id).then((res) => {
                const result = res.data
                setEntries(result)
                setEntriesFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 1) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage, id])

    useEffect(() => {
        if (entries.length === 0 && isLoad === false) {
            getEntriesList()
        }
    }, [entries, getEntriesList, isLoad])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='w-full'>
                <div className='flex flex-row mb-5 gap-4'>
                    <div className='py-2 px-3 bg-[#eaebeb] w-6/12 flex flex-col justify-center'>
                        <ul>
                            <li>Nom : {account !== null && account.name}</li>
                            <li>Compte Id : {account !== null && account?.id}</li>
                            <li>Numéro Compte : {account !== null && account?.accountNumber}</li>
                            <li>Balance : {account !== null && account?.balance}</li>
                            <li>Devise : {account !== null && account?.currency}</li>
                            <li>Créer le  : {account !== null && moment(account.createdAt).format('DD-MM-y')}</li>
                        </ul>
                    </div>
                    <div className='py-2 px-3 w-6/12 flex flex-row justify-end'>
                        <div className='py-3 px-3 border-2'>
                            {avatarClient !== undefined ? (
                                <img src={`https://api.kurrency.demo.e-makuta.com${avatarClient}`} alt="customer-avatar" className='h-[130px]' />
                            ) : (
                                <img src={avatar} alt="customer-avatar" className='h-[130px]' />
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row mb-5'>
                    <div className='w-6/12'>
                        <SearchText initialArray={entries} setArrayFiltered={setEntriesFiltered} setLoader={setIsLoading} />
                    </div>
                    <div className='flex flex-row gap-2  justify-end items-end w-6/12'>
                        <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                    </div>
                </div>
                <div className='flex flex-row justify-between mb-4 gap-4'>
                    <div className='flex-col flex gap-2 w-6/12'>
                        <div className='flex-row flex gap-3'>
                            <div className='w-6/12'>
                                <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                            </div>
                        </div>
                    </div>

                    <div className='w-6/12'>
                        <DateFilter elementKey={'date'} setArrayToFilter={setEntriesFiltered} arrayToFilter={entries} />
                    </div>
                </div>
                <ModelOne headers={headers} arrayItems={entriesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                    {entriesFiltered.length > 0 ?
                        currentItems.map((item, index) => (
                            <tr key={index} className="border-b-2 border-gray-300">
                                <td className='p-3'>{moment(item.date).format('DD-MM-Y')}</td>
                                <td className='p-3'>{item.authorizationId}</td>
                                <td className='p-3'>{item.label}</td>
                                <td className='p-3'>{item.currency}</td>
                                <td className='p-3'>{item.type === 'C' && item.amount}</td>
                                <td className='p-3'>{item.type === 'D' && item.amount}</td>
                                <td className='p-3'>{item.balance}</td>
                            </tr>
                        )) : (
                            <tr className="">
                                <td
                                    colSpan="10"
                                    className="p-5 text-center text-gray-700 text-md"
                                >
                                    {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                </td>
                            </tr>
                        )}
                </ModelOne>
            </div>
        </div>
    )
}

export default Entries

import React from 'react'

function MessagePanel({ messageStatus, message, mx = 'md:mx-10' }) {
  return (
    <div
      className={`p-3 mb-4 rounded-md text-gray-100 ${mx} duration-500 ${messageStatus === 0 ? 'bg-red-500' : 'bg-[#78dd8e]'
        }`}
    >
      {message}
    </div>
  )
}

export default MessagePanel

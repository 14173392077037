import React from 'react'

function Home() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-10 px-10">
      <h3>Welcome to the Dashboard.</h3>
    </div>
  )
}

export default Home

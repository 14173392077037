// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createCustomer = async (data) => {
    const url = '/customers'
    return await axiosInstance.post(url, data)
}

export const updateCustomer = async (data, id) => {
    const url = '/customers/' + id
    return await axiosInstance.put(url, data)
}

export const getCustomers = () => {
    const url = '/customers'

    return axiosInstance.get(url)
}

export const getCustomerById = (id) => {
    const url = '/customers/' + id

    return axiosInstance.get(url)
}

export const validateCustomer = async (id) => {
    const url = '/customers/' + id + '/validation'
    return await axiosInstance.put(url, {})
}


export const getCustomerDocuments = (id) => {
    const url = `/attachments?customer=${id}`

    return axiosInstance.get(url)
}

export const createDocument = async (data) => {
    const url = '/attachments'
    return await axiosInstance.post(
        url,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
    )
}


export const getCustomerKycDocuments = (id) => {
    const url = `/kyc_documents?customer=${id}`

    return axiosInstance.get(url)
}

export const createKycDocument = async (data) => {
    const url = '/kyc_documents'
    return await axiosInstance.post(
        url,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
    )
}
export const validateKycDocument = async (id) => {
    const url = '/kyc_documents/' + id + '/verify'
    return await axiosInstance.put(url, {
        "verified": true
    })
}
import React from 'react'
import { Navigate, Outlet } from "react-router-dom"
import CardAuthForm from '../../component/Card/CardAuthForm'

function Auth() {
  const isAuthenticated = localStorage.getItem('token')

  return (
    isAuthenticated !== null && isAuthenticated !== undefined ? <Navigate to='/dashboard' /> :
      <main className="flex flex-col items-center justify-center min-h-screen bg-gray-200 min-w-screen">
        <CardAuthForm>
          <Outlet />
        </CardAuthForm>
      </main>
  )
}

export default Auth

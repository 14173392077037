// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createBusinessRole = async (data) => {
    const url = '/business_roles'
    return await axiosInstance.post(url, data)
}

export const updateBusinessRole = async (data, id) => {
    const url = '/business_roles/' + id
    return await axiosInstance.put(url, data)
}

export const getBusinessRoles = () => {
    const url = '/business_roles'
    return axiosInstance.get(url)
}

export const getBusinessRoleById = (id) => {
    const url = '/business_roles/' + id
    return axiosInstance.get(url)
}
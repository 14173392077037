import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { resetPassword } from '../../../api/auth'
import Card from "../../../component/Card/Card"
import Input from '../../../component/Input'
import MessagePanel from '../../../component/Card/MessagePanel'

function UserAccount() {
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)
    const [passwords, setPasswords] = useState({
        oldPassword: '',
        newPassword: '',
    })


    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const setResetPassword = async () => {
        try {
            if (passwords.newPassword !== '' && passwords.oldPassword !== '') {
                const id = user.id
                setIsLoading(true)

                await resetPassword(passwords.oldPassword, passwords.newPassword, id)
                    .then(() => {
                        setMessage("Mot de passe changer avec succès")
                        setMessageStatus(1)
                        setShowMessage(true)
                        setIsLoading(false)

                    }).catch((err) => {
                        console.log(err)
                        setShowMessage(true)
                        setMessage("L'ancien mot de passe est incorrect")
                        setIsLoading(false)
                    })
            } else {
                setShowMessage(true)
                setMessage(
                    'Veillez renseignez vos  l\' ancien et le nouveau mot de passe '
                )
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            setShowMessage(true)
            setMessage("Une erreur inattendue veillez recommencer plus tard")
        }
    }

    return (
        <div className="flex flex-col items-center  w-full h-full py-20 px-10 bg-[#E5E7EB]">
            <div className='w-[400px]'>
                <Card bg={'#FFFFFF'}>

                    {showMessage && (
                        <MessagePanel message={message} messageStatus={messageStatus} />
                    )}

                    <div className='border-2 p-3 flex flex-col gap-2'>
                        <div className='flex flex-col'>
                            <span className='font-mono text-2xl font-semibold'>
                                {user && user.id}
                            </span>
                            <span className='text-md font-mono'>
                                {`${user?.platform?.name} | ${user?.profile?.name}`}
                            </span>
                        </div>

                        <div className='flex flex-col'>
                            <span className='text-lg font-mono font-medium'>
                                {`${user !== null && user.displayName !== undefined ? user.displayName : ''}`}
                            </span>
                            <span className='text-md font-mono'>
                                {`${user && user.email} ${user !== null && user.phone !== undefined ? '|' + user.phone : ''}`}
                            </span>
                        </div>

                    </div>

                    <div className="flex flex-col gap-4 mt-5">
                        <Input type='password' required={true} value={passwords.oldPassword} setValue={setPasswords} id={'oldPassword'} placeholder={'Ancien mot de passe'} label={'Ancien mot de passe'} />
                        <Input type='password' required={true} value={passwords.newPassword} setValue={setPasswords} id={'newPassword'} placeholder={'Nouveau mot de passe'} label={'Nouveau mot de passe'} />

                        <button className='bg-[#0064FF] p-3 text-white rounded-md' onClick={setResetPassword}>
                            {isLoading ? 'Changement en cours ' : 'Changer mon mot de passe'}
                        </button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default UserAccount
import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Input from '../../../../component/Input'
import { getContracts } from '../../../../api/contract'
import imageExtensions from 'image-extensions'
import {
    createCustomer,
    getCustomerById,
    validateCustomer,
    createDocument,
    createKycDocument,
    getCustomerDocuments,
    getCustomerKycDocuments,
    validateKycDocument,
    updateCustomer
} from '../../../../api/customer'
// import Modal from "../../../../component/Modal"
import Modal from '@mui/material/Modal';
import ModelTwo from '../../../../component/Table/ModelTwo'
import { HiTrash, HiEye, HiCheckCircle, HiOutlineX, HiPencil } from 'react-icons/hi'
import MessagePanel from '../../../../component/Card/MessagePanel'
import ModalKYC from '../../../../component/ModalKYC'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { setName, removeItem } from '../../../../helpers/helper'
import { createCompte } from '../../../../api/account'
import { getProducts } from '../../../../api/products'
import { setChildVisible } from '../../../../helpers/roleManager'

const inputStyle = "w-full px-2 py-2 rounded-md bg-gray-50 text-[14px] border-2"

const AddClient = () => {
    const { id } = useParams()
    const moment = require('moment')

    const userState = useSelector((state) => state.auth.user)
    const user = userState !== null && ((typeof userState) === 'object' ? userState : JSON.parse(userState))

    const [isLoad, setIsLoad] = useState(false)
    const [isLoad_, setIsLoad_] = useState(false)
    const [contracts, setContracts] = useState([])
    const [showModalAdresse, setShowModalAdresse] = useState(false)
    const [showModalContact, setShowModalContact] = useState(false)
    const [showModalKyc, setShowModalKyc] = useState(false)
    const [showModalOtherDoc, setShowModalOtherDoc] = useState(false)
    const [showModalUfDoc, setShowModalUfDoc] = useState(false)
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_, setIsLoading_] = useState(false)
    const [isLoading__, setIsLoading__] = useState(false)
    const [products, setProducts] = useState([])
    const [docUrl, setDocUrl] = useState(false)
    const [withShow, setWithShow] = useState(true)
    const [doc, setDoc] = useState({
        type: 'kyc'
    })
    const [client, setClient] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        country: "CD",
        gender: "M",
        birthday: "",
        contract: "",
        platformId: "",
        maritalStatus: "S",
        addresses: [],
        contacts: [],
        legalPerson: "I"
    })

    const [compte, setCompte] = useState({
        name: '',
        profile: '',
        currency: 'USD',
        customerId: ''
    })

    const legalPersons = [
        { label: "Individue", value: "I" },
        { label: "Morale", value: "C" }
    ]

    const [kycDocuments, setKycDocuments] = useState([])
    const [kycDocument, setKycDocument] = useState({
        type: 'ID',
        documentRefNumber: '',
    })

    const [otherDocuments, setOtherDocuments] = useState([])
    const [otherDocument, setOtherDocument] = useState({
        title: '',
    })

    const [selectedFile, setSelectedFile] = useState(false);

    const [adresse, setAdresse] = useState({
        address: '',
        city: '',
        state: "",
        country: "CD"
    })

    const [contact, setContact] = useState(
        {
            type: "EMAIL",
            contact: ""
        }
    )

    const sexe = [
        { value: 'M', label: "Homme" },
        { value: 'F', label: "Femme" }
    ]

    const contactType = [
        { value: 'EMAIL', label: "Adresse email" },
        { value: 'PHONE', label: "Téléphone" }
    ]

    const maritalStatus = [
        { value: "M", label: "Marié" },
        { value: "D", label: "divorcé" },
        { value: "S", label: "célibataire" },
        { value: "W", label: "veuf/veuve" }
    ]

    const documentType = [
        { value: 'PASS', label: 'Passport', default: true },
        { value: 'ID', label: 'Pièce d\'identité' },
        { value: 'DRVLC', label: 'Permis de conduire' },
        { value: 'PSSPH', label: 'Photo passeport' },
        { value: 'OTHER', label: 'Autres' },
    ]

    const docs = [
        { value: 'others', label: "Autres documents", default: true },
        { value: 'kyc', label: "KYC documents" }
    ]

    const currency = [
        { label: 'US Dollars', value: 'USD', default: true },
        { label: 'FC Congolais', value: 'CDF' },
    ]

    const { countryCode } = require('../../../../helpers/countryCode')

    const getDocumentList = useCallback(async () => {
        try {
            await getCustomerDocuments(id)
                .then(({ data }) => {
                    setOtherDocuments(data)
                })

            await getCustomerKycDocuments(id)
                .then(({ data }) => {
                    setKycDocuments(data)
                })
        } catch (error) {
            console.log(error)
        }
    }, [id])

    const getContractsList = useCallback(async () => {
        try {
            await getContracts().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((element, i) => {
                    i === 0 ? newArr.push({ value: element.id, label: `${element.label} (${element.code})`, default: true }) :
                        newArr.push({ value: element.id, label: `${element.label} (${element.code})` })
                });

                setClient((prev) => ({
                    ...prev,
                    contract: newArr[0]?.value
                }))

                setContracts(newArr)
                setIsLoad_(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
        }
    }, [])

    const toggleModalAdresse = useCallback(() => {
        setShowModalAdresse(!showModalAdresse)
        setShowMessage(false)
        resetAdresse()
    }, [showModalAdresse])

    const toggleModalContact = useCallback(() => {
        setShowModalContact(!showModalContact)
        setShowMessage(false)
        resetContact()
    }, [showModalContact])

    const toggleModalKyc = useCallback(() => {
        setShowModalKyc(!showModalKyc)
        setShowMessage(false)
        resetDocument()
    }, [showModalKyc])

    const toggleModalOtherDoc = useCallback(() => {
        setShowModalOtherDoc(!showModalOtherDoc)
        setShowMessage(false)
    }, [showModalOtherDoc])

    const resetDocument = () => {
        setKycDocument({
            type: 'ID',
            documentRefNumber: '',
        })
        setOtherDocument({
            title: ''
        })
    }

    const resetAdresse = () => {
        setAdresse({
            address: '',
            city: '',
            state: "",
            country: "CD"
        })
    }

    const addAdresse = () => {
        if (adresse.id !== undefined) {
            let newArr = client.addresses
            newArr[adresse.id] = adresse
            setClient((prev) => ({
                ...prev,
                adresse: newArr
            }))
            resetAdresse()
            toggleModalAdresse()
        } else {
            let newArr = client.addresses
            newArr.push(adresse)
            setClient((prev) => ({
                ...prev,
                adresse: newArr
            }))
            resetAdresse()
            toggleModalAdresse()
        }
    }

    const showAdresseItem = (item, index) => {
        setShowModalAdresse(true)
        setAdresse((prev) => ({
            ...prev,
            country: item.country,
            city: item.city,
            state: item.state,
            address: item.address,
            id: index,
        }))
    }

    const resetContact = () => {
        setContact({
            type: "EMAIL",
            contact: ""
        })
    }

    const addContact = () => {
        if (contact.id !== undefined) {
            let newArr = client.contacts
            newArr[contact.id] = contact
            setClient((prev) => ({
                ...prev,
                contacts: newArr
            }))
            resetAdresse()
            toggleModalContact()
        } else {
            let newArr = client.contacts
            newArr.push(contact)
            setClient((prev) => ({
                ...prev,
                contacts: newArr
            }))
            resetContact()
            toggleModalContact()
        }
    }

    const showContactItem = (item, index) => {
        setShowModalContact(true)
        setContact((prev) => ({
            ...prev,
            type: item.type,
            contact: item.contact,
            id: index,
        }))
    }

    const resetClient = () => {
        if (id === undefined) {
            setClient({
                firstName: "",
                middleName: "",
                lastName: "",
                country: "CD",
                gender: "M",
                birthday: "",
                contract: "",
                maritalStatus: "S",
                addresses: [],
                contacts: [],
            })
        } else {
            setItShowEdit(false)
        }

        setShowMessage(false)
    }

    const setCreateCustomer = async () => {
        if (
            client.addresses.length > 0 &&
            client.contacts.length > 0 &&
            client.firstName !== '' &&
            client.lastName !== '' &&
            client.gender !== '' &&
            client.maritalStatus !== '' &&
            client.birthday !== ''
        ) {
            if (id !== undefined) {
                setIsLoading(true)
                try {
                    let data = {}

                    client.contract === '' || client.contract === undefined || client.contract === null ? data = {
                        firstName: client.firstName,
                        middleName: client.middleName,
                        lastName: client.lastName,
                        country: client.country,
                        gender: client.gender,
                        birthday: client.birthday,
                        maritalStatus: client.maritalStatus,
                        addresses: client.addresses,
                        contacts: client.contacts,
                        legalPerson: client.legalPerson
                    } : data = {
                        firstName: client.firstName,
                        middleName: client.middleName,
                        lastName: client.lastName,
                        country: client.country,
                        gender: client.gender,
                        birthday: client.birthday,
                        maritalStatus: client.maritalStatus,
                        addresses: client.addresses,
                        contacts: client.contacts,
                        contract: 'api/contracts/' + client.contract,
                        legalPerson: client.legalPerson
                    }

                    await updateCustomer(data, id)
                        .then(() => {
                            setIsLoading(false)
                            resetClient()
                            setMessage('le client a été modifier avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    const message = error.response.data.detail
                    setMessage(message)
                    setMessageStatus(0)
                    setShowMessage(true)
                    setIsLoading(false)
                }
            } else {
                setIsLoading(true)
                try {
                    let data = {}

                    client.contract === '' || client.contract === undefined || client.contract === null ? data = {
                        firstName: client.firstName,
                        middleName: client.middleName,
                        lastName: client.lastName,
                        country: client.country,
                        gender: client.gender,
                        birthday: client.birthday,
                        maritalStatus: client.maritalStatus,
                        addresses: client.addresses,
                        contacts: client.contacts,
                        legalPerson: client.legalPerson
                    } : data = {
                        firstName: client.firstName,
                        middleName: client.middleName,
                        lastName: client.lastName,
                        country: client.country,
                        gender: client.gender,
                        birthday: client.birthday,
                        maritalStatus: client.maritalStatus,
                        addresses: client.addresses,
                        contacts: client.contacts,
                        contract: 'api/contracts/' + client.contract,
                        legalPerson: client.legalPerson
                    }

                    await createCustomer(data).then(() => {
                        setIsLoading(false)
                        resetClient()
                        setMessage('le client a été ajouter avec succès')
                        setMessageStatus(1)
                        setShowMessage(true)
                    })
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
            }
        }
        else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }


    const getClientInfos = useCallback(async () => {
        try {
            await getCustomerById(id)
                .then((res) => {
                    let result = res.data
                    setClient(result)
                    setIsLoad(false)
                })
        } catch (error) {
            console.log(error)
            setIsLoad(false)
        }
    }, [id])

    const toggleCustomerStatus = async () => {
        try {
            await validateCustomer(client.id)
                .then(() => {
                    setMessage('le status a été modifier avec succès')
                    setMessageStatus(1)
                    setShowMessage(true)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const toggleKyCStatus = async (id) => {
        try {
            await validateKycDocument(id)
                .then(() => {
                    setMessage('le status a été modifier avec succès')
                    setMessageStatus(1)
                    setShowMessage(true)
                    getDocumentList()
                })
        } catch (error) {
            console.log(error)
        }
    }

    const beforeUpload = (file) => {
        let valid = true

        const allowedFileType = ['image/jpeg', 'image/png', 'application/pdf', 'application/docx', 'application/doc']
        const maxFileSize = 5000000

        if (!allowedFileType.includes(file.type)) {
            valid = 'Veuillez entrer un document valide'
        }

        if (file.size >= maxFileSize) {
            valid = 'le fichier ne peut pas avoir une taille de plus de 5mb!'
        }


        return valid
    }

    const handleFile = (event) => {
        setSelectedFile(event.target.files[0] !== null && event.target.files[0] !== undefined && event.target.files[0])
    }

    const setCreateDocument = async () => {
        if (doc.type !== 'kyc') {
            if (otherDocument.title !== '' && otherDocument.file !== '') {
                let validFile = beforeUpload(selectedFile)
                if (validFile) {
                    try {
                        let data = new FormData()
                        data.append('title', otherDocument.title)
                        data.append('customer', `/api/customers/${id}`)
                        data.append('file', selectedFile)
                        setIsLoading__(true)
                        await createDocument(data).then(() => {
                            getDocumentList()
                            setIsLoading__(false)
                            toggleModalKyc()
                        })
                    } catch (error) {
                        setIsLoading(false)
                    }
                } else {
                    setMessage(validFile)
                    setMessageStatus(0)
                    setShowMessage(true)
                }

            } else {
                setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
                setMessageStatus(0)
                setShowMessage(true)
            }
        } else {
            if (kycDocument.type !== '' && kycDocument.documentRefNumber !== '' && kycDocument.file !== '') {
                let validFile = beforeUpload(selectedFile)
                if (validFile) {
                    try {
                        let data = new FormData()
                        data.append('type', kycDocument.type)
                        data.append('documentRefNumber', kycDocument.documentRefNumber)
                        data.append('customer', `/api/customers/${id}`)
                        data.append('file', selectedFile)
                        setIsLoading_(true)
                        await createKycDocument(data).then((res) => {
                            getDocumentList()
                            setIsLoading_()
                            toggleModalKyc()
                        })
                    } catch (error) {
                        setIsLoading_(false)
                    }
                } else {
                    setMessage(validFile)
                    setMessageStatus(0)
                    setShowMessage(true)
                }

            } else {
                setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
                setMessageStatus(0)
                setShowMessage(true)
            }
        }
    }

    const removeContactItem = (index) => {
        let newArr = removeItem(client.contacts, index)
        setClient((prev) => ({
            ...prev,
            contacts: newArr
        }))
    }

    const removeAddressItem = (index) => {
        let newArr = removeItem(client.addresses, index)
        setClient((prev) => ({
            ...prev,
            addresses: newArr
        }))
    }

    const removeKycItem = (index) => {
        let newArr = removeItem(kycDocuments, index)
        setKycDocuments(newArr)
    }

    const removeOtherItem = (index) => {
        let newArr = removeItem(otherDocuments, index)
        setOtherDocuments(newArr)
    }

    const getProductsList = useCallback(async () => {
        try {
            await getProducts().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((el, i) => {
                    if (i === 0) {
                        newArr.push({ value: el.id, label: `${el.label} (${el.code})`, default: true })
                    } else {
                        newArr.push({ value: el.id, label: `${el.label} (${el.code})` })
                    }
                })
                setProducts(newArr)
                setCompte((prev) => ({
                    ...prev,
                    profile: newArr[0].value
                }))
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [])

    const resetCompte = useCallback(() => {
        setCompte((prev) => ({
            ...prev,
            name: '',
            profile: products[0].value,
            currency: 'USD',
            customerId: '',
            id: undefined
        }))
    }, [products])

    const setCreateCompte = useCallback(async () => {
        if (compte.name !== '' && compte.profile !== '') {
            setIsLoading__(true)
            try {
                await createCompte(compte.name, compte.profile, compte.currency, id)
                    .then(() => {
                        setIsLoading__(false)
                        resetCompte()
                        setShowMessage(true)
                        setMessageStatus(1)
                        setMessage('Le compte a été créer avec success')
                    })

            } catch (error) {
                const errorMessage = error.response.data.detail
                setShowMessage(true)
                setMessageStatus(0)
                setMessage(errorMessage)
                setIsLoading(false)
            }

        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }, [compte, resetCompte, id])

    const setUfDocProps = (url) => {
        const ext = url.substring(url.length - 5)
        let result = imageExtensions.filter((el) => ext.includes(el))
        // console.log(ext)

        setShowModalUfDoc(!showModalUfDoc)
        if (result.length > 0) {
            setDocUrl(url)
            setWithShow(true)
        } else {
            setDocUrl(url)
            ext.includes('pdf') ? setWithShow(true) : setWithShow(false)
        }
    }

    useEffect(() => {
        if (products.length === 0 && isLoad === false) {
            getProductsList()
        }
    }, [products, getProductsList, isLoad])

    useEffect(() => {
        if (contracts.length === 0 && isLoad_ === false) {
            getContractsList()
        }
    }, [contracts, getContractsList, isLoad_])

    useEffect(() => {
        if (id !== undefined && id !== null && isLoad === false) {
            getClientInfos()
        }
    }, [getClientInfos, isLoad, id])

    return (
        <div className='flex flex-col h-full w-full items-center py-10'>
            <div className='border-2 px-10 py-10 w-10/12'>
                <div className='flex flex-row gap-3 items-center'>
                    {/* <button className='text-2xl' onClick={() => goTo('client-manager')}>
                        <HiArrowCircleLeft />
                    </button> */}
                    <div className='flex mt-3 flex-col'>
                        <h3 className='text-2xl mb-4 font-semibold uppercase'>
                            {id !== undefined ? "Modifier l'utilisateur" : "Ajouter un client"}
                        </h3>
                    </div>
                </div>

                {showMessage && (
                    <MessagePanel message={message} messageStatus={messageStatus} />
                )}

                <div className='flex flex-row mt-4 gap-4 w-full justify-between'>
                    <Input disabled={!itShowEdit && id !== undefined} label={'Nom'} placeholder={'Nom'} required={true} value={client.lastName} setValue={setClient} id={'lastName'} />
                    <Input disabled={!itShowEdit && id !== undefined} label={'Post-nom'} placeholder={'Post-nom'} value={client.middleName === undefined ? client.postName : client.middleName} setValue={setClient} id={'middleName'} />
                    <Input disabled={!itShowEdit && id !== undefined} label={'Prénom'} placeholder={'Prénom'} required={true} value={client.firstName} setValue={setClient} id={'firstName'} />
                </div>

                <div className='flex flex-row mt-4 gap-4 w-full justify-between'>
                    <Input disabled={!itShowEdit && id !== undefined} label={'Nationalité'} placeholder={'Nationalité'} required={true} value={client.country} setValue={setClient} id={'country'} select={true} selectItems={countryCode} />
                    <Input disabled={!itShowEdit && id !== undefined} label={'Sexe'} placeholder={'Sexe'} required={true} value={client.gender} setValue={setClient} id={'gender'} select={true} selectItems={sexe} />
                    <Input disabled={!itShowEdit && id !== undefined} label={'Date de naissance'} placeholder={'Date de naissance'} required={true} value={moment(client.birthday).format('Y-MM-DD')} setValue={setClient} id={'birthday'} type={'date'} />
                </div>

                <div className='flex flex-row mt-4 gap-4 w-full justify-between'>
                    <Input disabled={!itShowEdit && id !== undefined} label={'État civil'} placeholder={'État civil'} required={true} value={client.maritalStatus} setValue={setClient} id={'maritalStatus'} select={true} selectItems={maritalStatus} />
                    <Input disabled={!itShowEdit && id !== undefined} required={true} label={'Personnalité juridique'} select={true} selectItems={legalPersons} id={'legalPerson'} value={client.legalPerson} setValue={setClient} />
                    <Input disabled={!itShowEdit && id !== undefined} label={'Contrat'} placeholder={'Contrat'} value={client.contract} setValue={setClient} id={'contract'} select={true} selectItems={contracts} />
                </div>

                <div className='mt-4 w-full'>
                    <button disabled={!itShowEdit && id !== undefined} onClick={toggleModalAdresse} className='px-5 py-2 border-2 rounded-md text-[14px] w-full'>{'Ajouter une adresse (*)'}</button>
                </div>
                <ModelTwo headers={['Pays', 'Province / Ville', 'Ville / Commune', 'Avenue', 'Action']} styles={'mt-4'}>
                    {client.addresses.map((item, index) => (
                        <tr key={index} className="border-b-2 border-gray-300">
                            <td className='p-3'>{item.country}</td>
                            <td className='p-3'>{item.state}</td>
                            <td className='p-3'>{item.city}</td>
                            <td className='p-3'>{item.address}</td>
                            <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                <button onClick={() => showAdresseItem(item, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                    <HiEye />
                                </button>
                                {itShowEdit &&
                                    <button className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                        <HiTrash onClick={() => removeAddressItem(index)} />
                                    </button>
                                }
                            </td>
                        </tr>
                    ))
                    }
                </ModelTwo>

                <div className='mt-4 w-full'>
                    <button disabled={!itShowEdit && id !== undefined} onClick={toggleModalContact} className='px-5 py-2 border-2 rounded-md text-[14px] w-full'>{contact.id !== undefined ? 'Modifier le contact' : 'Ajouter un contact (*)'}</button>
                </div>
                <ModelTwo headers={['Type', "Valeur", "Action"]} styles={'mt-4'}>
                    {client.contacts.map((item, index) => (
                        <tr key={index} className="border-b-2 border-gray-300">
                            <td className='p-3'>{item.type}</td>
                            <td className='p-3'>{item.contact}</td>
                            <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                <button onClick={() => showContactItem(item, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                    <HiEye />
                                </button>
                                {itShowEdit && <button disabled={!itShowEdit && id !== undefined} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                    <HiTrash onClick={() => removeContactItem(index)} />
                                </button>
                                }
                            </td>
                        </tr>
                    ))
                    }
                </ModelTwo>

                {
                    id !== undefined && (
                        <>

                            <div>
                                <div>
                                    <h3 className='font-semibold mt-4'>
                                        Liste de KYC documents
                                    </h3>
                                </div>
                                <ModelTwo headers={['id', "Type du document", "Numéro de reference", 'status', itShowEdit && 'Action']} styles={'mt-4'}>
                                    {kycDocuments.map((item, index) => (
                                        <tr key={index} className="border-b-2 border-gray-300">
                                            <td className='p-3'>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {item.id}
                                                </button>
                                            </td>
                                            <td className='p-3'>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {setName(documentType, item.type)}
                                                </button>
                                            </td>
                                            <td className='p-3'>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {item.documentRefNumber}
                                                </button>
                                            </td>
                                            <td className={`p-3`}>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {item.status === "V" ? "Validé" : "En attente"}
                                                </button>
                                            </td>
                                            <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                                {item.status === "P" && (
                                                    <>
                                                        <button disabled={!itShowEdit && id !== undefined} onClick={() => toggleKyCStatus(item.id)} className={`${setChildVisible(user?.roles, ['ROLE_KYCDOC_VERIFY']) === true ? 'text-[16px] px-5 py-2 border-2 rounded-md' : 'hidden'}`}>
                                                            <HiOutlineX />
                                                        </button>

                                                        <button disabled={!itShowEdit && id !== undefined} onClick={() => toggleKyCStatus(item.id)} className={`${setChildVisible(user?.roles, ['ROLE_KYCDOC_VERIFY']) === true ? 'text-[16px] px-5 py-2 border-2 rounded-md' : 'hidden'}`}>
                                                            <HiCheckCircle />
                                                        </button>
                                                    </>
                                                )}
                                                {itShowEdit && <button onClick={() => removeKycItem(index)} disabled={!itShowEdit && id !== undefined} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                                    <HiTrash />
                                                </button>}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ModelTwo>
                            </div>

                            <div>
                                <div>
                                    <h3 className='font-semibold mt-4'>
                                        Liste d'autres documents
                                    </h3>
                                </div>
                                <ModelTwo headers={['id', "Titre du document", itShowEdit && 'Action']} styles={'mt-4'}>
                                    {otherDocuments.map((item, index) => (
                                        <tr key={index} className="border-b-2 border-gray-300">
                                            <td className='p-3'>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {item.id}
                                                </button>
                                            </td>
                                            <td className='p-3'>
                                                <button onClick={() => setUfDocProps(`https://api.kurrency.demo.e-makuta.com${item.contentUrl}`)}>
                                                    {item.title}
                                                </button>
                                            </td>
                                            {itShowEdit && <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                                <button onClick={() => removeOtherItem(index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                                    <HiTrash />
                                                </button>
                                            </td>}
                                        </tr>
                                    ))
                                    }
                                </ModelTwo>
                            </div>
                        </>)
                }

                <div className='flex flex-row gap-2 mt-5 items-end'>
                    {
                        (id !== undefined) && (
                            itShowEdit ?
                                <button disabled={isLoading} onClick={setCreateCustomer} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#0064ff]'>
                                    {isLoading === true ? 'Modification en cours...' : 'Modifier le client'}
                                </button>
                                :
                                <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_CUSTOMER_UPDATE']) === true ? 'px-5 py-3 text-white rounded-md text-[14px] bg-[#0064ff]' : 'hidden'}`}>
                                    <HiPencil />
                                </button>
                        )
                    }

                    {id === undefined && !itShowEdit && <button disabled={isLoading} onClick={setCreateCustomer} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#0064ff]'>
                        {isLoading === true ? 'Ajout en cours...' : 'Ajouter un client'}
                    </button>}

                    {id !== undefined && itShowEdit && (
                        <button onClick={toggleModalKyc} className={`${setChildVisible(user?.roles, ['ROLE_KYCDOC_CREATE']) === true ? 'py-2 px-10 rounded-md text-white bg-[#37c37b]' : 'hidden'}`}>{"Ajouter un document"}</button>
                    )
                    }


                    {id !== undefined && itShowEdit && (
                        <button disabled={client.status === "V"} onClick={toggleCustomerStatus} className={`${setChildVisible(user?.roles, ['ROLE_CUSTOMER_VALIDATE']) === true ? 'py-2 px-10 rounded-md text-white bg-[#c5c206]' : 'hidden'}`}>{client.status !== 'P' ? 'Client déjà validé' : "Valider le client"}</button>
                    )
                    }

                    <button onClick={() => resetClient()} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#E94228]'>Annuler</button>
                    {(id !== undefined && kycDocuments.length > 0) && <button onClick={toggleModalOtherDoc} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_CREATE']) === true ? 'px-5 py-2 text-white rounded-md text-[14px] bg-[#000064]' : 'hidden'}`}>Ajouter un compte</button>}
                </div>
            </div>

            <Modal
                open={showModalAdresse}
                onClose={toggleModalAdresse}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{itShowEdit ? 'Information de l\'adresse' : 'Ajouter une adresse'}</h3>


                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <Input disabled={!itShowEdit && id !== undefined} label={'Pays'} placeholder={'Pays'} required={true} value={adresse.country} setValue={setAdresse} id={'country'} select={true} selectItems={countryCode} />
                            <Input disabled={!itShowEdit && id !== undefined} label={"Province / Ville"} placeholder={"Province / Ville"} required={true} value={adresse.state} setValue={setAdresse} id={'state'} />
                            <Input disabled={!itShowEdit && id !== undefined} label={"Ville / Commune"} placeholder={"Ville / Commune"} required={true} value={adresse.city} setValue={setAdresse} id={'city'} />
                            <Input disabled={!itShowEdit && id !== undefined} label={"Adresse"} placeholder={"Adresse"} required={true} value={adresse.address} setValue={setAdresse} id={'address'} />

                            <div className='flex flex-row gap-4'>
                                {itShowEdit && <button onClick={addAdresse} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' >
                                    {adresse.id !== undefined ? 'Modifier l\'adresse ' : 'Ajouter une adresse'}
                                </button>
                                }

                                {id === undefined && <button onClick={addAdresse} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' >
                                    {'Ajouter une adresse'}
                                </button>}
                                <button onClick={() => toggleModalAdresse()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>

            <Modal
                open={showModalContact}
                onClose={toggleModalContact}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{itShowEdit ? 'Information du contact' : 'Ajouter un contact'}</h3>


                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <Input disabled={!itShowEdit && id !== undefined} label={"Type"} select={true} selectItems={contactType} required={true} value={contact.type} setValue={setContact} id={'type'} />
                            <Input disabled={!itShowEdit && id !== undefined} label={"Contact"} placeholder={"Contact"} required={true} value={contact.contact} setValue={setContact} id={'contact'} />

                            <div className='flex flex-row gap-4'>
                                {itShowEdit && <button disabled={isLoading} onClick={addContact} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                                    {contact.id !== undefined ? 'Modifier le contact' : 'Ajouter un contact'}
                                </button>
                                }

                                {id === undefined && <button disabled={isLoading} onClick={addContact} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                                    {'Ajouter un contact'}
                                </button>}
                                <button onClick={() => toggleModalContact()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                open={showModalKyc}
                onClose={toggleModalKyc}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{'Ajouter un document'}</h3>


                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <Input label={"Quel document ? "} select={true} selectItems={docs} required={true} value={doc.type} setValue={setDoc} id={'type'} />
                            {doc.type === 'kyc' ? (
                                <>
                                    <Input disabled={!itShowEdit && id !== undefined} label={'Type du document'} select={true} required={true} selectItems={documentType} setValue={setKycDocument} value={kycDocument.type} id={'type'} />
                                    <Input disabled={!itShowEdit && id !== undefined} label={'Numéro de reference'} placeholder={'Numéro de reference'} required={true} setValue={setKycDocument} value={kycDocument.documentRefNumber} id={'documentRefNumber'} />
                                    <input
                                        className={cn(inputStyle)}
                                        type="file"
                                        onChange={handleFile}
                                    />

                                    <div className='flex flex-row gap-4'>
                                        <button onClick={setCreateDocument} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{isLoading_ ? 'Ajout en cours ...' : 'Ajouter un document'} </button>
                                        <button onClick={() => toggleModalKyc()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                                    </div>
                                </>
                            ) :
                                (
                                    <>
                                        <Input label={'Titre du document'} placeholder={'Titre du document'} required={true} setValue={setOtherDocument} value={otherDocument.title} id={'title'} />
                                        <input
                                            className={cn(inputStyle)}
                                            type="file"
                                            onChange={handleFile}
                                        />

                                        <div className='flex flex-row gap-4'>
                                            <button disabled={isLoading__} onClick={setCreateDocument} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{isLoading__ ? 'Ajout en cours ...' : 'Ajouter un document'} </button>
                                            <button onClick={() => toggleModalKyc()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                                        </div>
                                    </>
                                )}
                        </div>

                    </div>
                </div>

            </Modal>

            <Modal
                open={showModalOtherDoc}
                onClose={toggleModalOtherDoc}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{compte.id !== undefined ? 'Information Compte' : 'Ajouter un Compte'}</h3>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} label={'Nom du Compte'} placeholder="Nom du Compte" id={'name'} value={compte.name} setValue={setCompte} />
                            </div>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} select={true} selectItems={products} label={'Produit'} id={'profile'} value={compte.profile} setValue={setCompte} />
                            </div>
                            <div>
                                <Input disabled={compte.id !== undefined && !itShowEdit} required={true} select={true} selectItems={currency} label={'Devise'} id={'currency'} value={compte.currency} setValue={setCompte} />
                            </div>

                            <div className='flex flex-row gap-4 mt-2'>

                                <button onClick={() => setCreateCompte()} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_CREATE']) === true ? 'py-2 px-10 rounded-md bg-[#0064FF] text-white' : 'hidden'}`} disabled={isLoading__}>
                                    {isLoading__ ? 'Ajout en cours...' : 'Ajouter un compte'}
                                </button>

                                <button onClick={() => toggleModalOtherDoc()} className='py-2 px-10 rounded-md text-white bg-[#000064]'>Fermer</button>
                            </div>

                        </div>

                    </div>
                </div>


            </Modal>

            <ModalKYC open={showModalUfDoc} onClose={setShowModalUfDoc} url={docUrl} withShow={withShow} />
        </div>
    )
}

export default AddClient
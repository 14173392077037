import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { getProducts } from '../../../api/products'
import { goTo, refresh } from '../../../helpers/helper'
import { getAccountTypes } from '../../../api/accountTypes'
import { setName } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'
import { useSelector } from 'react-redux'

function Products() {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [types, setTypes] = useState([])

    const [products, setProducts] = useState([])
    const [productsFiltered, setProductsFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const headers = [
        "Id",
        "Code",
        "Type de compte",
        "Designation",
        "Devise",
        setChildVisible(user?.roles, ['ROLE_ACCOUNT_PROFILE_DETAILS']) === true && "Action"
    ]

    const getProductsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getProducts().then(({ data }) => {
                const result = data
                // console.log(result)
                setProducts(result)
                setProductsFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage])

    const getAccountTypesList = useCallback(async () => {
        try {
            await getAccountTypes().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((el) => {
                    newArr.push({ value: el.id, label: `${el.label} (${el.code})` })
                })
                setTypes(newArr)
            })

        } catch (error) {
            const errorMessage = error.response.data.detail
            console.log(errorMessage)
        }
    }, [])

    useEffect(() => {
        if (types.length === 0) {
            getAccountTypesList()
        }
    })

    useEffect(() => {
        if (products.length === 0 && isLoad === false) {
            getProductsList()
        }
    }, [products, getProductsList, isLoad])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-7/12'>

                            <div className='flex-row flex gap-3'>
                                {/* <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div> */}
                                <div className='w-8/12'>
                                    <SearchText initialArray={products} setArrayFiltered={setProductsFiltered} setLoader={setIsLoading} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => goTo('add-product-manager')} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_PROFILE_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un produit</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={productsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {productsFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='p-3'>{setName(types, item.type)}</td>
                                    <td className='p-3'>{item.label}</td>
                                    <td className='p-3'>{item.currency}</td>
                                    <td className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_PROFILE_DETAILS']) === true ? 'flex flex-row items-center justify-center p-3 gap-2' : 'hidden'}`}>
                                        <button onClick={() => goTo('add-product-manager/' + item.id)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>
        </div>
    )
}

export default Products

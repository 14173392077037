// Navigate.js

import React from 'react'

const Paginate = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  previousPage,
  nextPage,
}) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <div className="flex flex-row mt-3">
      <ul className="flex flex-row gap-4">
        <li
          onClick={previousPage}
          className="px-4 py-2 rounded-md shadow-sm cursor-pointer drop-shadow-sm"
        >
          Précédent
        </li>
        {pageNumbers.map((number, index) => (
          <li
            key={index}
            onClick={() => paginate(number)}
            className={`px-4 py-2 rounded-md shadow-sm cursor-pointer drop-shadow-sm  ${number === currentPage
              ? 'bg-[#0064ff] text-gray-50'
              : 'bg-gray-50'
              }`}
          >
            {number}
          </li>
        ))}
        <li
          onClick={nextPage}
          className="px-4 py-2 rounded-md shadow-sm cursor-pointer drop-shadow-sm"
        >
          Suivant
        </li>
      </ul>
    </div>
  )
}

export default Paginate

import axios from "axios";

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};
const baseUrl = 'https://api.kurrency.demo.e-makuta.com/api';

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers,
});

// const refreshTokenFn = async () => {
//     const refreshToken = localStorage.getItem("refreshToken");
//     return axiosInstance.post("/token/refresh", { refresh_token: refreshToken })
// };

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token");

        if (token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${token}`,
            };
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// axiosInstance.interceptors.response.use(
//     (res) => {
//         return res;
//     },

//     async (err) => {
//         const originalConfig = err.config;

//         if (err.response) {
//             // Access Token was expired
//             if (err.response.status === 401 && !originalConfig._retry) {
//                 originalConfig._retry = true;

//                 try {
//                     const rs = await refreshTokenFn();
//                     console.log(rs)
//                 } catch (_error) {
//                     if (_error.response && _error.response.data) {
//                         return Promise.reject(_error.response.data);
//                     }

//                     return Promise.reject(_error);
//                 }
//             }

//             if (err.response.status === 403 && err.response.data) {
//                 return Promise.reject(err.response.data);
//             }
//         }

//         return Promise.reject(err);
//     }
// );

export default axiosInstance;
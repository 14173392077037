import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getBusinessRoles, createBusinessRole, updateBusinessRole } from '../../../api/businessRoles'
import { getProfils } from '../../../api/profil'
import { HiEye, HiPencil } from "react-icons/hi"
import MessagePanel from '../../../component/Card/MessagePanel'
import SearchText from '../../../component/SearchText'
import { refresh } from "../../../helpers/helper"

function Users() {
    const moment = require('moment')
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_, setIsLoading_] = useState(false)
    const [labelSearch, setLabelSearch] = useState('')

    const [affectation, setAffectation] = useState({
        profileId: "",
        code: "",
        label: "",
        partitioned: true
    })

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [affectations, setAffectations] = useState([])
    const [affectationsFiltered, setAffectationsFiltered] = useState([])
    const [profils, setProfils] = useState([])
    const [profilsFiltered, setProfilsFiltered] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])

    const getAffectationList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getBusinessRoles().then((response) => {
                const result = response.data
                setAffectations(result)
                setAffectationsFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
            })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }, [filter.itemsPerPage])

    const editShowItem = (item) => {
        setShowModal(true)

        let newArr = profils.filter((el) => el.id === item.profileId)
        setLabelSearch(`${newArr[0]?.id} | ${newArr[0]?.name}`)

        setAffectation((prev) => ({
            ...prev,
            profileId: item.profileId,
            code: item.code,
            label: item.label,
            partitioned: item.partitioned,
            id: item.id
        }))
    }

    const resetAffectation = () => {
        setAffectation((prev) => ({
            ...prev,
            profileId: "",
            code: "",
            label: "",
            partitioned: true
        }))
    }

    const setCreateAffectation = async () => {
        setShowMessage(false)
        if (
            affectation.profileId !== '' &&
            affectation.code !== '' &&
            affectation.label !== '' &&
            affectation.partitioned !== ''
        ) {
            setIsLoading(true)
            if (affectation.id !== undefined) {
                try {
                    await updateBusinessRole({ label: affectation.label, enabled: true }, affectation.id)
                        .then(() => {
                            setShowMessage(true)
                            setMessageStatus(1)
                            setMessage("L'affectation a été modifier avec succès")
                            getAffectationList()
                            setIsLoading(false)
                            setItShowEdit(false)
                        }).catch((error) => {
                            console.log("try : =>", error)
                            setIsLoading(false)
                        })
                } catch (error) {
                    console.log("catch : => ", error)
                    setIsLoading(false)
                }
            } else {
                try {
                    await createBusinessRole(affectation)
                        .then(({ data }) => {
                            console.log(data)
                            getAffectationList()
                            toggleModal()
                            setIsLoading(false)
                        }).catch((error) => {
                            console.log("try : =>", error)
                            setIsLoading(false)
                        })
                } catch (error) {
                    console.log("catch : => ", error)
                    setIsLoading(false)
                }
            }
        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }

    const headers = [
        "Id",
        "Code",
        "Label",
        "Statut",
        "Date de création",
        "Action"
    ]

    const statusItems = [
        {
            label: "Tout",
            value: 0,
            default: true
        },
        {
            label: "Active",
            value: 1,
        },
        {
            label: "Non-active",
            value: 2,
        }
    ]

    const toggleModal = () => {
        setShowModal(!showModal)
        setItShowEdit(false)
        setShowMessage(false)
        setIsLoading(false)
        resetAffectation()
    }

    const personTypes = [
        {
            label: "Avec",
            value: true
        },
        {
            label: "Sans",
            value: false
        },
    ]

    const getProfilsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getProfils().then(({ data }) => {
                const result = data

                setProfils(result)
                setProfilsFiltered(result)
            })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }, [])

    const setItemSearch = async (item) => {
        if (item !== undefined) {
            setAffectation((prev) => ({
                ...prev,
                profileId: item.id
            }))

            setLabelSearch(`${item.id} | ${item.name}`)
            setIsLoading_(false)
        }
    }

    useEffect(() => {
        if (affectations.length === 0) {
            getAffectationList()
        }
    }, [affectations, getAffectationList])

    useEffect(() => {
        if (profils.length === 0) {
            getProfilsList()
        }
    }, [profils, getProfilsList])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={affectations} setArrayFiltered={setAffectationsFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => toggleModal()} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter une affectation</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={affectationsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {affectationsFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='p-3'>{item.label}</td>
                                    <td className='p-3'>{!item.locked ? 'Active' : "Non-active"}</td>
                                    {/* <td className='p-3'>{setCategoryName(item.personType)}</td> */}
                                    <td className='p-3'>{moment(item.createdAt).format('ll')}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md' onClick={() => editShowItem(item)}>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{affectation.id !== undefined ? 'Information utilisateur' : 'Ajouter une affectation'}</h3>
                            <div className='flex flex-col my-4 gap-4'>
                                <div className='w-full'>
                                    <div className='w-full'>
                                        <SearchText
                                            arrayFiltered={profilsFiltered}
                                            initialArray={profils}
                                            setArrayFiltered={setProfilsFiltered}
                                            label={'Profile'}
                                            required={true}
                                            setLoader={setIsLoading_}
                                            defaultValue={labelSearch}
                                            setDefaultValue={setLabelSearch}
                                            disabled={affectation.id !== undefined}
                                        />
                                    </div>
                                </div>
                                {(isLoading_ !== false) &&
                                    <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                                        {profilsFiltered.length > 0 ?
                                            profilsFiltered.map((el, id) => (
                                                <button onClick={() => setItemSearch(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                                                    {`${el.id} | ${el.name}`}
                                                </button>
                                            ))
                                            :
                                            <div className='w-full flex justify-center items-center'>
                                                Aucun employé trouver
                                            </div>}
                                    </div>
                                }
                            </div>

                            <div>
                                <Input disabled={affectation.id !== undefined} required={true} label={'Code'} placeholder="Code" id={'code'} value={affectation.code} setValue={setAffectation} />
                            </div>

                            <div>
                                <Input disabled={!itShowEdit && affectation.id !== undefined} required={true} label={'Label'} placeholder="Label" id={'label'} value={affectation.label} setValue={setAffectation} />
                            </div>

                            <div>
                                <Input disabled={affectation.id !== undefined} required={true} label={'Partitionner ?'} id={'partitioned'} setValue={setAffectation} select={true} selectItems={personTypes} value={affectation.partitioned} />
                            </div>

                            <div className='flex flex-row gap-4'>
                                {(affectation.id !== undefined) && (itShowEdit ?
                                    <button onClick={setCreateAffectation} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier l\'utilisateur'} </button>
                                    :
                                    <button onClick={() => setItShowEdit(true)} className={`${'py-2 px-10 rounded-md bg-[#7c83e1] text-white'}`} disabled={isLoading}>
                                        <HiPencil />
                                    </button>)
                                }

                                {affectation.id === undefined &&
                                    <button onClick={setCreateAffectation} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter un utilisateur'} </button>}
                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default Users

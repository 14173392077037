import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getServices, createService, updateService } from '../../../api/services'
import { HiEye, HiPencil } from "react-icons/hi"
import MessagePanel from '../../../component/Card/MessagePanel'
import SearchText from '../../../component/SearchText'
import { refresh } from '../../../helpers/helper'

function Services() {
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const [service, setService] = useState({
        code: '',
        label: '',
        description: ''
    })
    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [services, setServices] = useState([])
    const [servicesFiltered, setServicesFiltered] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])

    const headers = [
        "Id",
        "Code",
        "Label",
        "Statut",
        'Description',
        "Action"
    ]
    const statusItems = [
        {
            label: "Tout",
            value: 0,
            default: true
        },
        {
            label: "Active",
            value: 1,
        },
        {
            label: "Non-active",
            value: 2,
        }
    ]


    const toggleModal = useCallback(() => {
        resetService()
        setShowModal(!showModal)
        setItShowEdit(false)
        setShowMessage(false)
    }, [showModal])

    const getServicesList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getServices().then((res) => {
                const result = res.data
                setServices(result)
                setServicesFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })

        } catch (error) {
            const errorMessage = error.response.data.detail
            console.log(errorMessage)
            setIsLoading(false)
        }
    }, [filter.itemsPerPage])

    const setCreateService = useCallback(async () => {
        if (service.code !== '' && service.label !== '') {
            if (service.id !== undefined) {
                setIsLoading(true)
                try {
                    await updateService(
                        service.code,
                        service.label,
                        service.description,
                        service.id
                    )
                        .then(() => {
                            getServicesList()
                            setIsLoading(false)
                            toggleModal()
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                    toggleModal()
                }
            } else {
                setIsLoading(true)
                try {
                    await createService(
                        service.code,
                        service.label,
                        service.description,
                    )
                        .then(() => {
                            getServicesList()
                            setIsLoading(false)
                            toggleModal()
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                    toggleModal()
                }
            }
        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }, [service, getServicesList, toggleModal])

    const editShowItem = (item) => {
        setShowModal(true)
        setService((prev) => ({
            ...prev,
            code: item.code,
            label: item.label,
            description: item.description,
            id: item.id
        }))
    }

    const resetService = () => {
        setService((prev) => ({
            ...prev,
            code: '',
            label: '',
            description: '',
            id: undefined
        }))
    }


    useEffect(() => {
        if (services.length === 0 && isLoad === false) {
            getServicesList()
        }
    }, [services, getServicesList, isLoad])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={services} setArrayFiltered={setServicesFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => toggleModal()} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter un service</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={servicesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {servicesFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='p-3'>{item.label}</td>
                                    <td className='p-3'>{!item.active ? 'Active' : "Non-active"}</td>
                                    <td className='p-3'>{item.description}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md' onClick={() => editShowItem(item)}>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>
                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{service.id !== undefined ? 'Information service' : 'Ajouter un service'}</h3>
                            <div>
                                <Input disabled={!itShowEdit && service.id !== undefined} required={true} label={'Code service'} placeholder="Code" id={'code'} value={service.code} setValue={setService} />
                            </div>
                            <div>
                                <Input disabled={!itShowEdit && service.id !== undefined} required={true} label={'Service label'} placeholder="Label" id={'label'} value={service.label} setValue={setService} />
                            </div>
                            <div>
                                <Input disabled={!itShowEdit && service.id !== undefined} label={'Description service'} placeholder="Description" id={'description'} value={service.description} setValue={setService} multiline={true} />
                            </div>

                            <div className='flex flex-row gap-4'>
                                {(service.id !== undefined) && (
                                    itShowEdit ?
                                        <button onClick={setCreateService} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier le service'} </button>
                                        :
                                        <button onClick={() => setItShowEdit(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                                            <HiPencil />
                                        </button>
                                )}
                                {service.id === undefined && <button onClick={setCreateService} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter un service'} </button>}
                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default Services

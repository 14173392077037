import React, { useState, useCallback, useEffect, useRef } from 'react'
import { createCashInCashOut, getOperations, getOperationTypes } from '../../../api/operations'
import Input from '../../../component/Input'
import SearchText from '../../../component/SearchText'
import MessagePanel from '../../../component/Card/MessagePanel'
import { setName, goTo } from '../../../helpers/helper'
import { getComptes } from '../../../api/account'
import { HiPrinter, HiDocument } from 'react-icons/hi'
import { useReactToPrint } from 'react-to-print';
import PaiementTicket from '../../../component/PaiementTicket'
import { useSelector } from 'react-redux'


function Add() {
    const componentRef = useRef();
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    // console.log(user?.partition?.name)
    const pageStyle = `
    @page {
      margin: 15px 15px 0px 15px  !important; 
      display: flex; 
      flex-direction: column;
      flex : 1 !important;
      align-items: center; 
      justify-content: center;
      height : 100vh;
    }
  `;

    const getPageMargins = () => {
        return `@page { }`;
    };

    const handlePrint = useReactToPrint({
        pageStyle,
        content: () => componentRef.current,
    });

    const [isLoad, setIsLoad] = useState(false)
    const [isLoading_, setIsLoading_] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [operations, setOperations] = useState([])
    const [comptes, setComptes] = useState([])
    const [comptesFiltered, setComptesFiltered] = useState([])
    const [caisseAccount, setCaisseAccount] = useState([])
    const [compteLabel, setCompteLabel] = useState('')
    const [compte, setCompte] = useState('')
    const [id, setId] = useState('')
    const [dataToPrint, setDataToPrint] = useState('')

    const [item, setItem] = useState({
        tellerAccountId: '',
        operatingAccount: '',
        operation: '',
        amount: '',
        currency: 'USD',
        instrument: null
    })

    // const currencies = [
    //     { value: 'USD', label: 'US Dollar', default: true },
    //     { value: 'CDF', label: 'FC Congolais' },
    // ]

    const getOperationList = useCallback(async () => {
        try {
            await getOperations()
                .then(async (response) => {
                    let types = []
                    await getOperationTypes()
                        .then(async (res) => {
                            const response = res.data
                            let newArr = []
                            await response.forEach((el) => {
                                newArr.push({ value: el.id, label: el.label })
                            })
                            types = newArr
                        })
                    const result = response.data
                    let newArr = []
                    await result.forEach((el, i) => {
                        i === 0 ?
                            newArr.push({ value: el.id, label: setName(types, el.type), default: true }) :
                            newArr.push({ value: el.id, label: setName(types, el.type) })
                    })
                    setOperations(newArr)
                    setItem((prev) => ({
                        ...prev,
                        operation: newArr[0]?.value
                    }))
                    setIsLoad(true)
                })
        } catch (error) {
            console.error(error)
        }
    }, [])

    const getComptesList = useCallback(async () => {
        try {
            await getComptes()
                .then(async ({ data }) => {
                    let res = data.filter((el) => el.holderId === undefined || el.holderId === null || el.holderId === '')
                    let clients = data.filter((el) => el.holderId !== undefined)
                    let newArr = []
                    await res.forEach((el) => {
                        newArr.push({ value: el.id, label: `${el.name} (${el.currency})`, currency: el.currency })
                    })
                    setCaisseAccount(newArr)
                    setComptes(clients)
                })
        } catch (error) {
            console.log(error)
        }
    }, [])

    const setCreation = async () => {
        if (
            item.amount !== '' &&
            item.tellerAccountId !== ''
        ) {
            setIsLoading(true)
            try {
                await createCashInCashOut(
                    item.tellerAccountId,
                    item.operatingAccount,
                    `/api/operation_settings/${item.operation}`,
                    item.amount,
                    item.currency,
                    item.instrument
                ).then(({ data }) => {
                    setIsLoading(false)
                    setMessage('Operation effectuer avec succès')
                    setMessageStatus(1)
                    setShowMessage(true)
                    setDataToPrint({
                        dateOpération: data.initiatedAt,
                        codeCaissier: data.initiator,
                        refOperation: data.id,
                        operationType: `${data.type.label} (${data.type.code})`,
                        agence: user?.partition !== undefined ? `${user?.partition.name} | ${user?.partition?.address} | ${user?.partition?.state}` : '',
                        accountNumber: compte.accountNumber,
                        accountName: compte.accountName,
                        description: data.description,
                        rate: data.fee,
                        amount: data.amount,
                        currency: data.currency,
                    })
                    setId(data.operatingAccount)
                })
            } catch (error) {
                const errorMessage = error.response.data.detail
                setIsLoading(false)
                setMessage(errorMessage)
                setMessageStatus(0)
                setShowMessage(true)
            }
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const reset = () => {
        setItem({
            tellerAccountId: '',
            operatingAccount: '',
            operation: '',
            amount: '',
            currency: 'USD',
            instrument: null
        })

        setCompteLabel(null)
        setMessageStatus(0)
        setShowMessage(false)
    }

    const setOperatingAccount = async (item) => {
        if (item !== undefined) {
            let newArr = await caisseAccount.filter((el) => el.currency === item.currency)
            setCaisseAccount(newArr)

            setItem((prev) => ({
                ...prev,
                operatingAccount: item.id,
                currency: item.currency,
                tellerAccountId: newArr[0] !== undefined && newArr[0].value
            }))

            setCompteLabel(`Nom : ${item.name} | Compte : ${item.accountNumber} | ID : ${item.id} | Devise : ${item.currency} | Balance : ${item.balance}`)
            setCompte({
                accountNumber: item.accountNumber,
                accountName: item.name
            })
            setIsLoading_(false)
        }
    }

    useEffect(() => {
        if (operations.length === 0 && isLoad === false) {
            getOperationList()
        }
    }, [operations, getOperationList, isLoad])

    useEffect(() => {
        if (comptes.length === 0 && isLoad === false) {
            getComptesList()
        }
    })

    return (
        <div className="h-full py-10 px-10">
            <div className='w-full flex flex-row min-h-[320px] gap-4 justify-center'>
                <div className='w-full border-2 px-5 py-5 rounded-md'>
                    {showMessage && (
                        <MessagePanel message={message} messageStatus={messageStatus} />
                    )}

                    <div className='flex flex-row justify-between'>
                        <h3 className='uppercase text-[24px] font-bold'>Depot / Retrait</h3>
                    </div>
                    <div className='flex flex-col my-4 gap-4'>
                        <div className='w-full'>
                            <div className='w-full'>
                                <SearchText
                                    arrayFiltered={setComptesFiltered}
                                    initialArray={comptes}
                                    setArrayFiltered={setComptesFiltered}
                                    label={'Compte'}
                                    required={true}
                                    setLoader={setIsLoading_}
                                    defaultValue={compteLabel}
                                    setDefaultValue={setCompteLabel}
                                    disabled={messageStatus === 1 || messageStatus === '1'}
                                />
                            </div>
                        </div>
                        {(isLoading_ !== false) &&
                            <div className='w-full shadow-md border-2 rounded-md py-5 px-3 max-h-[300px] overflow-y-scroll'>
                                {comptesFiltered.length > 0 ?
                                    comptesFiltered.map((el, id) => (
                                        <button onClick={() => setOperatingAccount(el)} key={id} className='py-3 px-2 hover:bg-[#ddd] rounded-sm w-full flex items-start'>
                                            {`Nom : ${el.name} | Compte : ${el.accountNumber} | ID : ${el.id} | Devise : ${el.currency}`}
                                        </button>
                                    ))
                                    :
                                    <div className='w-full flex justify-center items-center'>
                                        Aucun compte trouver
                                    </div>}
                            </div>
                        }
                    </div>
                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input required={true} label={'Montant'} placeholder={'Montant'} id={'amount'} value={item.amount} setValue={setItem} disabled={messageStatus === 1 || messageStatus === '1'} />
                            </div>
                        </div>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input required={true} select={true} selectItems={operations} label={'Opération'} id={'operation'} value={item.operation} setValue={setItem} disabled={messageStatus === 1 || messageStatus === '1'} />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input required={true} select={true} selectItems={caisseAccount} label={'Compte caisse'} id={'tellerAccountId'} value={item.tellerAccountId} setValue={setItem} disabled={messageStatus === 1 || messageStatus === '1'} />
                            </div>
                        </div>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input required={true} placeholder={'Devise'} label={'Devise'} id={'currency'} value={item.currency} setValue={setItem} disabled={true} />
                            </div>
                        </div>
                    </div>

                    {messageStatus === 1 || messageStatus === '1' ? (
                        <div className='flex flex-row gap-4 mt-4'>
                            <div style={{ display: "none" }}>
                                <PaiementTicket ref={componentRef} data={dataToPrint} />
                            </div>
                            <style>{getPageMargins()}</style>
                            <button onClick={handlePrint} className='py-2 px-10 rounded-md bg-[#78dd8e] text-white flex flex-row gap-2 justify-center items-center'>
                                <HiPrinter />
                                Imprimer la preuve
                            </button>
                            <button onClick={() => goTo(`entries-manager/${id}`)} className='py-2 px-10 rounded-md text-white bg-[#3e7ac3] flex flex-row gap-2 justify-center items-center'>
                                <HiDocument />
                                Voir l'opération
                            </button>

                            <button onClick={reset} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Rafraîchir</button>
                        </div>
                    ) : (
                        <div className='flex flex-row gap-4 mt-4'>
                            <button onClick={setCreation} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{isLoading ? 'Execution en cours...' : 'Executer'}</button>
                            <button onClick={reset} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Rafraîchir</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Add

import React, { useState, useEffect, useCallback } from 'react'
import { getAccountTypes } from '../../../../api/accountTypes'
import Input from '../../../../component/Input'
import ModelTwo from '../../../../component/Table/ModelTwo'
import { setName } from '../../../../helpers/helper'
import { HiEye, HiTrash, HiPencil } from 'react-icons/hi'
// import Modal from "../../../../component/Modal"
import Modal from '@mui/material/Modal';
import MessagePanel from '../../../../component/Card/MessagePanel'
import { getServices } from '../../../../api/services'
import { createProduct, getProductById, updateProduct } from '../../../../api/products'
import { removeItem } from '../../../../helpers/helper'
import { useParams } from 'react-router-dom'
import { setChildVisible } from '../../../../helpers/roleManager'
import { useSelector } from 'react-redux'


function AddProduct() {
    const { id } = useParams()
    const [product, setProduct] = useState({
        code: '',
        label: '',
        type: '',
    })

    const [featureItems, setFeatureItems] = useState([])

    const [featureItem, setFeatureItem] = useState({
        feature: '',
        limits: [],
        pricings: []
    })

    const [limit, setLimit] = useState({
        periodicity: "HOUR",
        limitValue: "",
        type: "V",
        currency: "USD"
    })

    const [pricing, setPricing] = useState({
        rate: "",
        fix: "",
        minimum: "",
        maximum: "",
        currency: "USD"
    })

    const [types, setTypes] = useState([])
    const [services, setServices] = useState([])
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const periodicity = [
        { label: 'Par jour', value: 'DAY' },
        { label: 'Par heure', value: 'HOUR' },
        { label: 'Par semaine', value: 'WEEK' },
        { label: 'Par mois', value: 'MONTH' },
        { label: 'Par an', value: 'YEAR' }
    ]

    const currency = [
        { label: 'US Dollars', value: 'USD' },
        { label: 'FC Congolais', value: 'CDF' },
    ]

    const type = [
        { label: 'Volume de transaction', value: 'V' },
        { label: 'Nombre de transaction', value: 'C' },
        { label: 'Mottant de la transaction', value: 'A' },
    ]

    const [showModal, setShowModal] = useState(false)
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const headers = [
        'Num',
        'Services',
        'Action'
    ]

    const resetFeatureItem = useCallback(() => {
        setFeatureItem((prev) => ({
            ...prev,
            feature: '',
            limits: [],
            pricings: [],
            id: null
        }))

    }, [])

    const toggleModal = useCallback(() => {
        resetFeatureItem()
        setShowModal(!showModal)
        setShowMessage(false)
    }, [showModal, resetFeatureItem])

    const resetProduct = () => {
        if (id === undefined) {
            resetFeatureItem()
            setFeatureItems([])
            setProduct((prev) => ({
                ...prev,
                code: '',
                label: '',
                type: types[0].value,
            }))

        }

        setShowMessage(false)
        setItShowEdit(false)
    }

    const resetLimit = () => {
        setLimit((prev) => ({
            ...prev,
            periodicity: "HOUR",
            limitValue: "",
            type: "V",
            currency: "USD"
        }))
        setShowMessage(false)
    }

    const resetPricing = () => {
        setPricing((prev) => ({
            ...prev,
            rate: "",
            fix: "",
            minimum: "",
            maximum: "",
            currency: "USD"
        }))
        setShowMessage(false)
    }

    const getAccountTypesList = useCallback(async () => {
        try {
            await getAccountTypes().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((el) => {
                    newArr.push({ value: el.id, label: `${el.label} (${el.code})` })
                })
                setTypes(newArr)
                setProduct((prev) => ({
                    ...prev,
                    type: newArr[0].value,
                }))
            })

        } catch (error) {
            const errorMessage = error.response.data.detail
            console.log(errorMessage)
        }
    }, [])

    const getServicesList = useCallback(async () => {
        try {
            await getServices().then(async (res) => {
                const result = res?.data
                let newArr = []
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.id, label: el.label, default: true }) :
                        newArr.push({ value: el.id, label: el.label })
                })
                setServices(newArr)
                setFeatureItem((prev) => ({
                    ...prev,
                    feature: newArr[0]?.value
                }))
            })

        } catch (error) {
            const errorMessage = error?.response?.data?.detail
            console.log(errorMessage)
        }
    }, [])

    const addLimitFeatureItem = () => {
        if (limit.limitValue !== '') {
            let newLimit = featureItem.limits
            newLimit.push(limit)
            setFeatureItem((prev) => ({
                ...prev,
                limits: newLimit
            }))
            resetLimit()
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const addPricingFeatureItem = () => {
        if (pricing.rate !== ''
            && pricing.fix !== ''
            && pricing.maximum !== ''
            && pricing.maximum !== ''
        ) {
            let newPricing = featureItem.pricings
            newPricing.push(pricing)
            setFeatureItem((prev) => ({
                ...prev,
                pricings: newPricing
            }))
            resetPricing()
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const addFeatureItem = () => {
        console.log(featureItem.feature, featureItem.pricings.length)
        if (featureItem.feature !== '' && featureItem.pricings.length > 0) {

            // console.log('awa')

            if (featureItem.id !== null && featureItem.id !== undefined) {
                let newArr = featureItems
                newArr[featureItem.id] = {
                    feature: "/api/features/" + featureItem.feature,
                    limits: featureItem.limits,
                    pricings: featureItem.pricings
                }

                setFeatureItems(newArr)
                toggleModal()
            } else {
                let newArr = featureItems
                newArr.push({
                    feature: "/api/features/" + featureItem.feature,
                    limits: featureItem.limits,
                    pricings: featureItem.pricings
                })
                setFeatureItems(newArr)
                toggleModal()
            }

        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const deleteLimitItem = (arr, index) => {
        let newArr = removeItem(arr, index)
        setFeatureItem((prev) => ({
            ...prev,
            limits: newArr
        }))
    }

    const deletePricingItem = (arr, index) => {
        let newArr = removeItem(arr, index)
        setFeatureItem((prev) => ({
            ...prev,
            pricings: newArr
        }))
    }

    const setCreateProduct = async () => {
        if (product.code !== '' && product.label !== '' && product.type !== '') {
            if (id !== undefined) {
                let featureItems_ = []
                let limits_ = []
                let pricings_ = []

                featureItems.forEach((el) => {
                    el.limits.forEach((item) => {
                        limits_.push({
                            periodicity: item.periodicity,
                            limitValue: item.limitValue,
                            type: item.type,
                            currency: item.currency
                        })
                    })

                    el.pricings.forEach((item_) => {
                        pricings_.push({
                            rate: item_.rate,
                            fix: item_.fix,
                            minimum: item_.minimum,
                            maximum: item_.maximum,
                            currency: item_.currency
                        })
                    })

                    featureItems_.push({
                        feature: (typeof el.feature === 'string') ? el.feature : "/api/features/" + el.feature.id,
                        limits: limits_,
                        pricings: pricings_
                    })

                    limits_ = []
                    pricings_ = []
                })

                // console.log(featureItems_)

                try {
                    await updateProduct(product.code, product.label, '/api/account_types/' + product.type, featureItems_, id)
                        .then(() => {
                            setIsLoading(false)
                            resetProduct()
                            setMessage('le produit a été modifier avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
            } else {
                try {
                    await createProduct(product.code, product.label, '/api/account_types/' + product.type, featureItems)
                        .then(() => {
                            setIsLoading(false)
                            resetProduct()
                            setMessage('le produit a été créer avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
            }
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const getProductInfos = useCallback(async () => {
        let productId = id.toString()
        await getProductById(productId)
            .then(({ data }) => {
                setIsLoad(false)

                setProduct((prev) => ({
                    prev,
                    code: data.code,
                    label: data.label,
                    type: data.type
                }))
                setFeatureItems(data.featureItems)
            })
    }, [id])

    const showItem = (item, index) => {
        let limits = []
        let pricings = []
        let feature = (typeof item.feature === 'string') && item.feature.slice(14, item.feature.length)

        item.limits.forEach((el) => {
            limits.push({
                periodicity: el.periodicity,
                limitValue: el.limitValue,
                type: el.type,
                currency: el.currency
            })
        })

        item.pricings.forEach((el) => {
            pricings.push({
                rate: el.rate,
                fix: el.fix,
                minimum: el.minimum,
                maximum: el.maximum,
                currency: el.currency
            })
        })

        setFeatureItem((prev) => ({
            ...prev,
            feature: (typeof item.feature === 'string') ? feature : item.feature.id,
            limits: limits,
            pricings: pricings,
            id: index
        }))
        setShowModal(true)
    }

    useEffect(() => {
        if (id !== undefined && isLoad === false) {
            getProductInfos()
        }
    }, [id, isLoad, getProductInfos])

    useEffect(() => {
        if (types.length === 0) {
            getAccountTypesList()
        }
    })

    useEffect(() => {
        if (services.length === 0) {
            getServicesList()
        }
    }, [services, getServicesList])

    return (
        <div className='flex flex-col h-full w-full items-center py-10'>
            <div className='border-2 px-10 py-10 w-10/12'>
                <div className='flex flex-row gap-3 items-center'>
                    {/* <button className='text-2xl' onClick={() => goTo('product-manager')}>
                        <HiArrowCircleLeft />
                    </button> */}
                    <div className='flex mt-3'>
                        <h3 className='text-2xl mb-4 font-semibold uppercase'>
                            {id !== undefined ? 'Modifier le produit' : 'Ajouter un produit'}
                        </h3>
                    </div>
                </div>
                {showMessage && (
                    <MessagePanel message={message} messageStatus={messageStatus} />
                )}

                <div className='flex flex-row gap-3 w-full'>
                    <div className='w-6/12'>
                        <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Code produit'} placeholder={'Code produit'} value={product.code} setValue={setProduct} id={'code'} />
                    </div>
                    <div className='w-6/12'>
                        <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Label produit'} placeholder={'Label produit'} value={product.label} setValue={setProduct} id={'label'} />
                    </div>
                </div>
                <div className='flex mt-4'>
                    <Input disabled={id !== undefined && !itShowEdit} required={true} select={true} label={'Sélectionner le type de compte'} selectItems={types} setValue={setProduct} id={'type'} value={product.type} />
                </div>
                <div className='flex mt-4 flex-col'>
                    <button disabled={id !== undefined && !itShowEdit} onClick={toggleModal} className='px-5 py-2 border-2 rounded-md text-[14px] '>{'Ajouter un service au produit (*)'}</button>
                    <ModelTwo headers={headers} styles={'mt-4'}>
                        {id !== undefined ?
                            featureItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{index + 1}</td>
                                    <td className='p-3'>{item.feature.label === undefined ? setName(services, item.feature) : item.feature.label}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button onClick={() => showItem(item, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                            :
                            featureItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{index + 1}</td>
                                    <td className='p-3'>{setName(services, item.feature)}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </ModelTwo>
                </div>
                <div className='flex flex-row gap-2 mt-5 items-end'>
                    {
                        (id !== undefined) && (
                            itShowEdit ?
                                <button onClick={setCreateProduct} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#0064ff]'>
                                    {isLoading === true ? 'Modification en cours...' : 'Modifier le produit'}
                                </button>
                                :
                                <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_PROFILE_UPDATE']) === true ? 'px-5 py-3 text-white rounded-md text-[14px] bg-[#0064ff]' : 'hidden'}`}>
                                    <HiPencil />
                                </button>
                        )
                    }

                    {id === undefined && <button onClick={setCreateProduct} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#0064ff]'>
                        {isLoading === true ? 'Création en cours...' : 'Créer un produit'}
                    </button>
                    }
                    <button onClick={resetProduct} className='px-5 py-2 text-white rounded-md text-[14px] bg-[#E94228]'>Annuler</button>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{itShowEdit ? 'Information du service' : 'Ajouter un service au produit'}</h3>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}
                            <div>
                                <Input
                                    required={true}
                                    select={true}
                                    label={'sélectionner un service'}
                                    id={'feature'}
                                    selectItems={services}
                                    setValue={setFeatureItem}
                                    value={
                                        featureItem.feature !== '' ?
                                            featureItem.feature :
                                            (services[0] !== undefined && services[0].value)
                                    } />
                            </div>

                            <span className='font-semibold'>{'Limites(*) : '}</span>
                            <div className='flex-row flex gap-4 justify-center items-end'>
                                <Input required={true} select={true} label={'sélectionner une période'} id={'periodicity'} selectItems={periodicity} setValue={setLimit} value={limit.periodicity} />
                                <Input required={true} select={true} label={'sélectionner une catégorie'} id={'type'} selectItems={type} setValue={setLimit} value={limit.type} />
                                <Input required={true} label={'Limite'} id={'limitValue'} placeholder={'Limite'} setValue={setLimit} value={limit.limitValue} />
                                <Input required={true} select={true} label={'sélectionner une devise'} id={'currency'} selectItems={currency} setValue={setLimit} value={limit.currency} />
                            </div>
                            <div className='flex flex-row gap-2'>
                                <button onClick={addLimitFeatureItem} className='px-5 py-2 border-2 rounded-md text-[14px] bg-[#9a9ed3]'>Ajouter une limite au service</button>
                                <button onClick={resetLimit} className='px-5 py-2 border-2 rounded-md text-[14px] bg-[#e1a388]'>Annuler</button>
                            </div>
                            <div>
                                <ModelTwo headers={['Période', 'Catégorie', 'Mottant', 'Devise', 'Action']}>
                                    {featureItem.limits.map((item, index) => (
                                        <tr key={index} className="border-b-2 border-gray-300">
                                            <td className='p-3'>{setName(periodicity, item.periodicity)}</td>
                                            <td className='p-3'>{setName(type, item.type)}</td>
                                            <td className='p-3'>{item.limitValue}</td>
                                            <td className='p-3'>{setName(currency, item.currency)}</td>
                                            <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                                <button onClick={() => deleteLimitItem(featureItem.limits, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                                    <HiTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ModelTwo>
                            </div>

                            <span className='font-semibold'>{'Tarification : '}</span>
                            <div className='flex-row flex gap-4 justify-center items-end'>
                                <Input required={true} label={'Pourcent'} id={'rate'} placeholder={'Pourcent'} setValue={setPricing} value={pricing.rate} />
                                <Input required={true} label={'Fixe'} id={'fix'} placeholder={'Fixe'} setValue={setPricing} value={pricing.fix} />
                                <Input required={true} label={'Minimum'} id={'minimum'} placeholder={'Minimum'} setValue={setPricing} value={pricing.minimum} />
                                <Input required={true} label={'Maximum'} id={'maximum'} placeholder={'Maximum'} setValue={setPricing} value={pricing.maximum} />
                                <Input required={true} select={true} label={'sélectionner une devise'} id={'currency'} selectItems={currency} setValue={setPricing} value={pricing.currency} />
                            </div>
                            <div className='flex flex-row gap-2'>
                                <button onClick={addPricingFeatureItem} className='px-5 py-2 border-2 rounded-md text-[14px] bg-[#9a9ed3]'>Ajouter un prix au service</button>
                                <button onClick={resetPricing} className='px-5 py-2 border-2 rounded-md text-[14px] bg-[#e1a388]'>Annuler</button>
                            </div>
                            <div>
                                <ModelTwo headers={['Pourcent', 'Montant', 'Maximum', 'Minimum', 'Devise', 'Action']}>
                                    {featureItem.pricings.map((item, index) => (
                                        <tr key={index} className="border-b-2 border-gray-300">
                                            <td className='p-3'>{item.rate}</td>
                                            <td className='p-3'>{item.fix}</td>
                                            <td className='p-3'>{item.maximum}</td>
                                            <td className='p-3'>{item.minimum}</td>
                                            <td className='p-3'>{setName(currency, item.currency)}</td>
                                            <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                                <button onClick={() => deletePricingItem(featureItem.pricings, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                                    <HiTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ModelTwo>
                            </div>

                            <div className='flex flex-row gap-4'>
                                {(featureItem.id !== null && featureItem.id !== undefined && itShowEdit === true) && (
                                    <button
                                        onClick={addFeatureItem}
                                        className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'
                                        disabled={isLoading}
                                    >
                                        Modifier le service au produit
                                    </button>
                                )}

                                {(featureItem.id === null || featureItem.id === undefined) && (
                                    <button
                                        onClick={addFeatureItem}
                                        className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'
                                        disabled={isLoading}
                                    >
                                        Ajouter un service au produit
                                    </button>
                                )}

                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default AddProduct
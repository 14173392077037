import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import SearchText from '../../../component/SearchText'
import { getTransactions } from '../../../api/account'
import { setName, refresh, currencies } from '../../../helpers/helper'
import DateFilter from '../../../component/DateFilter'
import ToolTip from '../../../component/ToolTip'
import UserResume from '../../../component/UserResume'

function Transactions() {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const moment = require('moment')
    const [defaultStatus, setDefaultStatus] = useState('AA')
    const [defaultCurrency, setDefaultCurrency] = useState('AA')

    const [filter, setFilter] = useState({
        searchText: '',
        status: 'AA',
        itemsPerPage: 10,
        currency: "AA"
    })

    const statusTrans = [
        {
            value: "A", label: "Acceptée",
        }, {
            value: "R", label: "Refusée",
        }, {
            value: "P", label: "En cours",
        },
    ]

    const [transactions, setTransactions] = useState([])
    const [transactionsFiltered, setTransactionsFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])

    const headers = [
        "Date",
        "No.Transaction",
        "Description",
        "Id Client",
        "Montant",
        "Commission",
        "Statut",
    ]

    const statusItems = [
        {
            label: "Tout",
            value: "AA",
            default: true
        },
        {
            label: "Acceptée",
            value: "A",
        },
        {
            label: "Refusée",
            value: 'R',
        }
    ]

    const filterByStatus = useCallback((status) => {
        if (status === 'AA') {
            setTransactionsFiltered(transactions)
        } else {
            let startArray = transactions
            let lastArray = startArray.filter((el) => el.status === status)
            setTransactionsFiltered(lastArray)
            setDefaultStatus(status)
        }
    }, [transactions])

    const filterByDevise = useCallback((devise) => {
        if (devise === 'AA') {
            setTransactionsFiltered(transactions)
        } else {
            let startArray = transactions
            let lastArray = startArray.filter((el) => el.currency === devise)
            setTransactionsFiltered(lastArray)
            setDefaultCurrency(devise)
        }
    }, [transactions])


    const getTransactionsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getTransactions().then((res) => {
                const result = res.data
                setTransactions(result)
                setTransactionsFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage])

    useEffect(() => {
        if (transactions.length === 0 && isLoad === false) {
            getTransactionsList()
        }
    }, [transactions, getTransactionsList, isLoad])

    useEffect(() => {
        if (filter.status !== defaultStatus) {
            filterByStatus(filter.status)
        }
    }, [filter, defaultStatus, filterByStatus])

    useEffect(() => {
        if (filter.currency !== defaultCurrency) {
            filterByDevise(filter.currency)
        }
    }, [filter, defaultCurrency, filterByDevise])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='w-full'>
                <div className='flex-row flex gap-3 mb-5'>
                    <div className='w-6/12'>
                        <SearchText initialArray={transactions} setArrayFiltered={setTransactionsFiltered} setLoader={setIsLoading} />
                    </div>
                    <div className='flex flex-row gap-2 w-6/12 justify-end items-end'>
                        <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                    </div>
                </div>
                <div className='flex flex-row justify-between mb-4 gap-4'>
                    <div className='flex-col flex gap-2 w-6/12'>
                        <div className='flex-row flex gap-3'>
                            <div className='w-4/12'>
                                <Input label={'Filtrer par devise'} id={'currency'} value={filter.currency} setValue={setFilter} selectItems={currencies} select={true} />
                            </div>
                            <div className='w-4/12'>
                                <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                            </div>
                            <div className='w-4/12'>
                                <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                            </div>
                        </div>
                    </div>
                    <div className='w-6/12'>
                        <DateFilter elementKey={'date'} setArrayToFilter={setTransactionsFiltered} arrayToFilter={transactions} />
                    </div>
                </div>
                <ModelOne headers={headers} arrayItems={transactionsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                    {transactionsFiltered.length > 0 ?
                        currentItems.map((item, index) => (
                            <tr key={index} className="border-b-2 border-gray-300">
                                <td className='p-3'>{moment(item.date).format('DD-MM-Y à HH:MM')}</td>
                                <td className='p-3'>{item.authorizationId}</td>
                                <td className='p-3'>{item.description}</td>
                                <td className='p-3'>
                                    <ToolTip element={item.holderId}>
                                        <UserResume id={item.holderId} />
                                    </ToolTip>
                                </td>
                                <td className='p-3'>{`${item.transactionAmount} ${item.currency}`}</td>
                                <td className='p-3'>{`${item.commissionAmount} ${item.commissionCurrency}`}</td>
                                <td className='p-3'>{setName(statusTrans, item.status)}</td>
                            </tr>
                        )) : (
                            <tr className="">
                                <td
                                    colSpan="10"
                                    className="p-5 text-center text-gray-700 text-md"
                                >
                                    {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                </td>
                            </tr>
                        )}
                </ModelOne>
            </div>
        </div>
    )
}

export default Transactions

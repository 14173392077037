// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createEmployee = async (data) => {
    const url = '/employees'
    let data_ = data
    data_.plainPassword = data.email

    return await axiosInstance.post(url, data_)
}

export const updateEmployee = async (data, id) => {
    const url = '/employees/' + id
    return await axiosInstance.put(url, data)
}

export const getEmployees = () => {
    const url = '/employees'
    return axiosInstance.get(url)
}

export const getEmployeeById = (id) => {
    const url = '/employees/' + id
    return axiosInstance.get(url)
}
export const isEmptyObject = value => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export const keyExist = (obj, value) => {
  let result = null;
  value in obj ? (result = true) : (result = false);
  return result;
};

export const goTo = (path) => {
  window.location.replace(path)
}

export const setName = (arr = [], val) => {
  let el = arr.filter((element) => element.value === val || (element.value && val.toString().toLowerCase().includes(element.value.toString().toLowerCase())))
  return el[0] !== undefined ? el[0].label : ''
}

export const currencies = [
  { value: 'AA', label: 'Toutes' },
  { value: 'USD', label: 'US Dollar' },
  { value: 'CDF', label: 'FC Congolais' },
]

export const removeItem = (array, index) => {
  let item = array[index]
  return array.filter(i => i !== item)
}

export const refresh = () => {
  window.location.reload()
}

export const beforeUpload = (file) => {
  let valid = true

  const allowedFileType = ['image/jpeg', 'image/png', 'application/pdf', 'application/docx', 'application/doc']
  const maxFileSize = 5000000

  if (!allowedFileType.includes(file.type)) {
    valid = 'Veuillez entrer un document valide'
  }

  if (file.size >= maxFileSize) {
    valid = 'le fichier ne peut pas avoir une taille de plus de 5mb!'
  }


  return valid
}

export const handleFile = (event, setSelectedFile) => {
  setSelectedFile(event.target.files[0] !== null && event.target.files[0] !== undefined && event.target.files[0])
}

// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createContract = async (data) => {
    const url = '/contracts'

    return await axiosInstance.post(url, data)
}

export const updateContract = async (data, id) => {
    const url = '/contracts/' + id
    return await axiosInstance.put(url, data)
}


export const getContracts = () => {
    const url = '/contracts'

    return axiosInstance.get(url)
}

export const getContractById = (id) => {
    const url = '/contracts/' + id

    return axiosInstance.get(url)
}


import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import { HiEye } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import Input from '../../../component/Input'
import { getPlatforms } from "../../../api/platform"
import { refresh, goTo } from "../../../helpers/helper"

const PLateforme = () => {

    const headers = [
        "Id",
        "Nom",
        "Mode",
        // 'Code',
        "Statut",
        "Action"
    ]

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [plateformes, setPlateformes] = useState([])
    const [plateformesFiltered, setPlateformesFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const { countryCode } = require('../../../helpers/countryCode')

    const statusItems = [
        {
            label: "Tout",
            value: 0,
            default: true
        },
        {
            label: "Active",
            value: 1,
        },
        {
            label: "Non-active",
            value: 2,
        }
    ]

    const getPlatformList = useCallback(async () => {
        try {
            setIsLoading(true)

            await getPlatforms()
                .then(({ data }) => {
                    const result = data
                    setPlateformes(result)
                    setPlateformesFiltered(result)
                    setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                    let arrayItemsPerPage = []
                    for (let i = 1; i <= result.length; i++) {
                        if (i === 0) {
                            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                        } else {
                            arrayItemsPerPage.push({ value: i, label: i.toString() })
                        }
                    }
                    setItemsPerPageArray(arrayItemsPerPage)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoading(false)
                })
        } catch (error) {
            console.log(error)
        }
    }, [filter])

    useEffect(() => {
        if (plateformes.length <= 0) {
            getPlatformList()
        }
    }, [plateformes, getPlatformList])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={plateformesFiltered} setArrayFiltered={setPlateformesFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => window.location.replace('add-plateformes')} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter une plateforme</button>
                        </div>
                    </div>

                    <ModelOne headers={headers} arrayItems={plateformesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>

                        {plateformesFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr className="border-b-2 border-gray-300" key={index}>
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.name}</td>
                                    <td className='p-3'>{item.mode}</td>
                                    <td className='p-3'>{item.enabled ? 'Active' : 'Non-active'}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <button className='text-[16px] px-5 py-2 border-2 rounded-md' onClick={() => goTo(`add-plateformes/${item.id}`)}>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>
        </div>
    )
}

export default PLateforme

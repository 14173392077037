export const setChildVisible = (roles, role) => {
    if (role.length > 0 && roles !== undefined) {
        let result = false

        if (roles !== undefined) {
            role.forEach(element => {
                const propertyValues = Object.values(roles);
                if (propertyValues?.includes(element)) {
                    result = true
                } else {
                    result = false
                }
            });
        }

        return result
    }
}


export const setParentVisible = (menuArray, roles) => {
    let parent = []
    if (roles !== undefined) {
        menuArray.forEach((element) => {
            if (element.role?.length > 0) {
                element.role?.forEach((item) => {
                    const propertyValues = Object.values(roles);
                    parent = propertyValues?.includes(item)
                })
            }
        })
    }

    // console.log("eye", parent)
    return (typeof parent === 'object' || parent === false) ? false : true
}

export const Add_customer = ['SuperAdmin', 'Commercial']
export const Detail_customer = ['SuperAdmin', 'Commercial', 'Validateur']
export const Validate_customer = ['SuperAdmin', 'Validateur']
export const Validate_document_customer = ['SuperAdmin', 'Validateur']
export const Add_document_customer = ['SuperAdmin', 'Commercial']

export const Add_account = ['SuperAdmin', 'Commercial']
export const Edit_account = ['SuperAdmin', 'Commercial']
export const Detail_account = ['SuperAdmin', 'Commercial', 'Validateur']
export const Entries_account = ['SuperAdmin', 'Caissier']

export const Add_contract = ['SuperAdmin', 'Commercial']
export const Edit_contract = ['SuperAdmin', 'Commercial']
export const Detail_contract = ['SuperAdmin', 'Commercial', 'Validateur']
// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const getPlatforms = () => {
    const url = '/platforms'
    return axiosInstance.get(url)
}

export const getPlatformById = (id) => {
    const url = '/platforms/' + id
    return axiosInstance.get(url)
}

export const addPlatform = (data) => {
    const url = '/platforms'
    return axiosInstance.post(url, data)
}

export const addLogo = async (data, id) => {
    const url = '/platforms/' + id + '/logo'
    return await axiosInstance.put(
        url,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
    )
}

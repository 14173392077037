// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createService = async (
    code,
    label,
    description = '',
    // platformId
) => {
    const url = '/features'
    return await axiosInstance.post(
        url,
        {
            code,
            label,
            description,
            // platformId
        },
    )
}

export const updateService = async (
    code,
    label,
    description = '',
    // platformId,
    id
) => {
    const url = '/features/' + id
    return await axiosInstance.put(
        url,
        {
            code,
            label,
            description,
            // platformId
        },
    )
}

export const getServices = () => {
    const url = '/features'

    return axiosInstance.get(url)
}

export const getServiceById = (id) => {
    const url = '/features/' + id

    return axiosInstance.get(url)
}


import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const signIn = async (username, password) => {
  const url = 'https://api.kurrency.demo.e-makuta.com/api/authentication_token'
  return await axios.post(url, {
    username,
    password,
  })
}

export const createUser = async (
  personType,
  email,
  plainPassword,
  // platformId,
  displayName,
  phone
) => {
  const url = '/users'
  return await axiosInstance.post(
    url,
    {
      personType,
      email,
      plainPassword,
      // platformId,
      displayName,
      phone
    },
  )
}

export const updateUser = async (
  personType,
  email,
  // platformId,
  displayName,
  phone,
  id
) => {
  const url = '/users/' + id
  return await axiosInstance.put(
    url,
    {
      personType,
      email,
      // platformId,
      displayName,
      phone
    },
  )
}

export const getUsers = (token) => {
  const url = '/users'

  return axiosInstance.get(url)
}

export const getUser = (token) => {
  const url = 'https://api.kurrency.demo.e-makuta.com/api/users/about'

  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
}

export const getUserById = (id) => {
  const url = '/users/' + id

  return axiosInstance.get(url)
}

export const setProfile = (id, profile) => {
  const url = '/users/' + id + '/profiles'
  return axiosInstance.post(url, { profile: '/api/profiles/' + profile })
}

export const resetPassword = (oldPassword, newPassword, id) => {
  const url = `https://api.kurrency.demo.e-makuta.com/api/users/${id}/credentials`

  return axiosInstance.put(url,
    {
      actualPassword: oldPassword,
      newPassword: newPassword,
    }, {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }
  )
}

import React, { useState, useCallback, useEffect } from 'react'
import { createOperation, getOperation, getChannels, getMethods, getOperationTypes, updateOperation } from '../../../../api/operations'
import { getSchemas } from '../../../../api/schema'
import { getServices } from '../../../../api/services'
import Input from '../../../../component/Input'
import { useParams } from 'react-router-dom'
import MessagePanel from '../../../../component/Card/MessagePanel'
import { HiPencil } from 'react-icons/hi'
import { setChildVisible } from '../../../../helpers/roleManager'
import { useSelector } from 'react-redux'

function Add() {
    const { id } = useParams()
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [schemas, setSchemas] = useState([])
    const [methods, setMethods] = useState([])
    const [channels, setChannels] = useState([])
    const [types, setTypes] = useState([])
    const [services, setServices] = useState([])
    const [itShowEdit, setItShowEdit] = useState(false)

    const [operation, setOperation] = useState({
        type: "",
        method: "",
        channel: "",
        description: "",
        authorizationSchema: "",
        completionSchema: '',
        cancellationSchema: '',
        feature: ""
    })

    const resetOperation = () => {
        if (id === undefined) {
            setOperation({
                type: types[0].value,
                method: methods[0].value,
                channel: channels[0].value,
                description: "",
                authorizationSchema: schemas[0].value,
                completionSchema: '',
                cancellationSchema: '',
                feature: ""
            })
        } else {
            setItShowEdit(false)
        }
        setShowMessage(false)
    }

    const getOpsConfig = useCallback(async () => {
        try {
            await getChannels().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.id, label: el.label, default: true }) :
                        newArr.push({ value: el.id, label: el.label })
                })
                setChannels(newArr)
                id === undefined && setOperation((prev) => ({
                    ...prev,
                    channel: newArr[0].value
                }))
            })
            await getMethods().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.id, label: el.label, default: true }) :
                        newArr.push({ value: el.id, label: el.label })
                })
                setMethods(newArr)
                id === undefined && setOperation((prev) => ({
                    ...prev,
                    method: newArr[0].value
                }))
            })
            await getOperationTypes().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.id, label: el.label, default: true }) :
                        newArr.push({ value: el.id, label: el.label })
                })
                setTypes(newArr)
                id === undefined && setOperation((prev) => ({
                    ...prev,
                    type: newArr[0].value
                }))
            })

            await getSchemas().then(async (res) => {
                const result = res.data
                let newArr = [{ value: '', label: 'Aucun schema' }]
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.schemaId, label: el.code, default: true }) :
                        newArr.push({ value: el.schemaId, label: el.code })
                })
                setSchemas(newArr)
                id === undefined && setOperation((prev) => ({
                    ...prev,
                    authorizationSchema: newArr[0].value,
                    completionSchema: newArr[0].value,
                    cancellationSchema: newArr[0].value
                }))
            })

            await getServices().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el, i) => {
                    i === 0 ? newArr.push({ value: el.id, label: el.label, default: true }) :
                        newArr.push({ value: el.id, label: el.label })
                })
                setServices(newArr)
                id === undefined && setOperation((prev) => ({
                    ...prev,
                    feature: newArr[0].value
                }))
            })
        } catch (error) {
            console.log(error)
        }
    }, [id])

    const setCreateOperation = async () => {
        if (operation.type !== '' &&
            operation.authorizationSchema !== '' &&
            operation.method !== '' &&
            operation.channel !== '' &&
            operation.description !== ''
        ) {
            if (id !== undefined) {
                setIsLoading(true)
                setShowMessage(false)
                try {
                    await updateOperation(
                        `/api/operation_types/${operation.type}`,
                        `/api/methods/${operation.method}`,
                        `/api/channels/${operation.channel}`,
                        operation.description,
                        operation.authorizationSchema,
                        operation.completionSchema === '' ? null : operation.completionSchema,
                        operation.cancellationSchema === '' ? null : operation.cancellationSchema,
                        operation.feature,
                        id
                    )
                        .then((res) => {
                            resetOperation()
                            setIsLoading(false)
                            setMessage('l\' operation a été créer avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
            } else {
                setIsLoading(true)
                setShowMessage(false)
                try {
                    await createOperation(
                        `/api/operation_types/${operation.type}`,
                        `/api/methods/${operation.method}`,
                        `/api/channels/${operation.channel}`,
                        operation.description,
                        operation.authorizationSchema,
                        operation.completionSchema === '' ? null : operation.completionSchema,
                        operation.cancellationSchema === '' ? null : operation.cancellationSchema,
                        operation.feature,
                        // platform
                    )
                        .then((res) => {
                            resetOperation()
                            setIsLoading(false)
                            setMessage('l\' operation a été créer avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
            }
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const getOperationInfos = async () => {
        try {
            await getOperation(id)
                .then((res) => {
                    const result = res.data
                    setOperation((prev) => ({
                        ...prev,
                        type: result.type.slice('/api/operation_types/'.length),
                        method: result.method.slice('/api/methods/'.length),
                        channel: result.channel.slice('/api/channels/'.length),
                        description: result.description,
                        authorizationSchema: result.authorizationSchema,
                        completionSchema: result.completionSchema !== undefined ? result.completionSchema : '',
                        cancellationSchema: result.cancellationSchema !== undefined ? result.cancellationSchema : '',
                        feature: result.feature
                    }))
                    setIsLoad(true)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (id !== undefined && isLoad === false) {
            getOperationInfos()
        }
    })

    useEffect(() => {
        if (types.length <= 0 && channels.length <= 0 && methods.length <= 0) {
            getOpsConfig()
        }
    }, [channels, types, methods, getOpsConfig])
    return (
        <div className="h-full py-10 px-10">
            <div className='w-full flex flex-row min-h-[320px] gap-4 justify-center'>
                <div className='w-full border-2 px-5 py-5 rounded-md'>
                    {showMessage && (
                        <MessagePanel message={message} messageStatus={messageStatus} />
                    )}

                    <div className='flex flex-row justify-between'>
                        <h3 className='uppercase text-[24px] font-bold'>
                            {id !== undefined ? 'Modifier la configuration ' : 'Ajouter une configuration'}
                        </h3>
                    </div>
                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} select={true} selectItems={types} label={'Opérations'} id={'type'} value={operation.type} setValue={setOperation} />
                            </div>
                        </div>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} select={true} selectItems={methods} label={'Méthode'} id={'method'} value={operation.method} setValue={setOperation} />
                            </div>
                        </div>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} select={true} selectItems={channels} label={'Canal'} id={'channel'} value={operation.channel} setValue={setOperation} />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} select={true} selectItems={schemas} label={'Schéma comptable d’autorisation'} id={'authorizationSchema'} value={operation.authorizationSchema} setValue={setOperation} />
                            </div>
                        </div>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} select={true} selectItems={schemas} label={'Schéma comptable de clôture'} id={'completionSchema'} value={operation.completionSchema} setValue={setOperation} />
                            </div>
                        </div>
                        <div className='w-4/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} select={true} selectItems={schemas} label={'Schéma comptable d’annulation'} id={'cancellationSchema'} value={operation.cancellationSchema} setValue={setOperation} />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} placeholder={'Description'} label={'Description'} id={'description'} value={operation.description} setValue={setOperation} />
                            </div>
                        </div>
                        <div className='w-6/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined && !itShowEdit} select={true} selectItems={services} label={'Services'} id={'feature'} value={operation.feature} setValue={setOperation} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row gap-4 mt-4'>
                        {(id !== undefined) && (
                            itShowEdit ?
                                <button onClick={setCreateOperation} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                                    {isLoading ? 'Modification en cours...' : 'Modifier la configuration'}
                                </button>
                                :
                                <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_OPERATION_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`}>
                                    <HiPencil />
                                </button>
                        )}

                        {id === undefined && <button onClick={setCreateOperation} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                            {isLoading ? 'Ajout en cours...' : 'Ajouter une configuration'}
                        </button>}
                        <button onClick={resetOperation} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Add

import React from 'react'

function Footer() {
  return (
    <footer className="flex flex-col items-center w-full px-40 py-3 bg-gray-200">
      <main className="container flex flex-col items-center w-4/5">
        <p className="text-[#555] text-[14px]">Copyright © 2023 FENX.</p>
      </main>
    </footer>
  )
}

export default Footer

import React, { useState, useEffect } from 'react'
import { getCustomerById } from '../../api/customer'

function UserResume({ id }) {
    const [resume, setResume] = useState(null)
    const [isLoad, setIsLoad] = useState(false)

    const getUserResume = async () => {
        try {
            await getCustomerById(id)
                .then(({ data }) => {
                    setResume(data)
                    setIsLoad(true)
                })
        } catch (error) {
            console.log(error)
            setIsLoad(true)
        }
    }

    useEffect(() => {
        if (isLoad === false && resume === null) {
            getUserResume()
        }
    })

    return (
        (resume !== null && resume !== undefined) && (
            <ul>
                <li>Nom : {`${resume.firstName} ${resume.postName} ${resume.lastName}`} </li>
                <li>Nationalité : {resume.country}</li>
                <li>Genre : {resume.gender === 'M' ? 'Homme' : 'Femme'}</li>
                <li>Statut : {resume.status === 'V' ? 'Validé' : 'En attente'}</li>
            </ul>
        )
    )
}

export default UserResume

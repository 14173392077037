import React from 'react'

const PaiementTicket = React.forwardRef((props, ref) => {
    const data = props?.data
    const moment = require('moment')

    return (
        <div ref={ref}>
            <h3 className='text-[14px] font-medium mb-5'>
                Détail de la transaction
            </h3>

            <ul className='flex flex-col gap-1 text-[12px]'>
                <li>Date de l'Opération : {moment(data.dateOpération).format('DD-MM-Y à HH:MM')}</li>
                <li>Code caissier: {data.codeCaissier}</li>
                <li>Reference de l'opération : {data.refOperation}</li>
                <li>Credit/Debit : {data.operationType}</li>
                <li>Nom de l'agence : {data.agence}</li>
                <li>Numéro de compte : {data.accountNumber}</li>
                <li>Nom du compte : {data.accountName}</li>
                <li>Description : {data.description}</li>
                <li>Rate : {data.rate}</li>
                <li>Montant : {data.amount}</li>
                <li>Devise : {data.currency}</li>
            </ul>
        </div>
    );
});

export default PaiementTicket
// import axios from 'axios'
import axiosInstance from '../helpers/axiosInstance'

export const createOperation = async (
    type,
    method,
    channel,
    description,
    authorizationSchema,
    completionSchema,
    cancellationSchema,
    feature,
    // platformId,
) => {
    const url = '/operation_settings'
    return await axiosInstance.post(
        url,
        {
            type,
            method,
            channel,
            description,
            authorizationSchema,
            completionSchema,
            cancellationSchema,
            feature,
            // platformId
        },
    )
}

export const updateOperation = async (
    type,
    method,
    channel,
    description,
    authorizationSchema,
    completionSchema,
    cancellationSchema,
    feature,
    // platformId,
    id
) => {
    const url = '/operation_settings/' + id
    return await axiosInstance.put(
        url,
        {
            type,
            method,
            channel,
            description,
            authorizationSchema,
            completionSchema,
            cancellationSchema,
            feature,
            // platformId
        },
    )
}

export const createCashInCashOut = async (
    tellerAccountId,
    operatingAccount,
    operation,
    amount,
    currency,
    instrument
) => {
    const url = '/operations/cashin_cashouts'
    return await axiosInstance.post(
        url,
        {
            tellerAccountId,
            operatingAccount,
            operation,
            amount,
            currency,
            instrument
        },
    )
}

export const getOperations = (ops = false) => {
    let url = ''

    if (ops === true) {
        url = '/operation_settings?channel.channelId=BKAGT&type.code=DPST'
    } else {
        url = '/operation_settings'
    }

    return axiosInstance.get(url)
}

export const getMethods = () => {
    const url = '/methods'

    return axiosInstance.get(url)
}

export const getChannels = () => {
    const url = '/channels'

    return axiosInstance.get(url)
}

export const getOperationTypes = () => {
    const url = '/operation_types'

    return axiosInstance.get(url)
}

export const getOperation = (id) => {
    const url = '/operation_settings/' + id

    return axiosInstance.get(url)
}

import React, { useState, useEffect, useCallback } from 'react'
import MessagePanel from '../../../../component/Card/MessagePanel'
import Input from '../../../../component/Input'
import FileInput from "../../../../component/FileInput"
import { HiPencil } from "react-icons/hi"
import { FaImage } from "react-icons/fa"
import { useParams } from 'react-router-dom'
import { addPlatform, getPlatformById, addLogo } from '../.././../../api/platform'
import { beforeUpload, refresh } from '../../../../helpers/helper'
import Modal from '@mui/material/Modal';

const AddPlatform = () => {
    const { id } = useParams()
    const [plateforme, setPlateforme] = useState({
        name: '',
        mode: 'BANK',

        bankSwiftCode: "",
        bankCode: "",

        countryCode: "CD",

        address: "",
        state: "",
        city: "",

        phone: "",
        email: "",
        autoconfig: true,

        adminEmail: "",
        adminPlainPassword: "",
        adminDisplayName: "",

        supportedCurrencies: []
    })
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_, setIsLoading_] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [itEditing, setItEditing] = useState(false)
    const [selectedFile, setSelectedFile] = useState(false);

    const modes = [
        {
            label: "BANK",
            Value: "BANK"
        }
    ]

    const autoConfigs = [
        { label: 'Oui', value: true },
        { label: 'Non', value: true }
    ]

    const currencies = [
        { label: 'Dollars US', value: 'USD' },
        { label: 'Franc Congolais', value: 'CDF' },
        { label: 'Euro', value: 'EUR' },

    ]

    const toggleCurrency = (value, arr) => {
        let newArr = arr
        let res = arr.find(c => c === value)

        if (res === undefined) {
            newArr.push(value)
        } else {
            newArr = arr.filter(el => el !== value)

        }

        // console.log(newArr)
        setPlateforme((prev) => ({
            ...prev,
            supportedCurrencies: newArr
        }))
    }

    const isMatched = (value) => {
        return plateforme.supportedCurrencies.includes(value)
    }

    const toggleModal = () => {
        setShowModal(!showModal)
        setSelectedFile(false)
    }

    const setCreatePlateforme = async () => {
        try {
            if (
                plateforme.name !== '' &&
                plateforme.adminEmail !== '' &&
                plateforme.adminPlainPassword !== '' &&
                plateforme.supportedCurrencies.length > 0
            ) {
                setIsLoading(true)
                await addPlatform(plateforme)
                    .then(() => {
                        setMessageStatus(1)
                        setMessage('Plateforme créer avec success')
                        setShowMessage(true)
                        setIsLoading(false)
                    })
                    .catch((error) => console.log("Try :", error))
            } else {
                setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
                setMessageStatus(0)
                setShowMessage(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addLogoPlateforme = async () => {
        if (setSelectedFile !== false) {
            setIsLoading_(true)
            let validFile = beforeUpload(selectedFile)
            if (validFile) {
                try {
                    let data = new FormData()
                    data.append('file', selectedFile)
                    await addLogo(data, id).then(({ data }) => {
                        setMessage('Logo ajouter avec succès')
                        setMessageStatus(1)
                        setShowMessage(true)
                        setIsLoading_(false)
                        toggleModal()
                    })
                } catch (error) {
                    console.log("medias : =>", error)
                    setIsLoading_(false)
                }
            } else {
                setMessage(validFile)
                setMessageStatus(0)
                setShowMessage(true)
                setIsLoading_(false)
            }
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const getPlateformeInfos = useCallback(async () => {
        if (id !== undefined) {
            try {
                await getPlatformById(id)
                    .then(({ data }) => {
                        setPlateforme((prev) => ({
                            ...prev,
                            name: data?.name || '',
                            mode: data?.mode || 'BANK',
                            bankSwiftCode: data?.bankSwiftCode || '',
                            bankCode: data?.bankCode || '',
                            address: data?.address || '',
                            city: data?.city || '',
                            state: data?.state || ''
                        }))
                    })
                    .catch((error) => console.log("Try :", error))
            } catch (error) {
                console.log("Catch :", error)
            }
        }
    }, [id])


    useEffect(() => {
        if (id !== undefined && id !== null) {
            getPlateformeInfos()
        }
    }, [id, getPlateformeInfos])

    return (
        <div className="h-full py-10 px-10">
            <div className='w-full flex flex-row min-h-[320px] gap-4 justify-center'>
                <div className='w-full border-2 px-5 py-5 rounded-md'>

                    {showMessage && (
                        <MessagePanel message={message} messageStatus={messageStatus} />
                    )}

                    <h3 className='text-2xl mb-5 font-semibold uppercase' >{id !== undefined ? 'Information de la plateforme' : 'Ajouter une plateforme'}</h3>


                    <div className='flex flex-col gap-5'>

                        <div className='flex flex-row gap-4'>
                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Nom de la plateforme'}
                                placeholder="Nom de la plateforme"
                                id={'name'}
                                value={plateforme.name}
                                setValue={setPlateforme}
                            />

                            <Input
                                disabled={!itEditing && id !== undefined}
                                select={true}
                                selectItems={modes}
                                required={true}
                                label={'Mode'}
                                id={'mode'}
                                value={plateforme.mode}
                                setValue={setPlateforme}
                            />
                        </div>

                        <div className='flex flex-row gap-4'>
                            <Input
                                disabled={!itEditing && id !== undefined}
                                label={'Swift-Code'}
                                placeholder="Swift-Code"
                                id={'bankSwiftCode'}
                                value={plateforme.bankSwiftCode}
                                setValue={setPlateforme}
                            />

                            <Input
                                disabled={!itEditing && id !== undefined}
                                label={'Code'}
                                placeholder="Code"
                                id={'bankCode'}
                                value={plateforme.bankCode}
                                setValue={setPlateforme}
                            />
                            {/* <Input
                                disabled={!itEditing && plateforme.id !== undefined}
                                label={'Nationalité'}
                                value={plateforme.countryCode}
                                setValue={setPlateforme}
                                id={'country'}
                                select={true}
                                selectItems={countryCode}
                            /> */}
                        </div>

                        <div className='flex flex-row gap-4'>
                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Adresse'}
                                placeholder="Adresse"
                                id={'address'}
                                value={plateforme.address}
                                setValue={setPlateforme}
                            />

                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Ville / Commune'}
                                placeholder={'Ville / Commune'}
                                id={'state'}
                                value={plateforme.state}
                                setValue={setPlateforme}
                            />

                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Province / Ville'}
                                placeholder={'Province / Ville'}
                                id={'city'}
                                value={plateforme.city}
                                setValue={setPlateforme}
                            />
                        </div>

                        <div className='flex flex-row gap-4'>
                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Numéro de téléphone'}
                                placeholder="Téléphone"
                                id={'phone'}
                                value={plateforme.phone}
                                setValue={setPlateforme}
                            />

                            <Input
                                disabled={!itEditing && id !== undefined}
                                required={true}
                                label={'Adresse électronique'}
                                placeholder={'Email'}
                                id={'email'}
                                value={plateforme.email}
                                setValue={setPlateforme}
                            />

                            {
                                id === undefined &&
                                <Input
                                    disabled={!itEditing && id !== undefined}
                                    required={true}
                                    label={'Configuration'}
                                    select={true}
                                    selectItems={autoConfigs}
                                    id={'autoconfig'}
                                    value={plateforme.autoconfig}
                                    setValue={setPlateforme}
                                />
                            }
                        </div>



                        {
                            id === undefined && (
                                <>
                                    <div className='flex flex-row gap-4'>
                                        <Input
                                            disabled={!itEditing && id !== undefined}
                                            required={true}
                                            label={"Email de l'administrateur"}
                                            placeholder="Email"
                                            id={'adminEmail'}
                                            value={plateforme.adminEmail}
                                            setValue={setPlateforme}
                                        />

                                        <Input
                                            disabled={!itEditing && id !== undefined}
                                            required={true}
                                            label={"Mot de passe de l'administrateur"}
                                            placeholder={'Mot de passe'}
                                            id={'adminPlainPassword'}
                                            value={plateforme.adminPlainPassword}
                                            setValue={setPlateforme}
                                        />

                                        <Input
                                            disabled={!itEditing && id !== undefined}
                                            required={true}
                                            label={"Nom de l'administrateur"}
                                            placeholder={'Nom complet'}
                                            id={'adminDisplayName'}
                                            value={plateforme.adminDisplayName}
                                            setValue={setPlateforme}
                                        />
                                    </div>



                                    <div className='flex flex-row gap-4 items-center'>
                                        <label htmlFor="">Sélectionner la(les) devises : </label>
                                        {
                                            currencies.map((item, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => toggleCurrency(item.value, plateforme.supportedCurrencies)}
                                                    className={`p-2 rounded-md cursor-pointer`}
                                                    style={
                                                        isMatched(item.value) ?
                                                            {
                                                                backgroundColor: '#0064ff',
                                                                color: '#fff'
                                                            }
                                                            :
                                                            {
                                                                backgroundColor: '#F3F4F6'
                                                            }
                                                    }
                                                >
                                                    {item.label}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            )
                        }

                        {id !== undefined && <div className='flex flex-row gap-4 items-center'>
                            <button onClick={() => setShowModal(true)} className="py-2 px-5 bg-[#0064FF] flex-row flex items-center gap-3 text-white rounded-md">
                                <FaImage />
                                Ajouter le logo
                            </button>
                        </div>}

                        <div className='flex flex-row gap-4'>
                            {(id !== undefined) && (itEditing ?
                                <button className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier la plateforme'} </button>
                                :
                                <button onClick={() => setItEditing(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>
                                    <HiPencil />
                                </button>)
                            }

                            {id === undefined &&
                                <button onClick={setCreatePlateforme} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter une plateforme'} </button>}
                            <button onClick={refresh} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <label htmlFor="">Logo : </label>
                            <FileInput setValue={setSelectedFile} />

                            <div className='flex flex-row gap-4 mt-2'>

                                <button onClick={() => addLogoPlateforme()} className={`py-2 px-10 rounded-md bg-[#0064FF] text-white`} disabled={isLoading_}>
                                    {isLoading_ ? 'Ajout en cours...' : 'Ajouter un logo'}
                                </button>

                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#000064]'>Fermer</button>
                            </div>

                        </div>

                    </div>
                </div>


            </Modal>
        </div>
    )
}

export default AddPlatform
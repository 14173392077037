import React from 'react'
import { Routes, Route } from 'react-router-dom'

import MiddleWare from './middleware'
import Auth from './pages/layout/auth'
import Dashboard from './pages/layout/dashboard'
import Login from './pages/auth/Login'
import HomeDashboard from './pages/dashboard/Home'
import Users from './pages/dashboard/Users'
import Employes from './pages/dashboard/Employes'
import Profiles from './pages/dashboard/Profiles'
import Affectation from './pages/dashboard/Affectation'
import Services from './pages/dashboard/Services'
import AccountType from './pages/dashboard/AccountType'
import Products from './pages/dashboard/Products'
import AddProduct from './pages/dashboard/Products/Add'
import Client from './pages/dashboard/Clients'
import AddClient from './pages/dashboard/Clients/Add'
import Contract from './pages/dashboard/Contracts'
import Account from './pages/dashboard/Account'
import Transactions from './pages/dashboard/Transactions'
import Schema from './pages/dashboard/Schema'
import AddSchema from './pages/dashboard/Schema/Add'
import Error404 from './pages/error/error404'
import Operations from './pages/dashboard/Operations'
import AddOperations from './pages/dashboard/Operations/Add'
import CashInCashOut from './pages/dashboard/CashInCashOut'
import Entries from './pages/dashboard/Account/Entries'
import UserAccount from './pages/dashboard/UserAccount'
import Plateforme from './pages/dashboard/Plateforme'
import AddPlatform from "./pages/dashboard/Plateforme/Add"
import Partitions from './pages/dashboard/Partitions'

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<Auth />} >
                <Route index element={<Login />} />
            </Route>

            <Route path='/dashboard' element={<MiddleWare />}>
                <Route element={<Dashboard />} >
                    <Route path='' index element={<HomeDashboard />} />
                    <Route path='plateformes' element={<Plateforme />} />
                    <Route path='add-plateformes' element={<AddPlatform />} />
                    <Route path='add-plateformes/:id' element={<AddPlatform />} />
                    <Route path='partitions' element={<Partitions />} />
                    <Route path='users-manager' element={<Users />} />
                    <Route path='employes-manager' element={<Employes />} />
                    <Route path='profiles-manager' element={<Profiles />} />
                    <Route path='affectation-manager' element={<Affectation />} />
                    <Route path='services-manager' element={<Services />} />
                    <Route path='account-manager' element={<Account />} />
                    <Route path='entries-manager/:id' element={<Entries />} />
                    <Route path='account-type-manager' element={<AccountType />} />
                    <Route path='product-manager' element={<Products />} />
                    <Route path='add-product-manager' element={<AddProduct />} />
                    <Route path='add-product-manager/:id' element={<AddProduct />} />
                    <Route path='client-manager' element={<Client />} />
                    <Route path='add-client-manager' element={<AddClient />} />
                    <Route path='add-client-manager/:id' element={<AddClient />} />
                    <Route path='contract-manager' element={<Contract />} />
                    <Route path='transactions-manager' element={<Transactions />} />
                    <Route path='schema-manager' element={<Schema />} />
                    <Route path='schema-manager-add' element={<AddSchema />} />
                    <Route path='schema-manager-add/:id' element={<AddSchema />} />
                    <Route path='operation-manager' element={<Operations />} />
                    <Route path='operation-manager-add' element={<AddOperations />} />
                    <Route path='operation-manager-add/:id' element={<AddOperations />} />
                    <Route path='cash-manager' element={<CashInCashOut />} />
                    <Route path='user-account' element={<UserAccount />} />
                </Route>
            </Route>

            <Route path="*" element={<Error404 />} />
        </Routes >
    )
}

export default App
import React, { useState, useEffect, useCallback } from 'react'
import Input from '../Input'

function SearchText({ value, initialArray, setArrayFiltered, setLoader, label = "Recherche", required = false, defaultValue = null, setDefaultValue, disabled = false }) {
    const [filter, setFilter] = useState({
        searchText: '',
    })

    const filterArrayBySearchText = useCallback(async (value) => {
        setLoader(true)
        let newArray = []
        await initialArray.forEach((elKeys) => {
            for (const prop in elKeys) {
                let element = elKeys[prop]
                if (element.toString().toLowerCase().includes(value.toString().toLowerCase())) {
                    if (newArray.length === 0) {
                        newArray.push(elKeys)
                    } else {
                        let existEl = false
                        newArray.forEach((item) => {
                            if (item.id === undefined) {
                                if (item.authorizationId === elKeys.authorizationId) existEl = true
                            } else {
                                if (item.id === elKeys.id) existEl = true
                            }
                        })
                        if (existEl === false) newArray.push(elKeys)
                    }
                }
            }
        })
        setArrayFiltered(newArray.slice(0, 5))
        if (newArray.length <= 0) setLoader(false)
    }, [initialArray, setArrayFiltered, setLoader])

    useEffect(() => {
        if (filter.searchText !== '' && filter.searchText !== ' ') {
            filterArrayBySearchText(filter.searchText)
        } else {
            setArrayFiltered(initialArray)
            setLoader(false)
        }
    }, [filter.searchText, filterArrayBySearchText, setArrayFiltered, initialArray, setLoader])

    useEffect(() => {
        if (defaultValue !== null && defaultValue !== undefined && defaultValue !== '') {
            setFilter((prev) => ({
                ...prev,
                searchText: defaultValue
            }))
            setDefaultValue(null)
        }
    }, [defaultValue, setDefaultValue])
    return (
        <div>
            <Input label={label} required={required} placeholder={value !== undefined ? value : label} value={filter.searchText} id={'searchText'} setValue={setFilter} disabled={disabled} />
        </div>
    )
}

export default SearchText
import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from "../../../component/Input"
import SearchText from '../../../component/SearchText'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getProfils, createProfil, getPermissions, updateProfil } from '../../../api/profil'
import MessagePanel from '../../../component/Card/MessagePanel'
import { HiEye, HiPencil } from "react-icons/hi"
import { refresh } from '../../../helpers/helper'

function Profile() {
  const [filter, setFilter] = useState({
    searchText: '',
    status: 1,
    itemsPerPage: 10
  })
  const [profils, setProfils] = useState([])
  const [profilsFiltered, setProfilsFiltered] = useState([])
  const [currentItems, setCurrentItems] = useState([])
  const [itemsPerPageArray, setItemsPerPageArray] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [personType] = useState('EMP')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [profil, setProfil] = useState({
    name: '',
    permissions: []
  })
  const [itShowEdit, setItShowEdit] = useState(false)

  const [permissionsList, setPermissionsList] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState()
  const [messageStatus, setMessageStatus] = useState(0)

  const setProfilePermission = (val) => {
    let newArr = profil.permissions
    let arrFiltered = []

    if (newArr.length <= 0) {
      newArr.push(val)
      arrFiltered = newArr
    } else {
      arrFiltered = newArr.filter((item) => item === val)
      if (arrFiltered.length === 1) {
        arrFiltered = newArr.filter((item) => item !== val)
      } else {
        newArr.push(val)
        arrFiltered = newArr
      }
    }

    setProfil((prev) => ({
      ...prev,
      permissions: arrFiltered
    }))
  }

  const getProfilsList = useCallback(async () => {
    setIsLoading(true)
    try {
      await getProfils().then((response) => {
        const result = response.data
        setProfils(result)
        setProfilsFiltered(result)
        setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
        let arrayItemsPerPage = []
        for (let i = 1; i <= result.length; i++) {
          if (i === 0) {
            arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
          } else {
            arrayItemsPerPage.push({ value: i, label: i.toString() })
          }
        }
        setItemsPerPageArray(arrayItemsPerPage)
        setIsLoad(true)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoad(true)
      setIsLoading(false)
    }
  }, [filter.itemsPerPage])

  const setCreateProfile = async () => {
    if (profil.name !== '' && profil.permissions.length > 0) {
      setIsLoading(true)
      if (profil.id !== undefined && profil.id !== null) {
        try {
          await updateProfil(
            profil.name,
            profil.permissions,
            personType,
            profil.id)
            .then(() => {
              setShowMessage(true)
              setMessageStatus(1)
              setMessage("Le profil a été modifier avec succès")
              getProfilsList()
              setIsLoading(false)
              setItShowEdit(false)
            })
        } catch (error) {
          console.log(error)
          setIsLoading(false)
        }
      } else {
        try {
          await createProfil(
            profil.name,
            profil.permissions,
            personType,
          )
            .then(() => {
              getProfilsList()
              setShowModal(false)
              setIsLoading(false)
            })
        } catch (error) {
          console.log(error)
          setIsLoading(false)
        }
      }
    } else {
      setShowMessage(true)
      setMessageStatus(0)
      setMessage(
        'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
      )
    }
  }

  const editShowItem = (item) => {
    setShowModal(true)
    setProfil((prev) => ({
      ...prev,
      id: item.id,
      name: item.name,
      permissions: item.permissions
    }))
  }

  const getPermissionsList = useCallback(async () => {
    try {
      if (permissionsList.length <= 0) {
        await getPermissions().then((response) => {
          const result = response.data
          let newArr = []
          result.forEach((el, i) => {
            newArr.push({ value: el.role, label: el.label })
          });
          console.log(newArr)
          setPermissionsList(newArr)
        })
      }
    } catch (error) {
      console.error(error)
    }
  }, [permissionsList])

  const headers = [
    "id",
    "Nom",
    // "Nombre d'utilisateur",
    "Statut",
    "Action"
  ]

  const statusItems = [
    {
      label: "Tout",
      value: 0,
      default: true
    },
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Non-active",
      value: 2,
    }
  ]

  const toggleModal = () => {
    setShowModal(!showModal)
    setItShowEdit(false)
    setProfil({
      name: '',
      permissions: [],
      id: undefined
    })
    setShowMessage(false)
  }

  useEffect(() => {
    if (profils.length === 0 && isLoad === false) {
      getProfilsList()
    }
  }, [profils, getProfilsList, isLoad])

  useEffect(() => {
    if (permissionsList.length === 0) {
      getPermissionsList()
    }
  }, [permissionsList, getPermissionsList])

  return (
    <div className="flex flex-col w-full h-full px-10 py-10">
      <div className='flex flex-row'>
        <div className='w-full'>
          <div className='flex flex-row justify-between mb-4'>
            <div className='flex-col flex gap-2 w-6/12'>
              <div>
                <SearchText initialArray={profils} setArrayFiltered={setProfilsFiltered} setLoader={setIsLoading} />
              </div>
              <div className='flex-row flex gap-3'>
                <div className='w-6/12'>
                  <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                </div>
                <div className='w-6/12'>
                  <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                </div>

              </div>
            </div>
            <div className='flex flex-row gap-2 justify-center items-end'>
              <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
              <button onClick={() => toggleModal()} className='px-5 py-2 border-2 rounded-md text-[14px]'>Ajouter un profil</button>
            </div>
          </div>
          <ModelOne headers={headers} arrayItems={profilsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
            {profilsFiltered.length > 0 ?
              currentItems.map((item, index) => (
                <tr key={index} className="border-b-2 border-gray-300">
                  <td className='p-3'>{item.id}</td>
                  <td className='p-3'>{item.name}</td>
                  {/* <td className='p-3'>{item.permissions.length}</td> */}
                  <td className='p-3'>{item.active ? "Active" : "Non-Active"}</td>
                  <td className='flex flex-row items-center justify-center p-3 gap-2'>
                    <button className='text-[16px] px-5 py-2 border-2 rounded-md'>
                      <HiEye onClick={() => editShowItem(item)} />
                    </button>
                  </td>
                </tr>
              )) : (
                <tr className="">
                  <td
                    colSpan="10"
                    className="p-5 text-center text-gray-700 text-md"
                  >
                    {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                  </td>
                </tr>
              )}
          </ModelOne>
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className='w-full h-full flex-col flex items-center justify-center'>
          <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

            <div className='flex flex-col gap-3'>
              {showMessage && (
                <MessagePanel message={message} messageStatus={messageStatus} />
              )}

              <h3 className='text-2xl mb-2 font-semibold uppercase' >{profil.id !== undefined ? 'Information du profile' : 'Ajouter un Profile'}</h3>
              <div>
                <Input disabled={!itShowEdit && profil.id !== undefined} required={true} placeholder="Nom du profile" label={'Nom du profile'} value={profil.name} id={'name'} setValue={setProfil} />
              </div>
              <label htmlFor="role" className='font-semibold'>{'Sélectionner les permissions(*) :'}</label>
              <div className='grid grid-cols-3 gap-4 h-[350px] overflow-auto mb-4 mt-1 border-2 p-5' id='role'>
                {permissionsList.map((el, i) => (
                  <div onClick={() => setProfilePermission(el.value)} className='flex flex-col gap-2' key={i}>
                    <label htmlFor={i.toString()}>{el.label}</label>
                    <input disabled={!itShowEdit && profil.id !== undefined} type="checkbox" id={i.toString()} defaultChecked={profil.permissions.includes(el.value)} />
                  </div>
                ))}
              </div>

              <div className='flex flex-row gap-2'>
                {(profil.id !== undefined) &&
                  (itShowEdit ?
                    <button onClick={() => setCreateProfile()} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                      {isLoading ?
                        'Modification en cours ..' :
                        'Modifier le profil'
                      }
                    </button>
                    :
                    <button onClick={() => setItShowEdit(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                      <HiPencil />
                    </button>)
                }

                {profil.id === undefined && <button onClick={() => setCreateProfile()} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                  {isLoading ?
                    'Ajout en cours ..' :
                    'Ajouter un profil'
                  }
                </button>}
                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
              </div>
            </div>

          </div>
        </div>

      </Modal>
    </div>
  )
}

export default Profile

import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, HiPencil } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { getAccountTypes, createAccountType, updateAccountType } from '../../../api/accountTypes'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import MessagePanel from '../../../component/Card/MessagePanel'
import { refresh } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'
import { useSelector } from 'react-redux'

function AccountType() {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [accountType, setAccountType] = useState({
        code: '',
        label: '',
        applyMaintenanceFee: false,
        supportInterest: false,
        interestRate: 0,
        maintenanceFee: 0,
        inactiveMaintenanceFee: 0,
    })

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [types, setTypes] = useState([])
    const [typesFiltered, setTypesFiltered] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])

    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const headers = [
        "Id",
        "Code",
        "label",
        "Devise",
        'status',
        setChildVisible(user?.roles, ['ROLE_ACCOUNT_TYPE_DETAILS']) === true && "Action"
    ]

    const statusItems = [
        {
            label: "Tout",
            value: 0,
            default: true
        },
        {
            label: "Active",
            value: 1,
        },
        {
            label: "Non-active",
            value: 2,
        }
    ]

    const resetAccountType = useCallback(() => {
        setAccountType((prev) => ({
            ...prev,
            id: undefined,
            code: '',
            label: '',
            applyMaintenanceFee: false,
            supportInterest: false,
            interestRate: 0,
            maintenanceFee: 0,
            inactiveMaintenanceFee: 0,
        }))
    }, [])

    const toggleModal = useCallback(() => {
        resetAccountType()
        setShowModal(!showModal)
        setItShowEdit(false)
        setShowMessage(false)
    }, [showModal, resetAccountType])

    const getAccountTypesList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getAccountTypes().then(({ data }) => {
                const result = data

                setTypes(result)
                setTypesFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })

        } catch (error) {
            const errorMessage = error.response.data.detail
            console.log(errorMessage)
            setIsLoading(false)
        }
    }, [filter.itemsPerPage])

    const setCreateAccountType = useCallback(async () => {

        if (accountType.code !== '' && accountType.label !== '') {
            setIsLoading(true)
            if (accountType.id !== undefined && accountType.id !== null) {
                // console.log(accountType.id)
                try {
                    await updateAccountType(
                        accountType.code,
                        accountType.label,
                        accountType.applyMaintenanceFee === 'true' ? true : false,
                        accountType.supportInterest === 'true' ? true : false,
                        accountType.interestRate.toString(), accountType.maintenanceFee.toString(),
                        accountType.inactiveMaintenanceFee.toString(),
                        accountType.id
                    )
                        .then(({ data }) => {
                            // console.log(data)
                            getAccountTypesList()
                            setShowMessage(true)
                            setMessageStatus(1)
                            setMessage("Type de compte modifier avec succès")
                            setIsLoading(false)
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            } else {
                try {
                    await createAccountType(
                        accountType.code,
                        accountType.label,
                        accountType.applyMaintenanceFee === 'true' ? true : false,
                        accountType.supportInterest === 'true' ? true : false,
                        accountType.interestRate.toString(), accountType.maintenanceFee.toString(),
                        accountType.inactiveMaintenanceFee.toString()
                    )
                        .then((res) => {
                            getAccountTypesList()
                            setShowMessage(true)
                            setMessageStatus(1)
                            setMessage("Type de compte créer avec succès")
                            setIsLoading(false)
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            }
        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }, [getAccountTypesList, accountType])

    const editShowItem = (item) => {
        setShowModal(true)
        setAccountType((prev) => ({
            ...prev,
            id: item.id,
            code: item.code,
            label: item.label,
            applyMaintenanceFee: item.applyMaintenanceFee,
            supportInterest: item.supportInterest,
            interestRate: item.interestRate,
            maintenanceFee: item.maintenanceFee,
            inactiveMaintenanceFee: item.inactiveMaintenanceFee,
        }))
    }

    const booleanArr = [
        { value: true, label: "OUI" },
        { value: false, label: "NON" }
    ]

    useEffect(() => {
        if (types.length === 0 && !isLoad) {
            getAccountTypesList()
        }
    }, [isLoad, types, getAccountTypesList])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-6/12'>
                            <div>
                                <SearchText initialArray={types} setArrayFiltered={setTypesFiltered} setLoader={setIsLoading} />
                            </div>
                            <div className='flex-row flex gap-3'>
                                <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div>
                                <div className='w-6/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={toggleModal} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_TYPE_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un type de compte</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={typesFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {typesFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='p-3'>{item.label}</td>
                                    <td className='p-3'>{item.currency}</td>
                                    <td className='p-3'>{!item.locked ? 'Active' : "Non-active"}</td>
                                    <td className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_TYPE_DETAILS']) === true ? 'flex flex-row items-center justify-center p-3 gap-2' : 'hidden'}`}>
                                        <button onClick={() => editShowItem(item)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{accountType.id !== undefined ? 'Information du type de compte' : 'Ajouter un type de compte'}</h3>
                            <div>
                                <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Code service'} placeholder="Code" id={'code'} value={accountType.code} setValue={setAccountType} />
                            </div>
                            <div>
                                <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Service label'} placeholder="Label" id={'label'} value={accountType.label} setValue={setAccountType} />
                            </div>

                            <div>
                                <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Frais de maintenance'} selectItems={booleanArr} id={'applyMaintenanceFee'} value={accountType.applyMaintenanceFee} setValue={setAccountType} select={true} />
                            </div>

                            {(accountType.applyMaintenanceFee === true || accountType.applyMaintenanceFee === 'true') &&
                                <div className='flex flex-row gap-2'>
                                    <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Maintenance active'} placeholder="montant" id={'maintenanceFee'} value={accountType.maintenanceFee} setValue={setAccountType} />
                                    <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Maintenance  inactive'} placeholder="montant" id={'inactiveMaintenanceFee'} value={accountType.inactiveMaintenanceFee} setValue={setAccountType} />
                                </div>
                            }

                            <div>
                                <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={'Frais d\' intérêt'} selectItems={booleanArr} id={'supportInterest'} value={accountType.supportInterest} setValue={setAccountType} select={true} />
                            </div>

                            {(accountType.supportInterest === true || accountType.supportInterest === 'true') &&
                                <div className='flex flex-row gap-2'>
                                    <Input disabled={!itShowEdit && accountType.id !== undefined} required={true} label={"Taux d’intérêt"} placeholder="Taux d’intérêt" id={'interestRate'} value={accountType.interestRate} setValue={setAccountType} />
                                </div>
                            }

                            <div className='flex flex-row gap-4'>
                                {(accountType.id !== undefined) &&
                                    (itShowEdit ?
                                        <button onClick={setCreateAccountType} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Modification en cours...' : 'Modifier le type de compte'} </button>
                                        :
                                        <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_ACCOUNT_TYPE_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`}>
                                            <HiPencil />
                                        </button>
                                    )
                                }

                                {accountType.id === undefined && <button onClick={setCreateAccountType} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white' disabled={isLoading}>{isLoading ? 'Ajout en cours...' : 'Ajouter un type de compte'} </button>}
                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default AccountType

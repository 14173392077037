import React, { useState } from 'react'
import MessagePanel from '../../../component/Card/MessagePanel'
import { signIn, getUser } from '../../../api/auth'
import { useDispatch } from 'react-redux'
import { Login as LoginUser } from '../../../store/actions/authActions'
import jwt from 'jwt-decode'
import Input from '../../../component/Input'
import logo from '../../../assets/images/b-logo.png'

function Login() {
  const [user, setUser] = useState({
    userName: '',
    password: ''
  })
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState()
  const messageStatus = 0
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const auth = async () => {
    setShowMessage(false)
    if (user.userName !== '' && user.password !== '') {
      setIsLoading(true)
      try {
        await signIn(user.userName, user.password)
          .then(async ({ data }) => {
            const token = data.token
            const refreshToken = data.refresh_token

            await getUser(token)
              .then(({ data }) => {
                const user = jwt(token);
                let result = data
                result.roles = user.roles

                localStorage.setItem('token', token)
                localStorage.setItem('refreshToken', refreshToken)

                // console.log(result)
                dispatch(LoginUser(token, result))
                setIsLoading(false)

                window.location.replace('/dashboard')
              })
              .catch((error) => {
                console.log(error)
                setIsLoading(false)
              })
          })
          .catch((err) => {
            setShowMessage(true)
            setMessage(
              'Adresse email ou/et mot de passe incorrect verifier vos informations de connexion '
            )
            console.log(err.message)
            setIsLoading(false)
          })
      } catch (error) {
        console.log(error.message)
        setIsLoading(false)
      }
    } else {
      setShowMessage(true)
      setMessage(
        'Veillez renseignez vos information avant de vous connectez !!'
      )
    }
  }

  return (
    <div className="flex flex-col">

      {showMessage && (
        <MessagePanel message={message} messageStatus={messageStatus} />
      )}

      <div className='mb-5 flex justify-center items-center flex-col md:px-10'>
        <p className={`px-5 my-5`}>
          <img src={logo} alt={'logo-banky'} className='h-[200px]' />
        </p>
        <h3 className="font-sans text-xl font-bold text-center uppercase text-neutral-800">
          Connexion
        </h3>
        {/* <small className='text-center md:mx-5 my-2'>Entrer vos informations de connexion pour vous connectez</small> */}
      </div>

      <div className="flex flex-col items-center justify-center w-full gap-4">
        <div className="w-full md:w-10/12">
          <Input
            placeholder={"Nom d'utilisateur"}
            value={user.userName}
            setValue={setUser}
            id={'userName'}
            type="text"
            label={"Nom d'utilisateur"}
          />
        </div>

        <div className="w-full md:w-10/12">
          <Input
            placeholder={"Mot de passe"}
            value={user.password}
            id={'password'}
            setValue={setUser}
            type="password"
            label={"Mot de passe"}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full mt-5">
        <button
          onClick={auth}
          type="button"
          className="py-2 md:px-10 px-2 md:w-10/12 w-full rounded-md bg-[#0064FF] text-white hover:bg-[#0064ff]"
        >
          {isLoading ? 'Connexion en cours ...' : 'Se connecter'}
        </button>
      </div>
    </div>
  )
}

export default Login

import React, { useState, useEffect } from 'react'
import { createSchema, getSchema, updateSchema } from '../../../../api/schema'
import Input from '../../../../component/Input'
import ModelTwo from '../../../../component/Table/ModelTwo'
import { useParams } from 'react-router-dom'
import Modal from '@mui/material/Modal';
import MessagePanel from '../../../../component/Card/MessagePanel'
import { setName, removeItem } from '../../../../helpers/helper'
import { HiEye, HiTrash, HiPencil } from 'react-icons/hi'

function Add() {
    const { id } = useParams()
    const [showModal, setShowModal] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [itShowEdit, setItShowEdit] = useState(false)
    const [computabilitySetting, setComputabilitySetting] = useState({
        amount: "true",
        currency: "true",
        direction: "false",
        label: "true",
        account: "true",
    })

    const [instruction, setInstruction] = useState({
        amount: "",
        currency: "",
        direction: "",
        account: "",
        label: "",
    })

    const [schema, setSchema] = useState({
        code: "",
        instructions: []
    })

    const currencies = [
        { value: 'USD', label: 'US Dollar' },
        { value: 'CDF', label: 'FC Congolais' },
    ]

    const directions = [
        { value: 'D', label: 'Débit' },
        { value: 'C', label: 'Crédit' },
    ]

    const configs = [
        { value: "true", label: 'Variable' },
        { value: "false", label: 'Donnée brute' }
    ]

    const editShowItem = (item, index) => {
        setShowModal(true)
        setInstruction({
            amount: item.amount,
            currency: item.currency,
            direction: item.direction,
            account: item.wallet !== undefined ? item.wallet : item.account,
            label: item.label,
            id: index
        })

        const computability = item.computability !== undefined ? item.computability : item.computabilitySettings

        setComputabilitySetting({
            amount: computability.amount,
            currency: computability.currency,
            direction: computability.direction,
            label: computability.label,
            account: computability.account,
        })
    }

    const resetSchema = () => {
        if (id === undefined) {
            setSchema({
                code: "",
                instructions: []
            })
        } else {
            setItShowEdit(false)
        }
    }

    const toggleModal = () => {
        setShowModal(!showModal)
        setShowMessage(false)

        setInstruction({
            amount: "",
            currency: "USD",
            direction: "D",
            account: "",
            label: "",
            computabilitySettings: {},
            id: null
        })

        setComputabilitySetting({
            amount: "true",
            currency: "true",
            direction: "false",
            label: "true",
            account: "true",
        })
    }

    const setCreateSchema = async () => {
        if (schema.code !== '' && schema.instructions.length > 0) {
            setIsLoading(true)

            if (id !== undefined && id !== null) {
                try {
                    let instructionsFromApi = schema.instructions
                    let instructionsFromApi_ = []

                    // console.log(instructionsFromApi)

                    instructionsFromApi.forEach((el) => {
                        instructionsFromApi_.push({
                            amount: el.amount,
                            currency: el.currency,
                            direction: el.direction,
                            account: el.computability !== undefined ? el.wallet : el.account,
                            label: el.label,
                            computabilitySettings: {
                                amount: el.computability !== undefined ? el.computability.amount : el.computabilitySettings.amount,
                                currency: el.computability !== undefined ? el.computability.currency : el.computabilitySettings.currency,
                                direction: el.computability !== undefined ? el.computability.direction : el.computabilitySettings.direction,
                                label: el.computability !== undefined ? el.computability.label : el.computabilitySettings.label,
                                account: el.computability !== undefined ? el.computability.account : el.computabilitySettings.account
                            }
                        })
                    })

                    await updateSchema(schema.code, instructionsFromApi_, id)
                        .then(() => {
                            setIsLoading(false)
                            setMessage('le schema a été modifier avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    await createSchema(
                        // platform, 
                        schema.code,
                        schema.instructions
                    )
                        .then(() => {
                            setIsLoading(false)
                            setMessage('le schema a été créer avec succès')
                            setMessageStatus(1)
                            setShowMessage(true)
                        })
                } catch (error) {
                    console.log(error)
                }
            }

        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const getSchemaInfos = async () => {
        try {
            await getSchema(id)
                .then(({ data }) => {
                    const result = data
                    setSchema(result)
                    setIsLoad(true)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const AddInstruction = async () => {
        if (instruction.amount !== '' && instruction.account !== '' && instruction.label !== '') {
            setIsLoading(true)
            if (instruction.id !== undefined && instruction !== null) {
                let newArr = schema.instructions
                newArr[instruction.id] = {
                    amount: instruction.amount,
                    currency: instruction.currency,
                    direction: instruction.direction,
                    account: instruction.account,
                    label: instruction.label,
                    computabilitySettings: {
                        amount: computabilitySetting.amount === "true" || computabilitySetting.amount === true ? true : false,
                        currency: computabilitySetting.currency === "true" || computabilitySetting.currency === true ? true : false,
                        direction: computabilitySetting.direction === "true" || computabilitySetting.direction === true ? true : false,
                        label: computabilitySetting.label === "true" || computabilitySetting.label === true ? true : false,
                        account: computabilitySetting.account === "true" || computabilitySetting.account === true ? true : false,
                    }
                }
                setSchema((prev) => ({
                    ...prev,
                    instructions: newArr,
                }))
            } else {
                let newArr = schema.instructions
                newArr.push({
                    amount: instruction.amount,
                    currency: instruction.currency,
                    direction: instruction.direction,
                    account: instruction.account,
                    label: instruction.label,
                    computabilitySettings: {
                        amount: computabilitySetting.amount === "true" || computabilitySetting.amount === true ? true : false,
                        currency: computabilitySetting.currency === "true" || computabilitySetting.currency === true ? true : false,
                        direction: computabilitySetting.direction === "true" || computabilitySetting.direction === true ? true : false,
                        label: computabilitySetting.label === "true" || computabilitySetting.label === true ? true : false,
                        account: computabilitySetting.account === "true" || computabilitySetting.account === true ? true : false,
                    }
                })
                setSchema((prev) => ({
                    ...prev,
                    instructions: newArr,
                }))
            }

            setIsLoading(false)
            toggleModal()
        } else {
            setMessage('Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire')
            setMessageStatus(0)
            setShowMessage(true)
        }
    }

    const removeInstructionItem = (index) => {
        let newArr = removeItem(schema.instructions, index)
        setSchema((prev) => ({
            ...prev,
            instructions: newArr
        }))
    }


    useEffect(() => {
        if (id !== undefined && isLoad === false) {
            getSchemaInfos()
        }
    })

    return (
        <div className="h-full py-10 px-10">
            <div className='w-full flex flex-row min-h-[320px] gap-4 justify-center'>
                <div className='w-full border-2 px-5 py-5 rounded-md'>
                    {showMessage && (
                        <MessagePanel message={message} messageStatus={messageStatus} />
                    )}

                    <div className='flex flex-row my-4 gap-4'>
                        <div className='w-7/12'>
                            <div className='w-full'>
                                <Input disabled={id !== undefined} placeholder={'Code schema'} label={'Code schema'} id={'code'} value={schema.code} setValue={setSchema} />
                            </div>
                        </div>

                        <div className='w-5/12 flex items-end'>
                            <button disabled={id !== undefined && !itShowEdit} onClick={toggleModal} className='px-5 py-2 border-2 rounded-md text-[14px] w-full'>{'Ajouter des instructions au schema (*)'}</button>
                        </div>
                    </div>
                    <ModelTwo headers={['Direction', 'Montant', 'Devise', 'Compte', 'Libellé', 'Action']}>
                        {schema.instructions.map((item, index) => (
                            <tr key={index}>
                                <td className='p-3'>{item.direction === "D" || item.direction === "C" ? setName(directions, item.direction) : item.direction}</td>
                                <td className='p-3'>{item.amount}</td>
                                <td className='p-3'>{item.currency === "CDF" || item.currency === "USD" ? setName(currencies, item.currency) : item.currency}</td>
                                <td className='p-3'>{item.wallet === undefined ? item.account : item.wallet}</td>
                                <td className='p-3'>{item.label}</td>
                                <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                    <button onClick={() => editShowItem(item, index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                        <HiEye />
                                    </button>
                                    {itShowEdit && <button onClick={() => removeInstructionItem(index)} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                        <HiTrash />
                                    </button>}
                                </td>
                            </tr>
                        ))}
                    </ModelTwo>
                    <div className='flex flex-row gap-4 mt-4'>
                        {id === undefined && <button onClick={setCreateSchema} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{'Créer un schema'}</button>}
                        {(id !== undefined) && (
                            itShowEdit ?
                                <button onClick={setCreateSchema} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{'Modifier le schema'}</button>
                                :
                                <button onClick={() => setItShowEdit(true)} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>
                                    <HiPencil />
                                </button>
                        )}
                        <button onClick={resetSchema} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Annuler</button>
                    </div>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{itShowEdit ? 'Information du service' : 'Ajouter une instruction au schema'}</h3>

                            <div className='flex flex-row gap-2'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Mottant'} placeholder={'Montant'} value={instruction.amount} id={'amount'} setValue={setInstruction} />
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Configuration'} select={true} selectItems={configs} value={computabilitySetting.amount} id={'amount'} setValue={setComputabilitySetting} />
                            </div>

                            <div className='flex flex-row gap-2'>
                                {computabilitySetting.currency === true || computabilitySetting.currency === "true" ?
                                    (
                                        <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Devise'} value={instruction.currency} id={'currency'} setValue={setInstruction} />
                                    )
                                    :
                                    (
                                        <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Devise'} select={true} selectItems={currencies} value={instruction.currency} id={'currency'} setValue={setInstruction} />
                                    )
                                }
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Configuration'} select={true} selectItems={configs} value={computabilitySetting.currency} id={'currency'} setValue={setComputabilitySetting} />
                            </div>

                            <div className='flex flex-row gap-2'>
                                {
                                    computabilitySetting.direction === true || computabilitySetting.direction === "true" ?
                                        (
                                            <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Direction'} value={instruction.direction} id={'direction'} setValue={setInstruction} />
                                        )
                                        :
                                        (
                                            <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Direction'} select={true} selectItems={directions} value={instruction.direction} id={'direction'} setValue={setInstruction} />
                                        )
                                }
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Configuration'} select={true} selectItems={configs} value={computabilitySetting.direction} id={'direction'} setValue={setComputabilitySetting} />
                            </div>

                            <div className='flex flex-row gap-2'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Compte'} placeholder={'Compte'} value={instruction.account} id={'account'} setValue={setInstruction} />
                                <Input required={true} label={'Configuration'} select={true} selectItems={configs} value={computabilitySetting.account} id={'account'} setValue={setComputabilitySetting} />
                            </div>

                            <div className='flex flex-row gap-2'>
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Designation'} placeholder={'Designation'} value={instruction.label} id={'label'} setValue={setInstruction} />
                                <Input disabled={id !== undefined && !itShowEdit} required={true} label={'Configuration'} select={true} selectItems={configs} value={computabilitySetting.label} id={'label'} setValue={setComputabilitySetting} />
                            </div>

                            <div className='flex flex-row gap-4'>
                                {
                                    itShowEdit === true && (
                                        <button onClick={AddInstruction} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{isLoading ? 'Modification en cours ...' : "Modifier l'instruction"}</button>
                                    )
                                }

                                {
                                    id === undefined && (
                                        <button onClick={AddInstruction} className='py-2 px-10 rounded-md bg-[#7c83e1] text-white'>{isLoading ? 'Ajout en cours ...' : 'Ajouter une instruction'}</button>
                                    )
                                }
                                <button onClick={toggleModal} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default Add

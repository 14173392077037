const initialState = {
  token: null,
  user: null,
  refreshToken: null
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken
      }

    case 'LOGOUT':
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken
      }

    // case 'REFRESH':
    //   return {
    //     ...state,
    //     token: action.payload.token,
    //     refreshToken: action.payload.refreshToken,
    //   };

    default:
      return state
  }
}

export default AuthReducer

import React, { } from 'react'

function ModelTwo({ children, headers, styles }) {

    return (
        <div className={`overflow-auto ${styles}`}>
            <table className="w-full border-2">
                <thead className="border-b-2 border-gray-400 bg-[#e6e4e4]">
                    <tr>
                        {headers.map((el, i) => (
                            <th
                                className={`${el === 'Action' ? 'text-center' : 'text-left'
                                    } p-3 text-sm font-semibold tracking-wide  text-gray-500`}
                                key={i}
                            >
                                {el}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}

export default ModelTwo

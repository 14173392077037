import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { getOperations, getChannels, getMethods, getOperationTypes } from '../../../api/operations'
import { getSchemas } from '../../../api/schema'
import { Link } from 'react-router-dom'
import { HiEye, } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { goTo, setName, refresh } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'
import { useSelector } from 'react-redux'

function Operations() {
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [operations, setOperations] = useState([])
    const [operationsFiltered, setOperationsFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })
    const [methods, setMethods] = useState([])
    const [channels, setChannels] = useState([])
    const [types, setTypes] = useState([])
    const [schemas, setSchemas] = useState([])

    const getOperationList = useCallback(async () => {
        try {
            await getOperations().then((response) => {
                const result = response.data
                setOperations(result)
                setOperationsFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            console.error(error)
        }
    }, [filter.itemsPerPage])

    const getOpsConfig = async () => {
        try {
            await getChannels().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el) => {
                    newArr.push({ value: el.id, label: el.label })
                })
                setChannels(newArr)
            })
            await getMethods().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el) => {
                    newArr.push({ value: el.id, label: el.label })
                })
                setMethods(newArr)
            })
            await getOperationTypes().then(async (res) => {
                const result = res.data
                let newArr = []
                await result.forEach((el) => {
                    newArr.push({ value: el.id, label: el.label })
                })
                setTypes(newArr)
            })

            await getSchemas().then(async (res) => {
                const result = res.data
                let newArr = [{ value: '', label: 'Aucun schema' }]
                await result.forEach((el) => {
                    newArr.push({ value: el.schemaId, label: ` ${el.code} (${el.schemaId}) ` })
                })
                setSchemas(newArr)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const headers = [
        "id",
        "Opérations",
        "Méthode",
        "Canal",
        "Schéma comptable d’autorisation",
        setChildVisible(user?.roles, ['ROLE_OPERATION_LIST']) === true && "Action"
    ]

    // const statusItems = [
    //     {
    //         label: "Tout",
    //         value: 0,
    //         default: true
    //     },
    //     {
    //         label: "Active",
    //         value: 1,
    //     },
    //     {
    //         label: "Non-active",
    //         value: 2,
    //     }
    // ]

    useEffect(() => {
        if (operations.length === 0 && isLoad === false) {
            getOperationList()
        }
    }, [operations, getOperationList, isLoad])

    useEffect(() => {
        if (types.length <= 0 && channels.length <= 0 && methods.length <= 0) {
            getOpsConfig()
        }
    }, [channels, types, methods])
    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-7/12'>
                            <div className='flex-row flex gap-3'>
                                {/* <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div> */}
                                <div className='w-8/12'>
                                    <SearchText initialArray={operations} setArrayFiltered={setOperations} setLoader={setIsLoading} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => goTo('operation-manager-add')} className={`${setChildVisible(user?.roles, ['ROLE_OPERATION_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter une configuration</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={operationsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {operationsFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{setName(types, item.type)}</td>
                                    <td className='p-3'>{setName(methods, item.method)}</td>
                                    <td className='p-3'>{setName(channels, item.channel)}</td>
                                    <td className='p-3'>{setName(schemas, item.authorizationSchema)}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <Link to={"/dashboard/operation-manager-add/" + item.id} className={`${setChildVisible(user?.roles, ['ROLE_OPERATION_LIST']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>
                                            <HiEye />
                                        </Link>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>
        </div>
    )
}

export default Operations

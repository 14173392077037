import React, { useState, useEffect, useCallback } from 'react'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { getSchemas } from '../../../api/schema'
import { Link } from 'react-router-dom'
import { HiEye, } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { goTo, refresh } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'
import { useSelector } from 'react-redux'

function Business() {
    const userState = useSelector((state) => state.auth.user)
    const user = (typeof userState) === 'object' ? userState : JSON.parse(userState)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [schemas, setSchemas] = useState([])
    const [schemasFiltered, setSchemasFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const getSchemaList = useCallback(async () => {
        try {
            await getSchemas().then((response) => {
                const result = response.data
                setSchemas(result)
                setSchemasFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            console.error(error)
        }
    }, [filter.itemsPerPage])

    const headers = [
        "id",
        "code",
        setChildVisible(user?.roles, ['ROLE_SCHEMA_DETAILS']) === true && "Action"
    ]

    useEffect(() => {
        if (schemas.length === 0 && isLoad === false) {
            getSchemaList()
        }
    }, [schemas, getSchemaList, isLoad])

    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-7/12'>

                            <div className='flex-row flex gap-3'>
                                {/* <div className='w-6/12'>
                                    <Input label={'Filtrer par statut'} id={'status'} value={filter.status} setValue={setFilter} selectItems={statusItems} select={true} />
                                </div> */}
                                <div className='w-8/12'>
                                    <SearchText initialArray={schemas} setArrayFiltered={setSchemasFiltered} setLoader={setIsLoading} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => goTo('schema-manager-add')} className={`${setChildVisible(user?.roles, ['ROLE_SCHEMA_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un schema</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={schemasFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {schemasFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.schemaId}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='flex flex-row items-center justify-center p-3 gap-2'>
                                        <Link to={"/dashboard/schema-manager-add/" + item.schemaId} className={`${setChildVisible(user?.roles, ['ROLE_SCHEMA_DETAILS']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>
                                            <HiEye />
                                        </Link>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>
        </div>
    )
}

export default Business

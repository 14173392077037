import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ModelOne from "../../../component/Table/ModelOne"
import Input from '../../../component/Input'
import { HiEye, HiPencil } from "react-icons/hi"
import SearchText from '../../../component/SearchText'
import { getContracts, createContract, updateContract } from '../../../api/contract'
import MessagePanel from '../../../component/Card/MessagePanel'
// import Modal from "../../../component/Modal"
import Modal from '@mui/material/Modal';
import { getProducts } from '../../../api/products'
import { setName, refresh } from '../../../helpers/helper'
import { setChildVisible } from '../../../helpers/roleManager'

function Contracts() {
    const userState = useSelector((state) => state.auth.user)
    const user = userState !== null && ((typeof userState) === 'object' ? userState : JSON.parse(userState))
    const [itShowEdit, setItShowEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const [filter, setFilter] = useState({
        searchText: '',
        status: 1,
        itemsPerPage: 10
    })

    const [contract, setContract] = useState({
        code: '',
        label: '',
        products: [],
        legalPerson: "I",
    })

    const [contracts, setContracts] = useState([])
    const [contractsFiltered, setContractsFiltered] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [itemsPerPageArray, setItemsPerPageArray] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState()
    const [messageStatus, setMessageStatus] = useState(0)
    const [products, setProducts] = useState([])

    const headers = [
        "Id",
        "Code",
        "Type de compte",
        setChildVisible(user?.roles, ['ROLE_CONTRACT_DETAILS']) === true && "Action"
    ]

    const resetContract = () => {
        setContract((prev) => ({
            ...prev,
            code: '',
            label: '',
            products: [],
            legalPerson: "I",
            id: undefined
        }))
    }

    const toggleModal = useCallback(() => {
        resetContract()
        setShowModal(!showModal)
        setItShowEdit(false)
        setShowMessage(false)
    }, [showModal])


    const getContractsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getContracts().then(({ data }) => {
                const result = data
                setContracts(result)

                setContractsFiltered(result)
                setCurrentItems(result.reverse().slice(0, filter.itemsPerPage))
                let arrayItemsPerPage = []
                for (let i = 1; i <= result.length; i++) {
                    if (i === 0) {
                        arrayItemsPerPage.push({ value: i, label: i.toString(), default: true })
                    } else {
                        arrayItemsPerPage.push({ value: i, label: i.toString() })
                    }
                }
                setItemsPerPageArray(arrayItemsPerPage)
                setIsLoading(false)
                setIsLoad(true)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [filter.itemsPerPage])

    const getProductsList = useCallback(async () => {
        setIsLoading(true)
        try {
            await getProducts().then((res) => {
                const result = res.data
                let newArr = []
                result.forEach((el) => {
                    newArr.push({ value: el.id, label: `${el.label} ${el.currency} (${el.code})` })
                })
                setProducts(newArr)
            })
        } catch (error) {
            // const errorMessage = error.response.data.detail
            console.log(error)
            setIsLoading(false)
            setIsLoad(true)
        }
    }, [])

    const setContractProduct = (val) => {
        let newArr = contract.products
        let arrFiltered = []

        if (newArr.length <= 0) {
            newArr.push(val)
            arrFiltered = newArr
        } else {
            arrFiltered = newArr.filter((item) => item === val)
            if (arrFiltered.length === 1) {
                arrFiltered = newArr.filter((item) => item !== val)
            } else {
                newArr.push(val)
                arrFiltered = newArr
            }
        }
        setContract((prev) => ({
            ...prev,
            products: arrFiltered
        }))
    }

    const setCreateContract = useCallback(async () => {
        if (contract.code !== '' && contract.label !== '') {
            if (contract.id !== undefined) {
                setIsLoading(true)
                try {
                    let data = {}

                    contract?.id !== undefined ? data = contract : data = {
                        code: contract.code,
                        label: contract.label,
                        products: contract.products,
                        legalPerson: contract.legalPerson,
                    }

                    await updateContract(data, contract.id)
                        .then(() => {
                            getContractsList()
                            setIsLoading(false)
                            resetContract()
                            toggleModal()
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            } else {
                setIsLoading(true)
                try {
                    let data = {}

                    contract?.id !== undefined ? data = contract : data = {
                        code: contract.code,
                        label: contract.label,
                        products: contract.products,
                        legalPerson: contract.legalPerson,
                    }

                    await createContract(data)
                        .then(() => {
                            getContractsList()
                            setIsLoading(false)
                            resetContract()
                            toggleModal()
                        })

                } catch (error) {
                    const errorMessage = error.response.data.detail
                    setShowMessage(true)
                    setMessageStatus(0)
                    setMessage(errorMessage)
                    setIsLoading(false)
                }
            }
        } else {
            setShowMessage(true)
            setMessageStatus(0)
            setMessage(
                'Veillez renseignez les information obligatoire (*) avant de soumettre ce formulaire'
            )
        }
    }, [getContractsList, contract, toggleModal])

    const editShowItem = (item) => {
        setShowModal(true)
        setContract((prev) => ({
            ...prev,
            code: item.code,
            label: item.label,
            products: item.products,
            id: item.id,
        }))
    }

    const legalPersons = [
        { label: "Individue", value: "I" },
        { label: "Morale", value: "C" }
    ]

    useEffect(() => {
        if (contracts.length === 0 && isLoad === false) {
            getContractsList()
        }
    }, [contracts, getContractsList, isLoad])

    useEffect(() => {
        if (products.length === 0 && isLoad === false) {
            getProductsList()
        }
    }, [products, getProductsList, isLoad])
    return (
        <div className="flex flex-col w-full h-full px-10 py-10">
            <div className='flex flex-row'>
                <div className='w-full'>
                    <div className='flex flex-row justify-between mb-4'>
                        <div className='flex-col flex gap-2 w-7/12'>
                            <div className='flex-row flex gap-3'>
                                <div className='w-8/12'>
                                    <SearchText initialArray={contracts} setArrayFiltered={setContractsFiltered} setLoader={setIsLoading} />
                                </div>
                                <div className='w-4/12'>
                                    <Input label={'Nombre des lignes'} id={'itemsPerPage'} value={filter.itemsPerPage} setValue={setFilter} selectItems={itemsPerPageArray} select={true} />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-row gap-2  justify-center items-end'>
                            <button onClick={refresh} className='px-5 py-2 border-2 rounded-md text-[14px]'>Actualiser les filtres</button>
                            <button onClick={() => toggleModal()} className={`${setChildVisible(user?.roles, ['ROLE_CONTRACT_CREATE']) === true ? 'px-5 py-2 border-2 rounded-md text-[14px]' : 'hidden'}`}>Ajouter un contract</button>
                        </div>
                    </div>
                    <ModelOne headers={headers} arrayItems={contractsFiltered} setArrayItems={setCurrentItems} itemsPerPage={filter.itemsPerPage}>
                        {contractsFiltered.length > 0 ?
                            currentItems.map((item, index) => (
                                <tr key={index} className="border-b-2 border-gray-300">
                                    <td className='p-3'>{item.id}</td>
                                    <td className='p-3'>{item.code}</td>
                                    <td className='p-3'>{item.label}</td>
                                    <td className={`${setChildVisible(user?.roles, ['ROLE_CONTRACT_DETAILS']) === true && 'flex flex-row items-center justify-center p-3 gap-2'}`}>
                                        <button onClick={() => { editShowItem(item) }} className='text-[16px] px-5 py-2 border-2 rounded-md'>
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )) : (
                                <tr className="">
                                    <td
                                        colSpan="10"
                                        className="p-5 text-center text-gray-700 text-md"
                                    >
                                        {isLoading ? "Chargement en cours ..." : "Votre liste est vide."}
                                    </td>
                                </tr>
                            )}
                    </ModelOne>
                </div>
            </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className='w-full h-full flex-col flex items-center justify-center'>
                    <div className={`bg-white shadow-sm ma min-w-[200px] drop-shadow-sm rounded-md px-10 py-10  max-w-[1000px] max-h-[700px]  overflow-auto`}>

                        <div className='flex flex-col gap-3'>
                            {showMessage && (
                                <MessagePanel message={message} messageStatus={messageStatus} />
                            )}

                            <h3 className='text-2xl mb-2 font-semibold uppercase' >{contract.id !== undefined ? 'Information contract' : 'Ajouter un contract'}</h3>
                            <div>
                                <Input disabled={!itShowEdit && contract.id !== undefined} required={true} label={'Code contract'} placeholder="Code" id={'code'} value={contract.code} setValue={setContract} />
                            </div>

                            <div>
                                <Input disabled={!itShowEdit && contract.id !== undefined} required={true} label={'Label contract'} placeholder="Label" id={'label'} value={contract.label} setValue={setContract} />
                            </div>


                            <div>
                                <Input disabled={!itShowEdit && contract.id !== undefined} required={true} label={'Personnalité juridique'} select={true} selectItems={legalPersons} id={'legalPerson'} value={contract.legalPerson} setValue={setContract} />
                            </div>

                            <label htmlFor="role" className='font-semibold'>{'Sélectionner les produits : (*)'}</label>
                            <div className='grid grid-cols-3 gap-4 h-[250px] overflow-auto mb-4 mt-1 border-2 p-5' id='role'>
                                {products.map((el, i) => (
                                    <div onClick={() => setContractProduct(el.value)} className='flex flex-col gap-2' key={i}>
                                        <label htmlFor={i.toString()}>{setName(products, el.value)}</label>
                                        <input disabled={!itShowEdit && contract.id !== undefined} type="checkbox" id={i.toString()} defaultChecked={contract.products.includes(el.value)} />
                                    </div>
                                ))}
                            </div>

                            <div className='flex flex-row gap-4'>
                                {(contract.id !== undefined) && (
                                    itShowEdit ?
                                        <button onClick={setCreateContract} className={`${setChildVisible(user?.roles, ['ROLE_CONTRACT_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`} disabled={isLoading}>
                                            {isLoading ? 'Modification en cours...' : 'Modifier le contract'}
                                        </button>
                                        :
                                        <button onClick={() => setItShowEdit(true)} className={`${setChildVisible(user?.roles, ['ROLE_CONTRACT_UPDATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`} disabled={isLoading}>
                                            <HiPencil />
                                        </button>
                                )}

                                {contract.id === undefined && <button onClick={setCreateContract} className={`${setChildVisible(user?.roles, ['ROLE_CONTRACT_CREATE']) === true ? 'py-2 px-10 rounded-md bg-[#7c83e1] text-white' : 'hidden'}`} disabled={isLoading}>
                                    {isLoading ? 'Ajout en cours...' : 'Ajouter un contract'}
                                </button>}

                                <button onClick={() => toggleModal()} className='py-2 px-10 rounded-md text-white bg-[#de6e3e]'>Fermer</button>
                            </div>

                        </div>

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default Contracts
